import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import { bool, func, shape, string } from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as kuaraActions, selectors as kuaraSelectors } from "reducers/kuara";
import { compose } from "redux";
import * as i18n from "util/i18n";
import { push } from "react-router-redux";
import { formatAccount } from "./hooks/KuaraHooks";

const SettingsKuara = ({ history, dispatch, accountKuara, isFetching, countryNumber, mobilePhoneMask }) => {
    const [showModal, setshowModal] = useState(false);
    const [fetchingSubmit, setFetchingSubmit] = useState(false);

    const handleOpenModal = () => {
        setshowModal(true);
    };

    const handleCloseModal = () => {
        setshowModal(false);
    };

    const handleBack = () => {
        history.goBack();
    };
    const onFinish = () => {
        setFetchingSubmit(false);
        setshowModal(false);
    };

    const desactivateKuara = () => {
        setFetchingSubmit(true);
        dispatch(kuaraActions.desactivateRequest(onFinish));
    };

    const navigateToChangeAccount = () => {
        dispatch(kuaraActions.setFetching(true));
        dispatch(push(`kuaraChangeAccountStep1`));
    };

    return (
        <PageLoading loading={fetchingSubmit || isFetching} classicStyle={false}>
            {showModal ? (
                <TextAndButtonsModal
                    modalShow
                    acceptFunction={desactivateKuara}
                    cancelFunction={handleCloseModal}
                    headingText={i18n.get("kuara.settings.deactivate.modal.title")}
                    text={i18n.get("kuara.settings.deactivate.modal.text")}
                    kuaraColorBtnPrimary
                />
            ) : null}

            <Head onBack={handleBack} title="kuara.settings.title" additionalClassName="kuara-header" />

            <MainContainer>
                <Notification scopeToShow="settingsKuara" />
                <Box display="flex" column alignX="between" alignY="center" className="pt-8">
                    <Image
                        src="images/settings-kuara.svg"
                        color="kuara-secondary"
                        isMobileNative
                        height="12"
                        className="mb-7"
                    />

                    <Box display="flex" column alignY="center" alignX="center" fullWidth>
                        <Text
                            labelKey="kuara.settings.label1"
                            size={4}
                            color="heading-color"
                            align="center"
                            className="px-5 mb-1"
                        />
                        <Box display="flex">
                            <Text labelKey="kuara.settings.label2" size="4" color="heading-color" align="center" />
                            <Box display="inline-flex" className="ml-2">
                                <Image
                                    src="images/logos/kuaraHorizontalSmall.svg"
                                    fullWidth={false}
                                    isMobileNative
                                    height="5"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" column alignX="center" alignY="center" fullWidth className="mt-8 mb-9">
                    <Box
                        background="white"
                        display="flex"
                        alignX="between"
                        alignY="center"
                        fullWidth
                        className=" mt-3 py-7 pl-6 pr-1"
                        borderRadius="xl">
                        <Box display="flex" alignX="flex-start" alignY="center">
                            <Box display="flex" className="mr-3">
                                <Image
                                    src="images/check-circle-kuara.svg"
                                    isMobileNative
                                    height="5"
                                    wrapperWidth="5"
                                    wrapperHeight="5"
                                />
                            </Box>
                            <Text labelKey="kuara.settings.field.phone" size="3" bold color="heading" />:
                            <Text className="ml-2" size="3" ellipsis>
                                <Text size="3">
                                    {" "}
                                    +{countryNumber}
                                    {mobilePhoneMask}
                                </Text>
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        background="white"
                        display="flex"
                        alignX="between"
                        alignY="center"
                        fullWidth
                        className=" mt-3 py-4 pl-6 pr-1"
                        borderRadius="xl">
                        <Box display="flex" alignX="flex-start" alignY="center">
                            <Box display="flex" className="mr-3">
                                <Image
                                    src="images/check-circle-kuara.svg"
                                    isMobileNative
                                    height="5"
                                    wrapperWidth="5"
                                    wrapperHeight="5"
                                />
                            </Box>
                            <Text size="3" bold color="heading" className="text-ellipsis max-width-23">
                                {accountKuara?.productAlias}
                            </Text>
                            <Text className="ml-2" size="3">
                                {formatAccount(accountKuara)}
                            </Text>
                        </Box>
                        <Button
                            image="images/icons/editPen16.svg"
                            imageColor="kuara-secondary"
                            small
                            onClick={navigateToChangeAccount}
                            ariaLabel="global.edit"
                        />
                    </Box>
                </Box>

                <Button
                    label="kuara.settings.deactivate"
                    bsStyle="outline"
                    block
                    className="mt-auto mb-6"
                    onClick={handleOpenModal}
                />
            </MainContainer>
        </PageLoading>
    );
};

SettingsKuara.propTypes = {
    history: shape({}).isRequired,
    dispatch: func.isRequired,
    accountKuara: shape({}),
    isFetching: bool,
    countryNumber: string,
    mobilePhoneMask: string,
};

SettingsKuara.defaultProps = {
    accountKuara: {},
    isFetching: true,
    countryNumber: null,
    mobilePhoneMask: null,
};
const mapStateToProps = (state) => ({
    accountKuara: kuaraSelectors.getAccountKuara(state),
    isFetching: kuaraSelectors.isFetching(state),
    countryNumber: kuaraSelectors.getCountryNumberKuara(state),
    mobilePhoneMask: kuaraSelectors.getMobilePhoneMaskKuara(state),
});

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(SettingsKuara));
