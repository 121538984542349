import classNames from "classnames";
import moment from "moment";
import Box from "pages/_components/Box";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { PDFTextField, PDFAmountField, PDFAmountFieldError } from "pages/forms/customForms/PDFTicket";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as paymentItemSelectors } from "reducers/paymentItem";
import { compose } from "redux";
import * as i18n from "util/i18n";
import { numberFormat } from "util/number";
import { isValidString } from "util/string";
import Text from "pages/_components/Text";
import { View } from "@react-pdf/renderer";
import TicketRenderCustom from "../TicketRenderCustom";

let ID_FORM = "role.payment";

const TicketPaymentItemDetail = (props) => {
    const { mode, data, currentLang, fromBackoffice, dispatch, transaction } = props;
    const {
        amount,
        reference,
        creationDateTime,
        operationType,
        debitAccount,
        bankName,
        productName,
        productNumber,
        beneficiaryName,
        motive,
        itemStatus,
    } = data;

    const ID_ACTIVITY = operationType;

    const [title, setTitle] = useState(`${ID_FORM}.details.title`);
    const [customTitleForGoBack, setCustomTitleForGoBack] = useState(i18n.get(`${ID_FORM}.details.title`));
    const [customTitleForTicket, setCustomTitleForTicket] = useState(null);
    const customStatus = `${itemStatus}`;
    const customTitleKind = "paymentItem";

    useEffect(() => {
        if (operationType) {
            ID_FORM = operationType === "suppliers.payment.send" ? "suppliers.payment" : "role.payment";
            setTitle(`${ID_FORM}.details.title`);
            setCustomTitleForGoBack(i18n.get(`${ID_FORM}.details.title`));
        }
        setCustomTitleForTicket(`forms.transaction.ticket.status.${itemStatus}.paymentItem`);
    }, [operationType]);

    const { decimalSeparator, thousandSeparator } = numberFormat();

    const dataAmount = useMemo(
        () => ({
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options: amount
                ? [
                      {
                          id: amount.currency,
                          label: amount.currency ? i18n.get(`currency.label.${amount.currency}`) : "",
                      },
                  ]
                : [],
        }),
        [decimalSeparator, thousandSeparator, amount],
    );

    const amountTextLabel =
        itemStatus === "PROCESSED"
            ? i18n.get("forms.payment.item.detail.finished.amount.label_view")
            : i18n.get("forms.payment.item.detail.amount.label_view");

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: transaction?.idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
        dispatch,
    };

    const renderFields = () => (
        <>
            <Box display="flex" alignX="center" column className="amount-wrapper">
                <Box display="flex" alignX="center" fullWidth className="mb-2">
                    {itemStatus === "PROCESSED" || itemStatus === "SEND" ? (
                        <Text size="6" color="inverse" align="center">
                            {amountTextLabel}
                        </Text>
                    ) : (
                        <Text size="" align="center">
                            {amountTextLabel}
                        </Text>
                    )}
                </Box>
                <Box display="flex" alignX="center" fullWidth>
                    <FormFieldsComponents.Amount
                        {...genericProps}
                        data={dataAmount}
                        key="amount"
                        name="amount"
                        value={amount}
                        idField="amount"
                        bigDataAmount
                        mode="view"
                        readOnly
                        field={{ name: "amount", value: amount }}
                        idForm={ID_FORM}
                        renderLabel={false}
                    />
                </Box>
            </Box>
            <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                {reference && (
                    <FormFieldsComponents.ReadTextCustom value={reference} label="transactions.ticket.voucher.label" />
                )}

                <FormFieldsComponents.ReadTextCustom
                    value={moment(creationDateTime).format("DD/MM/YYYY")}
                    label="transactions.ticket.date.label"
                />

                <FormFieldsComponents.ReadTextCustom
                    value={i18n.get(`activities.${operationType}`)}
                    label="global.operationType"
                />
                <FormFieldsComponents.ReadTextCustom
                    value={debitAccount}
                    label={`forms.${ID_FORM}.debitAccount.label`}
                />
                <FormFieldsComponents.ReadTextCustom value={bankName} label="forms.bankselector.bank.label" />
                <FormFieldsComponents.ReadTextCustom
                    value={i18n.get(`forms.payment.item.detail.productType.other.${productName}`)}
                    label="forms.role.payment.productType.label"
                />
                <FormFieldsComponents.ReadTextCustom
                    value={productNumber}
                    label="forms.payment.item.detail.productNumber.label"
                />

                <FormFieldsComponents.ReadTextCustom value={beneficiaryName} label="forms.beneficiary.name.label" />

                {motive && (
                    <FormFieldsComponents.ReadTextCustom value={motive} label={`forms.${ID_FORM}.commentary.label`} />
                )}
            </Box>
        </>
    );

    const renderTicket = (values) => (
        <View>
            {values?.itemStatus !== "PROCESSED" ? (
                <PDFAmountFieldError
                    idForm={ID_FORM}
                    name="amount"
                    values={values}
                    label={i18n.get(`forms.${ID_FORM}.amount.label`)}
                />
            ) : (
                <PDFAmountField
                    idForm={ID_FORM}
                    name="amount"
                    values={values}
                    label={i18n.get("forms.payment.item.detail.finished.amount.label_view")}
                />
            )}

            {isValidString(values?.reference) && (
                <PDFTextField
                    idForm={ID_FORM}
                    value={values.reference}
                    label={i18n.get("transactions.ticket.voucher.label")}
                />
            )}

            {values?.transaction?.creationDateTime && (
                <PDFTextField
                    idForm={ID_FORM}
                    value={moment(values.transaction.creationDateTime).format("DD/MM/YYYY")}
                    label={i18n.get("transactions.ticket.date.label")}
                />
            )}

            <PDFTextField
                idForm={ID_FORM}
                value={i18n.get(`activities.${values?.operationType}`)}
                label={i18n.get("global.operationType")}
            />

            <PDFTextField
                idForm={ID_FORM}
                value={values?.debitAccount}
                label={i18n.get(`forms.${ID_FORM}.debitAccount.label`)}
            />

            <PDFTextField idForm={ID_FORM} value={values?.bankName} label={i18n.get("forms.bankselector.bank.label")} />

            <PDFTextField
                idForm={ID_FORM}
                value={i18n.get(`forms.payment.item.detail.productType.other.${values?.productName}`)}
                label={i18n.get("forms.role.payment.productType.label")}
            />

            <PDFTextField
                idForm={ID_FORM}
                value={values?.productNumber}
                label={i18n.get("forms.payment.item.detail.productNumber.label")}
            />
            <PDFTextField
                idForm={ID_FORM}
                value={values?.beneficiaryName}
                label={i18n.get("forms.beneficiary.name.label")}
            />

            {isValidString(values?.motive) && (
                <PDFTextField
                    idForm={ID_FORM}
                    value={values.motive}
                    label={i18n.get(`forms.${ID_FORM}.commentary.label`)}
                />
            )}
        </View>
    );

    const formProps = {
        customTitleForGoBack,
        customTitleForTicket,
        title,
        renderFields,
        renderTicket,
        idActivity: ID_ACTIVITY,
        values: data,
        transaction,
        metadata: {
            idActivity: ID_ACTIVITY,
            draftsEnabled: false,
            templatesEnabled: false,
            idForm: ID_FORM,
            showSubmit: false,
        },
        customStatus,
        customTitleKind,
    };
    return <TicketRenderCustom {...props} {...formProps} />;
};

TicketPaymentItemDetail.propTypes = {
    values: shape({}),
    dispatch: func.isRequired,
    isSubmitting: bool,
    currentLang: string,
    metadata: shape({}),
    errors: shape({}),
    history: shape({ location: shape({ pathname: string }) }).isRequired,
    match: shape({ path: string, url: string, isExact: bool }).isRequired,
    setValues: func,
    setErrors: func,
    setFieldValue: func,
    isDesktop: bool.isRequired,
    location: shape({ pathname: string }).isRequired,
    idActivity: string,
    transaction: shape({
        idTransaction: string,
    }),
    fromBackoffice: bool,
    title: string,
    id: string,
    showSubmit: bool,
    returnBack: bool,
    renderFields: func,
    renderTicket: func,
    mode: string,
    data: shape({}),
};

TicketPaymentItemDetail.defaultProps = {
    fromBackoffice: false,
    mode: "view",
    currentLang: "",
    transaction: {},
    isSubmitting: false,
    metadata: {
        idActivity: "",
        draftsEnabled: false,
        templatesEnabled: false,
        idForm: "",
        showSubmit: false,
    },
    errors: {},
    setValues: () => {},
    setErrors: () => {},
    setFieldValue: () => {},
    data: {},
    values: {},
    idActivity: "",
    title: "",
    id: "",
    showSubmit: false,
    returnBack: true,
    renderFields: null,
    renderTicket: null,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    currentLang: i18nSelectors.getLang(state),
    data: paymentItemSelectors.getTicketData(state),
    mode: paymentItemSelectors.getMode(state),
    transaction: paymentItemSelectors.getTransaction(state),
});
export default compose(connect(mapStateToProps), withRouter)(TicketPaymentItemDetail);
