import { Image, StyleSheet } from "@react-pdf/renderer";
import { bool, string } from "prop-types";
import React from "react";
import logoTicketBGUpdate from "styles/images/logos/logoTicketBGUpdate.png";
import logoTicketFooter from "styles/images/logos/logoTicketFooter.png";

const ImagePDFUpdate = ({ imageFooter, imageBG, width, height, padding }) => {
    const styles = StyleSheet.create({
        logo: {
            width,
            height,
            objectFit: "cover",
            padding,
        },
    });

    return imageFooter || imageBG ? (
        <Image
            {...(imageFooter && { src: logoTicketFooter })}
            {...(imageBG && { src: logoTicketBGUpdate })}
            style={styles.logo}
        />
    ) : null;
};

ImagePDFUpdate.propTypes = {
    width: string,
    height: string,
    padding: string,
    imageFooter: bool,
    imageBG: bool,
};
ImagePDFUpdate.defaultProps = {
    width: "100%",
    height: "auto",
    padding: "1px",
    imageFooter: false,
    imageBG: false,
};

export default ImagePDFUpdate;
