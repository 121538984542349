import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TextField from "pages/_components/fields/TextField";
import Checkbox from "pages/_components/fields/formik/Checkbox";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import * as i18n from "util/i18n";
import {
    actions as preApprovedCreditPosActions,
    selectors as preApprovedCreditPosSelectors,
} from "reducers/preApprovedCreditPos";
import * as Yup from "yup";

import PageLoading from "pages/_components/PageLoading";
import { USD_CURRENCY } from "constants.js";

const FORM_ID = "preApprovedProductCreditPOS.drawer";
const ACCEPTED = "ACCEPTED";
const REJECTED = "REJECTED";

const PreApprovedProductCreditPOSModal = (props) => {
    const {
        handleCloseModal,
        isDesktop,
        isFetching,
        showModal,
        productType,
        dispatch,
        preApprovedCreditBanner,
        preApprovedCreditUser,
    } = props;

    const [dataTreatmentCheck, setDataTreatmentCheck] = useState(false);

    useEffect(() => {
        if (!showModal) {
            setDataTreatmentCheck(false);
        }
    }, [showModal]);

    const onChangeDataTreatmentCheckBox = () => {
        setDataTreatmentCheck(!dataTreatmentCheck);
    };

    const handleSubmit = (values) => {
        const { preApprovedCreditUsersId } = values;
        dispatch(preApprovedCreditPosActions.preApprovedProductSendRequest(preApprovedCreditUsersId, ACCEPTED));
    };

    const declinePreApprovedProduct = (preApprovedCreditUsersId) => {
        dispatch(preApprovedCreditPosActions.preApprovedProductSendRequest(preApprovedCreditUsersId, REJECTED));
    };

    const validationSchema = () => Yup.object().shape({});

    return (
        <Modal
            aria-labelledby="modalTitleID"
            aria-modal="true"
            onHide={handleCloseModal}
            show={showModal}
            className={classNames({ drawer: true })}>
            <div className="modal-container">
                <Modal.Header closeButton className="mb-7">
                    <Box display="flex" alignX="center" column>
                        <Text
                            id="modalTitleID"
                            component="h4"
                            labelKey={`${FORM_ID}.modal.title.${productType}`}
                            className="mt-0 mb-3"
                            size="3"
                            color="heading-color"
                            bold
                            align="center"
                        />
                    </Box>
                </Modal.Header>
                <Modal.Body>
                    <Box
                        display="flex"
                        column
                        alignX="between"
                        className="scrollable-content px-0 px-md-9"
                        fullWidth
                        fullHeight>
                        <PageLoading loading={isFetching}>
                            <Formik
                                validationSchema={validationSchema(productType)}
                                onSubmit={handleSubmit}
                                initialValues={{
                                    bankBranch: preApprovedCreditUser?.bankBranch,
                                    amount: `${USD_CURRENCY} ${preApprovedCreditUser?.amount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                    })}`,
                                    monthlyInstallment: `${USD_CURRENCY} ${preApprovedCreditUser?.monthlyInstallment.toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 2 },
                                    )}`,
                                    years: `${preApprovedCreditUser?.durationYears} ${i18n.get("scheduler.months")}`,
                                    anualRate: `${(100 * preApprovedCreditUser?.annualRate ?? 0).toFixed(2)}%`,
                                    preApprovedCreditUsersId: preApprovedCreditUser?.preApprovedCreditUsersId,
                                    preApprovedCreditUser,
                                    preApprovedCreditBanner,
                                }}>
                                <Form className="full-height">
                                    <Box display="flex" column alignX="between" className="mb-auto" fullHeight>
                                        <Box display="flex" column className="mb-3">
                                            <Field
                                                idForm={FORM_ID}
                                                component={TextField}
                                                name="bankBranch"
                                                placeholder={i18n.get(
                                                    "preApprovedProduct.drawer.salary.select.placeholder",
                                                )}
                                                className="mb-2"
                                                disabled
                                            />

                                            <Box display="flex" gapX="5">
                                                <Field
                                                    idForm={FORM_ID}
                                                    component={TextField}
                                                    name="amount"
                                                    placeholder=""
                                                    className="mb-2"
                                                    disabled
                                                />
                                                <Field
                                                    idForm={FORM_ID}
                                                    component={TextField}
                                                    name="monthlyInstallment"
                                                    placeholder=""
                                                    className="mb-2"
                                                    disabled
                                                />
                                            </Box>

                                            <Field
                                                idForm={FORM_ID}
                                                component={TextField}
                                                name="years"
                                                placeholder=""
                                                className="mb-2"
                                                disabled
                                            />

                                            <Field
                                                idForm={FORM_ID}
                                                component={TextField}
                                                name="anualRate"
                                                placeholder=""
                                                className="mb-2"
                                                disabled
                                            />

                                            <Box display="flex" column>
                                                <Text size="7" labelKey="preApprovedProductCreditPOS.drawer.info.1" />
                                                <Text size="7" labelKey="preApprovedProductCreditPOS.drawer.info.2" />
                                                <Text size="7" labelKey="preApprovedProductCreditPOS.drawer.info.3" />
                                            </Box>
                                        </Box>

                                        <Box display="flex" className="pb-9 pb-md-12" column>
                                            <Box display="flex" column className="mb-0 mb-md-8">
                                                <Box className="mb-5" display="flex" alignX="flex-start">
                                                    <Field
                                                        idForm={FORM_ID}
                                                        name="dataTreatment"
                                                        component={Checkbox}
                                                        className="align-top-start-checkbox mt-2"
                                                        label={i18n.get(
                                                            "preApprovedProductCreditPOS.drawer.dataTreatment.label",
                                                        )}
                                                        checked={dataTreatmentCheck}
                                                        block={false}
                                                        onChange={onChangeDataTreatmentCheckBox}
                                                        labelSize={isDesktop ? "7" : "6"}
                                                        labelColor="heading-color"
                                                    />
                                                </Box>
                                            </Box>
                                            <Button
                                                block
                                                type="submit"
                                                label="global.request"
                                                bsStyle="primary"
                                                loading={isFetching}
                                                disabled={!dataTreatmentCheck}
                                            />
                                            <Button
                                                block
                                                label="global.decline"
                                                bsStyle="outline"
                                                loading={isFetching}
                                                onClick={() => {
                                                    declinePreApprovedProduct(
                                                        preApprovedCreditUser?.preApprovedCreditUsersId,
                                                    );
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Form>
                            </Formik>
                        </PageLoading>
                    </Box>
                </Modal.Body>
            </div>
        </Modal>
    );
};

PreApprovedProductCreditPOSModal.propTypes = {
    handleCloseModal: func,
    isDesktop: bool,
    isFetching: bool,
    showModal: bool,
    productType: string.isRequired,
    dispatch: func.isRequired,
    preApprovedCreditBanner: shape({}),
    preApprovedCreditUser: shape({}),
};

PreApprovedProductCreditPOSModal.defaultProps = {
    handleCloseModal: null,
    isDesktop: false,
    isFetching: true,
    showModal: false,
    preApprovedCreditBanner: null,
    preApprovedCreditUser: null,
};

const mapStateToProps = (state) => ({
    isFetching: preApprovedCreditPosSelectors.isFetching(state),
    showModal: preApprovedCreditPosSelectors.showModalCreditPOS(state),
    preApprovedCreditBanner: preApprovedCreditPosSelectors.getPreApprovedCreditBanner(state),
    preApprovedCreditUser: preApprovedCreditPosSelectors.getPreApprovedCreditUser(state),
});

export default connect(mapStateToProps)(resizableRoute(PreApprovedProductCreditPOSModal));
