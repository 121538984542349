/* eslint-disable no-confusing-arrow */
import classNames from "classnames";
import AccessBarBtn from "pages/_components/AccessBarBtn";
import Badge from "pages/_components/Badge";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import { arrayOf, bool, number, shape, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as wallySelectors } from "reducers/wally";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { getUrlMarket } from "util/settings";
import kuaraVersionNumber from "util/kuaraVersionNumber";

const AccessBar = ({
    options,
    defaultAccesBar,
    notificationCount,
    pendingTransactionsQuantity,
    showNotification,
    kuara,
    wally,
    isLocal,
    isActiveCorporate,
    home,
    isExternalLayout,
}) => {
    const renderKuaraButton = () => (
        <Button
            ariaLabel={kuara.label}
            className={`kuara-btn my-auto  ${kuara.className}`}
            href={kuara.href}
            image={kuara.image}
            onClick={kuara.onClick}
            imageMd>
            {showNotification && notificationCount > 0 && <Badge count={notificationCount} circular />}
        </Button>
    );

    const renderHomeButton = () => (
        <Button
            ariaLabel={home.label}
            className={home.className}
            href={home.href}
            image={home.image}
            onClick={home.onClick}
            imageMd>
            {showNotification && notificationCount > 0 && <Badge count={notificationCount} circular />}
        </Button>
    );

    const renderWallyButton = () =>
        kuaraVersionNumber(1) && isExternalLayout ? (
            <Button
                ariaLabel={wally.label}
                className="banesco-btn my-auto"
                href={wally.href}
                image="images/banesco-icon.svg"
                onClick={wally.onClick}
                imageMd
            />
        ) : (
            <Button
                ariaLabel={wally.label}
                className={`wally-btn my-auto  ${wally.className}`}
                href={wally.href}
                image={wally.image}
                onClick={wally.onClick}
                imageMd>
                {showNotification && notificationCount > 0 && <Badge count={notificationCount} circular />}
                <span className="btn-link-span">{wally.label}</span>
            </Button>
        );

    const renderVersion1Buttons = () => (isLocal ? renderKuaraButton() : renderWallyButton());

    const renderButtonCenter = () => {
        if (isActiveCorporate !== undefined && isActiveCorporate === true) {
            return <div />;
        }

        if (home) {
            return renderHomeButton();
        }

        if (kuaraVersionNumber(2)) {
            return renderKuaraButton();
        }

        return renderVersion1Buttons();
    };

    return defaultAccesBar ? (
        <Box className="access-bar-wrapper" component="nav" display="flex" alignX="between" alignY="center">
            <Box className="access-bar" display="flex">
                {options.map((option, index) =>
                    index === 2 ? (
                        <>
                            <Box display="flex">
                                {/* APPROVE */}
                                {isActiveCorporate !== undefined && isActiveCorporate === true && (
                                    <Button
                                        ariaLabel="global.approve"
                                        className={classNames("wally-btn my-auto", {
                                            "is-corporate": isActiveCorporate,
                                        })}
                                        href="/pendingTransaction/list"
                                        image="images/icons/signatures.svg"
                                        imageMd>
                                        {showNotification && pendingTransactionsQuantity > 0 && (
                                            <Badge count={pendingTransactionsQuantity} circular />
                                        )}

                                        <Text className="btn-link-span" labelKey="global.approve" size="small" />
                                    </Button>
                                )}

                                {renderButtonCenter()}
                            </Box>
                            <AccessBarBtn
                                key={`${option.label}_${option.image}`}
                                image={option.image}
                                label={option.label}
                                className={option.className}
                                href={option.href}
                                externalHref={option.externalHref}
                                onClick={option.onClick}
                            />
                        </>
                    ) : (
                        <AccessBarBtn
                            key={`${option.label}_${option.image}`}
                            image={option.image}
                            label={option.label}
                            className={option.className}
                            href={option.href}
                            externalHref={option.externalHref}
                            onClick={option.onClick}
                        />
                    ),
                )}
            </Box>
        </Box>
    ) : (
        <Box
            className="access-bar-wrapper access-bar-wrapper-blue"
            component="article"
            display="flex"
            alignX="between"
            alignY="center">
            <Box display="flex" column className="pr-5">
                <Text labelKey="login.access.app.title" size="6" color="inverse" className="pb-2" />
            </Box>
            <Button
                externalHref={getUrlMarket()}
                label="login.access.app.btn"
                bsStyle="outline"
                className="access-bar-app-btn"
                inverse
            />
        </Box>
    );
};

AccessBar.propTypes = {
    options: arrayOf(
        shape({
            image: string,
            href: string,
            label: string,
            className: string,
        }),
    ).isRequired,
    kuara: shape({
        image: string,
        href: string,
        label: string,
        className: string,
    }).isRequired,
    wally: shape({
        image: string,
        href: string,
        label: string,
        className: string,
    }).isRequired,
    defaultAccesBar: bool,
    notificationCount: number,
    pendingTransactionsQuantity: number,
    showNotification: bool,
    isLocal: bool,
    isActiveCorporate: bool.isRequired,
    isExternalLayout: bool,
    home: shape({
        image: string,
        href: string,
        label: string,
        className: string,
    }),
};

AccessBar.defaultProps = {
    defaultAccesBar: true,
    notificationCount: 0,
    pendingTransactionsQuantity: 0,
    showNotification: false,
    isLocal: false,
    isExternalLayout: false,
    home: undefined,
};

const mapStateToProps = (state) => ({
    notificationCount: wallySelectors.getPendingTransactionsWallyQuantity(state),
    pendingTransactionsQuantity: transactionsSelectors.getPendingTransactionsQuantity(state),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
});

export default connect(mapStateToProps)(AccessBar);
