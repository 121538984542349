import classNames from "classnames";
import withTransactionFilterContext from "hoc/withTransactionFilterContext";
import Badge from "pages/_components/Badge";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import Header from "pages/_components/header/Header";
import ViewTitle from "pages/_components/header/components/ViewTitle";
import Menu from "pages/_components/menu/Menu";
import { arrayOf, bool, func, node, number, oneOfType, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import Navbar from "react-bootstrap/lib/Navbar";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Link from "react-router-dom/Link";
import { routerActions } from "react-router-redux/actions";
import { selectors as assistantSelectors } from "reducers/assistant";
import { selectors as communicationsSelectos } from "reducers/communications";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { actions as statusActions } from "reducers/status";
import { selectors as transactionsSelectors } from "reducers/transactions";
import * as utilsI18n from "util/i18n";
import { getLastLoginDate, getLastLoginPlace } from "util/settings";

class Head extends Component {
    static propTypes = {
        additionalClassName: string,
        align: string,
        // i18n head title
        title: node,
        // specify a translated text instead a i18n key
        titleText: string,
        // back link url
        backLinkTo: string,
        // back function, ignored if backLinkTo is specified
        onBack: func,
        // close link url
        closeLinkTo: string,
        // onClose function link, ignored if closeLinkTo is specified
        onClose: func,
        // link url, it links located under title
        linkTo: string,
        // I18n id of link text, it is located under title
        linkText: string,
        // head logo
        logo: node,
        // called right after button with closeLinkTo is clicked
        onCloseClick: func,
        dashboard: bool,
        hasCenterContent: bool,
        showPlusIcon: bool,
        accessibilityTextId: string,
        dispatch: func.isRequired,
        hasActiveSession: bool,
        isMobile: bool,
        isDesktop: bool,
        unreadCommunications: number,
        contentLeft: bool,
        onAdd: func,
        actionButton: func,
        actionButtonLabel: string,
        actionButtonImage: string,
        actionButtonImageMd: bool,
        actionButtonBsStyle: string,
        rightChildren: oneOfType([arrayOf(node), node]),
        addLinkTo: string,
        addLinkToLabel: string,
        addButtonBigPadding: bool,
        exportList: arrayOf(shape({})),
        exportListBsStyle: string,
        handleInviteClick: func,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            securitySeal: oneOfType([number, string]),
            userFullName: string,
        }),
        environments: shape(),
        note: string,
        handleClick: func,
        isFetchingExport: bool,
        children: oneOfType([arrayOf(node), node]),
        replace: shape({ USER_NAME: string }),
        hideNavbarInMobile: bool,
        updateFocus: bool, // Prop which defines if component will re render if updated or not
        maskAmount: bool,
        userFirstName: string,
        disableBackButton: bool,
        lightText: bool,
        pushRedirect: bool,
        titleImage: string,
        subtitlePrefix: string,
        subtitle: string,
        wally: bool,
        position: string,
        externalHrefButton: bool,
        externalRefValue: string,
        isFetchingDownload: bool.isRequired,
        downloadFilterTransaction: func,
        subtitleUppercase: bool,
        exportTicketOptionList: arrayOf(node),
    };

    static defaultProps = {
        additionalClassName: null,
        align: "",
        dashboard: false,
        title: null,
        titleText: "",
        backLinkTo: "",
        onBack: null,
        closeLinkTo: "",
        onClose: null,
        linkTo: "",
        linkText: "",
        logo: null,
        loggedUser: null,
        contentLeft: false,
        onCloseClick: () => {},
        hasCenterContent: false,
        showPlusIcon: false,
        accessibilityTextId: "",
        hasActiveSession: false,
        isMobile: false,
        isDesktop: false,
        unreadCommunications: 0,
        addLinkTo: "",
        addLinkToLabel: "",
        exportList: null,
        exportListBsStyle: "link",
        environments: undefined,
        note: "",
        isFetchingExport: false,
        replace: { USER_NAME: "" },
        hideNavbarInMobile: false,
        addButtonBigPadding: false,
        updateFocus: true,
        children: undefined,
        rightChildren: undefined,
        handleClick: undefined,
        handleInviteClick: undefined,
        onAdd: undefined,
        actionButton: undefined,
        actionButtonLabel: null,
        actionButtonImage: null,
        actionButtonImageMd: false,
        actionButtonBsStyle: null,
        maskAmount: false,
        userFirstName: "",
        disableBackButton: false,
        lightText: false,
        pushRedirect: false,
        titleImage: null,
        subtitlePrefix: null,
        subtitle: null,
        wally: false,
        position: null,
        externalHrefButton: false,
        externalRefValue: "",
        downloadFilterTransaction: null,
        subtitleUppercase: true,
        exportTicketOptionList: undefined,
    };

    constructor(props) {
        super(props);
        this.titleRef = React.createRef();
        this.state = {
            menuClosed: false,
        };
    }

    componentDidMount() {
        if (this.titleRef.current) {
            this.titleRef.current.focus({
                preventScroll: true,
            });
        }
    }

    componentDidUpdate() {
        const { updateFocus } = this.props;
        if (this.titleRef.current && updateFocus) {
            this.titleRef.current.focus({
                preventScroll: true,
            });
        }
    }

    back = () => {
        const { dispatch, backLinkTo } = this.props;
        dispatch(routerActions.replace({ pathname: backLinkTo, state: { transition: "transition-drill-out" } }));
    };

    getLastLoginInfo = () => {
        const { loggedUser } = this.props;
        const { previousLoginInfo } = loggedUser;

        if (previousLoginInfo && previousLoginInfo.length) {
            return (
                <React.Fragment>
                    <Box className="last-login" display="flex" wrap>
                        <Box>
                            <I18n id="settings.lastLogin.date" />:
                        </Box>
                        <Box display="flex" wrap>
                            <Text>{getLastLoginDate(previousLoginInfo[0])}&nbsp;-&nbsp;</Text>
                            <Link to="/settings/lastLogin">{getLastLoginPlace(previousLoginInfo[0])}</Link>
                        </Box>
                    </Box>
                </React.Fragment>
            );
        }
        return null;
    };

    menuCloseHandler = () => {
        const { menuClosed } = this.state;
        this.setState({ menuClosed: !menuClosed });
    };

    getLeftOption = () => {
        const {
            backLinkTo,
            onBack,
            hasActiveSession,
            isMobile,
            hideNavbarInMobile,
            disableBackButton,
            lightText,
            dashboard,
        } = this.props;
        if (backLinkTo || onBack) {
            return (
                <div className="toolbar-item toolbar-item--fixed toolbar-item-left">
                    <Button
                        className="toolbar-btn view-back"
                        {...(lightText && { inverse: true })}
                        onClick={backLinkTo ? this.back : onBack}
                        image="images/arrowLeft.svg"
                        ariaLabel="global.back"
                        bsStyle="link"
                        disable={disableBackButton}
                    />
                </div>
            );
        }

        if (hasActiveSession && isMobile && !hideNavbarInMobile && dashboard) {
            return (
                <Navbar.Toggle
                    className={classNames({
                        collapsed1: !this.state.menuClosed,
                    })}
                    onClick={this.menuCloseHandler}
                />
            );
        }

        return null;
    };

    close = () => {
        const { onCloseClick, dispatch, closeLinkTo } = this.props;
        onCloseClick();
        dispatch(routerActions.replace({ pathname: closeLinkTo, state: { transition: "transition-flow-close" } }));
    };

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(statusActions.showSessionCloseModal());
    };

    add = () => {
        const { dispatch, onAdd, addLinkTo, isDesktop, pushRedirect } = this.props;

        if (onAdd) {
            onAdd();
        }
        let routerAction = {
            pathname: addLinkTo,
            state: { transition: "transition-flow-open" },
        };

        if (isDesktop) {
            // con la transition no está funcionando en Desktop, se quita y se sigue el issue para encontrar el motivo
            routerAction = {
                pathname: addLinkTo,
            };
        }
        if (pushRedirect) {
            dispatch(routerActions.push(routerAction));
            return;
        }
        dispatch(routerActions.replace(routerAction));
    };

    /**
     * Update mask amount
     */

    updateMaskAmount = () => {
        const { dispatch, maskAmount } = this.props;
        dispatch(sessionActions.maskAmountUpdateRequest(!maskAmount));
    };

    getRightOption = () => {
        const {
            addLinkTo,
            addLinkToLabel,
            closeLinkTo,
            dashboard,
            exportList,
            exportTicketOptionList,
            handleInviteClick,
            hasActiveSession,
            isDesktop,
            isMobile,
            note,
            onAdd,
            actionButton,
            actionButtonLabel,
            actionButtonImage,
            actionButtonImageMd,
            actionButtonBsStyle,
            onClose,
            showPlusIcon,
            unreadCommunications,
            addButtonBigPadding,
            rightChildren,
            externalHrefButton,
            externalRefValue,
        } = this.props;

        const noteElement = (
            <Box className="toolbar-item view-note">
                <Text>{note}</Text>
            </Box>
        );

        const closeElement = (
            <Box className="toolbar-item toolbar-item--fixed toolbar-item-right">
                <Button
                    className="toolbar-btn view-close"
                    onClick={closeLinkTo ? this.close : onClose}
                    image="images/icons/cross16.svg"
                    bsStyle="link"
                />
            </Box>
        );

        if (closeLinkTo || onClose || note) {
            return (
                <>
                    {note && noteElement}
                    {(closeLinkTo || onClose) && closeElement}
                </>
            );
        }

        if (exportTicketOptionList?.length && exportTicketOptionList.length > 0) {
            const { exportListBsStyle } = this.props;

            return (
                <Dropdown
                    image="images/icons/share.svg"
                    className="toolbar-item"
                    label="global.download"
                    imageMd
                    bsStyle={exportListBsStyle}
                    {...(!isDesktop && { labelClassName: "visually-hidden" })}
                    pullRight>
                    {exportTicketOptionList.map((item) => (
                        <Button
                            key={item.label}
                            label={item.label}
                            onClick={() => {
                                item.onClick();
                            }}
                            className="dropdown__item-btn"
                            bsStyle="link"
                        />
                    ))}
                </Dropdown>
            );
        }

        let buttons = [];

        if (exportList && !isDesktop) {
            const { isFetchingExport, exportListBsStyle, isFetchingDownload } = this.props;

            return (
                <>
                    <Dropdown
                        image="images/download.svg"
                        className="toolbar-item"
                        label="global.download"
                        {...(!isDesktop && { labelClassName: "visually-hidden" })}
                        bsStyle={exportListBsStyle}
                        fetching={isFetchingExport || isFetchingDownload}
                        pullRight>
                        {exportList.map((item) => (
                            <Button
                                key={item.label}
                                label={item.label}
                                onClick={() => {
                                    const { downloadFilterTransaction } = this.props;
                                    item.onClick(downloadFilterTransaction);
                                }}
                                className="dropdown__item-btn"
                                bsStyle="link"
                            />
                        ))}
                    </Dropdown>
                </>
            );
        }

        const addButton = (
            <Box className="toolbar-item toolbar-item--fixed toolbar-item-right" key="add">
                <Button
                    imageMd
                    onClick={this.add}
                    className={classNames({
                        "btn-primary": isDesktop,
                        "toolbar-btn view-close": !isDesktop,
                        "btn-big-padding": addButtonBigPadding,
                    })}
                    image="images/icons/circlePlus.svg"
                    label={addLinkToLabel}
                />
            </Box>
        );

        const addButtonHref = (
            <Box className="toolbar-item toolbar-item--fixed toolbar-item-right" key="add">
                <Button
                    imageMd
                    className={classNames({
                        "btn-primary": isDesktop,
                        "toolbar-btn view-close": !isDesktop,
                        "btn-big-padding": addButtonBigPadding,
                    })}
                    image="images/icons/circlePlus.svg"
                    label={addLinkToLabel}
                    externalHref={externalRefValue}
                />
            </Box>
        );

        const addActionButton = (
            <Box className="toolbar-item toolbar-item--fixed toolbar-item-right" key="add">
                <Button
                    onClick={actionButton}
                    bsStyle={actionButtonBsStyle}
                    label={actionButtonLabel}
                    image={actionButtonImage}
                    imageMd={actionButtonImageMd}
                    {...(!isDesktop ? { inverse: true } : null)}
                />
            </Box>
        );

        if (addLinkTo || onAdd || externalHrefButton) {
            if (externalHrefButton) {
                buttons = [addButtonHref, ...buttons];
            } else {
                if (showPlusIcon) {
                    return addButton;
                }
                buttons = [addButton, ...buttons];
            }
        }

        if (actionButton) {
            return addActionButton;
        }

        if (rightChildren) {
            return rightChildren;
        }

        if (handleInviteClick) {
            buttons = [
                <Box className="toolbar-item toolbar-item--fixed toolbar-item-right" key="invite">
                    <Button
                        onClick={handleInviteClick}
                        image="/images/plusCircle.svg"
                        bsStyle="outline"
                        label="administration.users.list.addUser"
                    />
                </Box>,
                ...buttons,
            ];
        }

        if (hasActiveSession && isMobile && !closeLinkTo && !onClose && dashboard) {
            const { maskAmount } = this.props;

            return (
                <div className="toolbar-item toolbar-item--fixed toolbar-item-right mailIcon">
                    <Button
                        imageMd
                        onClick={() => {
                            this.updateMaskAmount();
                        }}
                        bsStyle="link"
                        className="btn-only-icon"
                        image={maskAmount ? "images/icons/showOff.svg" : "images/icons/show.svg"}
                    />

                    <Button imageMd className="btn-only-icon" image="images/icons/bell.svg" href="/communications">
                        <Badge count={unreadCommunications} />
                        <I18n id="menu.chat.badge" componentProps={{ className: "visually-hidden" }} />
                    </Button>

                    <Button
                        imageMd
                        className="btn-only-icon"
                        image="images/icons/logOut.svg"
                        onClick={() => this.logOut()}
                    />
                </div>
            );
        }

        return buttons.length ? <Fragment>{buttons}</Fragment> : null;
    };

    getMenu = () => {
        const { hasActiveSession, isMobile, environments, hideNavbarInMobile } = this.props;
        const buildNumber = window.BUILD_NUMBER ? `(Build ${window.BUILD_NUMBER})` : "";

        if (hasActiveSession && isMobile && !hideNavbarInMobile) {
            return (
                <Fragment>
                    <Navbar.Collapse in={this.state.menuClosed}>
                        {environments && Object.keys(environments).length >= 1 && (
                            <Box className="last-login-wrapper">{this.getLastLoginInfo()}</Box>
                        )}

                        <Menu
                            isMobile={isMobile}
                            onOptionClicked={() => {
                                this.menuCloseHandler();
                            }}
                        />
                        <p className="app-version-mobile">
                            {/* global process */}
                            <I18n id="global.version" component="small" /> {process.env.REACT_APP_VERSION} {buildNumber}
                        </p>
                    </Navbar.Collapse>
                    <Box className="menu-overlay" onClick={this.menuCloseHandler} />
                </Fragment>
            );
        }

        return null;
    };

    getUserFirstName = (userFirstName) => (
        <Box display="flex">
            <Text
                component="h1"
                className="welcome-in-desktop"
                align="left"
                color="inverse"
                labelKey="desktop.welcome"
                username={userFirstName}
            />
        </Box>
    );

    getCenterContent = () => {
        const {
            children,
            dashboard,
            logo,
            replace,
            title,
            titleText,
            hasCenterContent,
            accessibilityTextId,
            align,
            userFirstName,
            isMobile,
            lightText,
            titleImage,
            subtitlePrefix,
            subtitle,
            subtitleUppercase,
        } = this.props;

        return (
            (title || titleText || logo || hasCenterContent || accessibilityTextId) && (
                <>
                    <Helmet>
                        <title>
                            {// eslint-disable-next-line no-nested-ternary
                            utilsI18n.get(accessibilityTextId || title) !== "*undefined*"
                                ? `Banesco - ${`${utilsI18n.get(accessibilityTextId || title)} ${replace.USER_NAME ||
                                      ""}`}`
                                : titleText !== ""
                                ? `Banesco - ${titleText}`
                                : "Banesco"}
                        </title>
                    </Helmet>

                    {dashboard && (
                        <>
                            <Image
                                wrapperClassName="brand-logo-wrapper mr-3"
                                src="images/icons/MbBanescoLogo.svg"
                                ariaLabel="global.companyName"
                            />
                            {this.getUserFirstName(userFirstName)}
                        </>
                    )}

                    {(title || titleText) && !dashboard && (
                        <>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {isMobile ? (
                                <Box display="flex" column={subtitlePrefix && subtitle}>
                                    <Text
                                        component={subtitlePrefix && subtitle ? "span" : "h1"}
                                        size={subtitlePrefix && subtitle ? 1 : null}
                                        bold={subtitlePrefix && subtitle}
                                        className="mx-8 px-2"
                                        color="inverse">
                                        {title && utilsI18n.get(title)}
                                        {titleText}
                                        {titleImage ? (
                                            <Image
                                                height="5"
                                                wrapperWidth="8"
                                                wrapperHeight="5"
                                                className="ml-3"
                                                src={titleImage}
                                            />
                                        ) : null}
                                    </Text>
                                    {subtitlePrefix && subtitle ? (
                                        <Box display="flex" alignX="center" className="mt-1">
                                            <Text size={6} color="inverse" uppercase>
                                                {subtitlePrefix} {subtitle}
                                            </Text>
                                        </Box>
                                    ) : null}
                                </Box>
                            ) : subtitlePrefix || subtitle ? (
                                <Box display="flex" column alignY="flex-start">
                                    <ViewTitle
                                        align={align}
                                        accessibilityTextId={accessibilityTextId}
                                        defaultValue={titleText}
                                        id={title}
                                        lightText={lightText}
                                        replace={replace}
                                    />
                                    <Box display="flex" alignX="center" className="mt-1" position="absolute" bottom="0">
                                        <Text size={6} color="heading" uppercase={subtitleUppercase}>
                                            {subtitlePrefix} {subtitle}
                                        </Text>
                                    </Box>
                                </Box>
                            ) : (
                                <ViewTitle
                                    align={align}
                                    accessibilityTextId={accessibilityTextId}
                                    defaultValue={titleText}
                                    id={title}
                                    lightText={lightText}
                                    replace={replace}
                                />
                            )}
                        </>
                    )}
                    {children}
                    {logo}

                    {this.getCenterOption()}
                </>
            )
        );
    };

    getCenterOption = () => {
        const { linkTo, linkText } = this.props;
        if (linkTo) {
            return (
                <Link to={linkTo}>
                    <I18n component="p" id={linkText} />
                </Link>
            );
        }

        return null;
    };

    render() {
        const { dashboard, isMobile, contentLeft, additionalClassName, position } = this.props;
        return (
            <>
                {isMobile ? (
                    <Header
                        position={position}
                        {...(additionalClassName && { additionalClassName })}
                        collapsed={this.state.menuClosed}>
                        <Box className="header-wrapper">
                            <Box className="left-content">{this.getLeftOption()}</Box>
                            <Box
                                className={classNames("center-content", {
                                    "center-content-left": contentLeft,
                                })}
                                ref={this.titleRef}>
                                {this.getCenterContent()}
                            </Box>
                            <Box
                                className={classNames("right-content", {
                                    "right-content-relative": dashboard,
                                })}>
                                {this.getRightOption()}
                            </Box>
                            {this.getMenu()}
                        </Box>
                    </Header>
                ) : (
                    <Header {...(additionalClassName && { additionalClassName })} collapsed={this.state.menuClosed}>
                        <Box display="flex" alignY="center" alignX="center" fullWidth>
                            {this.getLeftOption()}
                            <Box
                                className={`toolbar-item view-title ${dashboard && "dashboard-title"}`}
                                ref={this.titleRef}
                                noWrap>
                                {this.getCenterContent()}
                            </Box>
                            {this.getRightOption()}
                            {this.getMenu()}
                        </Box>
                    </Header>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: assistantSelectors.isAssistantLogin(state) ? false : sessionSelectors.isLoggedIn(state),
    unreadCommunications: communicationsSelectos.getUnreadCommunications(state),
    environments: sessionSelectors.getEnvironments(state),
    loggedUser: sessionSelectors.getUser(state),
    maskAmount: sessionSelectors.getMaskAmountUpdate(state),
    isFetchingDownload: transactionsSelectors.isFetchingDownload(state),
});

export default connect(mapStateToProps)(resizableRoute(withTransactionFilterContext(Head)));
