import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { PDFTextField, PDFTextFieldFirst } from "pages/forms/customForms/PDFTicket";
import FormTransition from "pages/forms/_components/FormTransition";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { Field } from "formik";
import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import FormattedAmount from "pages/_components/FormattedAmount";
import Col from "react-bootstrap/lib/Col";
import Row from "pages/_components/Row";
import FormattedDate from "pages/_components/FormattedDate";
import { bool, func, shape, string } from "prop-types";
import GridTable from "pages/_components/GridTable/GridTable";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { validationText } from "util/validationSchemaUtil";
import { selectors as creditCardMovementsSelectors } from "reducers/creditCardMovementDetails";
import PageLoading from "pages/_components/PageLoading";
import * as config from "util/config";
import moment from "moment";
import { useCustomFinancialList } from "./hooks/TransferCustomHooks";

const ID_FORM = "installment.purchase";
const ID_ACTIVITY = `${ID_FORM}.send`;
const TITLE_FORM = "installment.purchase.title";
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

const normalizeAmount = (amountStr) =>
    amountStr.replace(/(-\d+\.\d+)/g, (match) => Math.abs(parseFloat(match)).toFixed(2));

const InstallmentPurchaseForm = (props) => {
    const {
        mode,
        currentLang,
        preDataForm,
        fromBackoffice,
        dispatch,
        location,
        isDesktop,
        movement,
        transaction,
    } = props;

    const [convertedAmount, setConvertedAmount] = useState(null);
    const [chargeCommission, setChargeCommission] = useState(null);
    const listInteresRate = useCustomFinancialList(preDataForm?.financialPlans);
    const [listInstallments, setListInstallments] = useState([]);

    const {
        date,
        concept,
        sourceAmount,
        sourceAmountCurrency,
        creditCardfranchise,
        creditCardShortLabel,
        reference,
        number,
        idProduct,
    } = movement || {};

    const creditCardTittle = `${creditCardShortLabel?.substring(9)} ${creditCardfranchise}`;

    const homologationMap = config.getIdValueMapArray("installment.purchase.valid.codes.homologation");
    const chargeCommissionMap = config.getIdValueMapArray("installment.purchase.charge.commission.percentages");

    useEffect(() => {
        if (mode === "edit") {
            if (!preDataForm?.financialPlans) {
                const requestData = {
                    isMobile: !isDesktop,
                };
                dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData }));
            }
        }
    }, [dispatch, mode, location]);

    const genericProps = {
        mode,
        lang: currentLang,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const renderTicket = (values) => (
        <>
            <PDFTextFieldFirst
                idForm={ID_FORM}
                name="reference"
                values={values}
                label={i18n.get(`${ID_FORM}.transactionNumber.label`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                name="transactionDate"
                value={moment(transaction?.creationDateTime).format("DD/MM/YYYY")}
                label={i18n.get(`${ID_FORM}.transactionDate.label`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                name="typeOperation"
                value={i18n.get(`${TITLE_FORM}`)}
                label={i18n.get(`${ID_FORM}.typeOperation.label`)}
            />

            <PDFTextField
                idForm={ID_FORM}
                name="creditCardTittle"
                values={values}
                label={i18n.get(`${ID_FORM}.creditCard.label`)}
            />

            <PDFTextField
                idForm={ID_FORM}
                name="transactionDescription"
                values={values}
                label={i18n.get(`${ID_FORM}.transactionDescription.label`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                name="transactionAmount"
                values={values}
                label={i18n.get(`${ID_FORM}.transactionAmount.label`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                name="interestRate"
                value={`${values?.interestRate[0]?.id || ""} %`}
                label={i18n.get(`${ID_FORM}.interestRate.label`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                name="numberOfInstallments"
                value={values?.numberOfInstallments[0]?.label}
                label={i18n.get(`${ID_FORM}.numberOfInstallments.label`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                name="convertedAmt"
                value={normalizeAmount(values?.convertedAmt)}
                label={i18n.get(`${ID_FORM}.monthlyInstallment.label`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                name="chargeCommission"
                value={normalizeAmount(values?.chargeCommission)}
                label={i18n.get(`${ID_FORM}.chargeCommission.label`)}
            />
        </>
    );

    const onChangeInterestRate = (e, setFieldValue) => {
        const interestSelected = listInteresRate.filter((x) => x.id === e[0]?.id);
        setListInstallments(interestSelected[0]?.installments);
        setFieldValue("term", interestSelected[0]?.id);
    };

    const onChangeTerm = (e, setFieldValue) => {
        setFieldValue("option", e[0]?.id);
        const item = homologationMap.find((itemAux) => itemAux.id === e[0]?.id);
        const _number = item?.value;
        const convertedAmt = sourceAmount && _number ? (sourceAmount / _number).toFixed(2) : "";
        setFieldValue("convertedAmt", `${sourceAmountCurrency} ${convertedAmt}`);
        setConvertedAmount(convertedAmt);

        const termPercentage = chargeCommissionMap.find((itemAux) => itemAux.id === item?.value);
        const chargeCommissionAmount = Math.abs((sourceAmount * termPercentage?.value).toFixed(2));
        setFieldValue("chargeCommission", `${sourceAmountCurrency} ${chargeCommissionAmount}`);
        setChargeCommission(chargeCommissionAmount);
    };

    const validationSchema = () =>
        Yup.object().shape({
            option: validationText(),
            term: validationText(),
        });

    const getColumnsTemplate = () => (isDesktop ? "1fr 1fr 1fr" : "1fr 1fr");
    const getColumnsTemplateBottom = () => (isDesktop ? "2fr 1fr 1fr" : "1fr 1fr");

    const [init, setInit] = useState(false);

    const renderFields = (setFieldValue, values) => {
        const optionsSelected = values?.interestRate && values?.numberOfInstallments;

        if ((init === undefined || init !== true) && mode === "edit" && idProduct) {
            setInit(true);
            setFieldValue("reference", reference);
            setFieldValue("number", number);
            setFieldValue("creditcard", idProduct);
            setFieldValue("creditCardTittle", creditCardTittle);
            setFieldValue("isMobile", !isDesktop);
            setFieldValue("transactionAmount", `${sourceAmountCurrency} ${sourceAmount.toFixed(2)}`);
            setFieldValue("transactionDate", date);
            setFieldValue("transactionDescription", concept);
        }

        if (mode === "edit" && (!preDataForm || !preDataForm.financialPlans)) {
            return <PageLoading loading />;
        }

        return (
            <>
                {mode === "edit" && (
                    <Row gapY={isDesktop ? "7" : "3"} className="pb-3">
                        <Col xs={12}>
                            <Box
                                background="component-background"
                                className="pt-6 mt-5 mt-md-0 mx-n-5"
                                borderRadius="default">
                                <Box
                                    className="px-5 pb-5 pb-md-0 mb-5 mb-md-6"
                                    {...(!isDesktop && { border: "bottom-background-divider-1" })}>
                                    <Text
                                        component="h2"
                                        className="m-0"
                                        size="4"
                                        bold
                                        labelKey={`${ID_FORM}.purchaseDetail.header.label`}
                                    />
                                </Box>
                                <Box
                                    {...(!isDesktop && { display: "flex", column: true, className: "px-5 pb-5" })}
                                    borderRadius="bottom-default"
                                    overflow="hidden">
                                    {isDesktop ? (
                                        <GridTable>
                                            <GridTable.Header>
                                                <GridTable.Container
                                                    className={classNames({ "gapX-3": isDesktop })}
                                                    padding={isDesktop ? "py-3 px-5" : "py-2 pl-5 pr-3"}
                                                    columnsTemplate={getColumnsTemplate()}
                                                    borderRadius="bottom-default"
                                                    border="none">
                                                    <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                                                        <Text labelKey={`${ID_FORM}.transactionDate.header.label`} />
                                                    </GridTable.Data>
                                                    <GridTable.Data columnStart={2} alignX="flex-start" inHeader>
                                                        <Text
                                                            labelKey={`${ID_FORM}.transactionDescription.header.label`}
                                                        />
                                                    </GridTable.Data>
                                                    <GridTable.Data columnStart={3} alignX="center" inHeader>
                                                        <Text labelKey={`${ID_FORM}.transactionAmount.header.label`} />
                                                    </GridTable.Data>
                                                </GridTable.Container>
                                            </GridTable.Header>
                                            <GridTable.Body>
                                                <GridTable.Container
                                                    className={classNames({ "gapX-3": isDesktop })}
                                                    padding={isDesktop ? "py-3 px-5" : "py-2 pl-5 pr-3"}
                                                    columnsTemplate={getColumnsTemplate()}
                                                    removeBottomSpacing
                                                    border="none">
                                                    <GridTable.Data columnStart={1} alignX="flex-start">
                                                        <FormattedDate date={date} anotherFormat="DD/MM/YYYY" />
                                                    </GridTable.Data>
                                                    <GridTable.Data columnStart={2} alignX="flex-start">
                                                        <Text>{concept}</Text>
                                                    </GridTable.Data>
                                                    <GridTable.Data columnStart={3} alignX="center">
                                                        <FormattedAmount
                                                            color={sourceAmount < 0 ? "error" : "heading"}
                                                            quantity={sourceAmount}
                                                            noCurrency
                                                        />
                                                    </GridTable.Data>
                                                </GridTable.Container>
                                            </GridTable.Body>
                                        </GridTable>
                                    ) : (
                                        <>
                                            <Box display="flex" alignX="between" className="mb-3">
                                                <Text
                                                    component="label"
                                                    size="6"
                                                    className="m-0"
                                                    addColon
                                                    regular
                                                    labelKey={`${ID_FORM}.transactionDate.header.label`}
                                                    uppercase
                                                />
                                                <FormattedDate date={date} anotherFormat="DD/MM/YYYY" size="6" />
                                            </Box>
                                            <Box display="flex" alignX="between" className="mb-3">
                                                <Text
                                                    component="label"
                                                    size="6"
                                                    className="m-0"
                                                    addColon
                                                    regular
                                                    labelKey={`${ID_FORM}.transactionDescription.header.label`}
                                                    uppercase
                                                />
                                                <Text size="6" align="right" ellipsis>
                                                    {concept}
                                                </Text>
                                            </Box>
                                            <Box display="flex" alignX="between" className="mb-3">
                                                <Text
                                                    component="label"
                                                    size="6"
                                                    className="m-0"
                                                    addColon
                                                    regular
                                                    labelKey={`${ID_FORM}.transactionAmount.header.label`}
                                                    uppercase
                                                />
                                                <FormattedAmount
                                                    color={sourceAmount < 0 ? "error" : "heading"}
                                                    quantity={sourceAmount}
                                                    noCurrency
                                                    size="6"
                                                />
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={12} md={6}>
                            <Box
                                background="component-background"
                                borderRadius="default"
                                className="pt-5 pt-md-8 pb-8 pb-md-10 px-5 px-md-9 mx-n-5">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Selector}
                                    key="interestRate"
                                    name="interestRate"
                                    idField="interestRate"
                                    labelIdField="react-select-interestRate-input"
                                    customPlaceholder={i18n.get("forms.placeholder.select")}
                                    optionList={listInteresRate}
                                    renderAs="combo"
                                    onChange={(e) => {
                                        onChangeInterestRate(e, setFieldValue);
                                    }}
                                />
                            </Box>
                        </Col>
                        <Col xs={12} md={6}>
                            <Box
                                background="component-background"
                                borderRadius="default"
                                className="pt-5 pt-md-8 pb-8 pb-md-10 px-5 px-md-9 mx-n-5">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Selector}
                                    key="numberOfInstallments"
                                    name="numberOfInstallments"
                                    idField="numberOfInstallments"
                                    labelIdField="react-select-numberOfInstallments-input"
                                    customPlaceholder={i18n.get("forms.placeholder.select")}
                                    optionList={listInstallments}
                                    renderAs="combo"
                                    onChange={(e) => {
                                        onChangeTerm(e, setFieldValue);
                                    }}
                                />
                            </Box>
                        </Col>
                        {((optionsSelected && isDesktop) || isDesktop) && (
                            <Col xs={12}>
                                <Box background="component-background" borderRadius="default" overflow="hidden">
                                    <GridTable>
                                        <GridTable.Header>
                                            <GridTable.Container
                                                className={classNames({ "gapX-3": isDesktop })}
                                                padding={isDesktop ? "py-3 px-5" : "py-2 pl-5 pr-3"}
                                                columnsTemplate={getColumnsTemplateBottom()}
                                                border="none">
                                                <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                                                    <Text labelKey={`${ID_FORM}.installmentDescription.header.label`} />
                                                </GridTable.Data>
                                                <GridTable.Data columnStart={2} alignX="center" inHeader>
                                                    <Text labelKey={`${ID_FORM}.monthlyInstallment.header.label`} />
                                                </GridTable.Data>
                                                <GridTable.Data columnStart={3} alignX="flex-end" inHeader>
                                                    <Text labelKey={`${ID_FORM}.installmentTerm.header.label`} />
                                                </GridTable.Data>
                                            </GridTable.Container>
                                        </GridTable.Header>
                                        <GridTable.Body>
                                            <GridTable.Container
                                                className={classNames({ "gapX-3": isDesktop })}
                                                padding={isDesktop ? "py-3 px-5" : "py-2 pl-5 pr-3"}
                                                columnsTemplate={getColumnsTemplateBottom()}
                                                removeBottomSpacing
                                                border="none">
                                                {optionsSelected && (
                                                    <>
                                                        <GridTable.Data columnStart={1} alignX="flex-start">
                                                            <Text>{concept}</Text>
                                                        </GridTable.Data>
                                                        <GridTable.Data columnStart={2} alignX="center">
                                                            <FormattedAmount
                                                                quantity={Math.abs(convertedAmount)}
                                                                currency="USD"
                                                            />
                                                        </GridTable.Data>
                                                        <GridTable.Data columnStart={3} alignX="flex-end">
                                                            <Text>{values?.numberOfInstallments[0]?.label}</Text>
                                                        </GridTable.Data>
                                                    </>
                                                )}
                                            </GridTable.Container>
                                        </GridTable.Body>
                                    </GridTable>
                                </Box>
                            </Col>
                        )}
                        {optionsSelected && !isDesktop && (
                            <Col xs={12}>
                                <Box background="component-background" className="pt-6 mx-n-5" borderRadius="default">
                                    <Box
                                        className="px-5 pb-5 pb-md-0 mb-5 mb-md-6"
                                        {...(!isDesktop && { border: "bottom-background-divider-1" })}>
                                        <Text
                                            component="h2"
                                            className="m-0"
                                            size="4"
                                            bold
                                            labelKey={`${ID_FORM}.installmentsTerm.header.label`}
                                        />
                                    </Box>
                                    <Box display="flex" column className="px-5 pb-5">
                                        <Box display="flex" alignX="between" className="mb-3">
                                            <Text
                                                component="label"
                                                size="6"
                                                className="m-0"
                                                addColon
                                                regular
                                                uppercase
                                                labelKey={`${ID_FORM}.installmentDescription.header.label`}
                                            />
                                            <Text size="6" align="right" ellipsis>
                                                {concept}
                                            </Text>
                                        </Box>
                                        <Box display="flex" alignX="between" className="mb-3">
                                            <Text
                                                component="label"
                                                size="6"
                                                className="m-0"
                                                addColon
                                                regular
                                                uppercase
                                                labelKey={`${ID_FORM}.monthlyInstallment.header.label`}
                                            />
                                            <FormattedAmount size="6" quantity={convertedAmount} currency="USD" />
                                        </Box>
                                        <Box display="flex" alignX="between" className="mb-3">
                                            <Text
                                                component="label"
                                                size="6"
                                                className="m-0"
                                                addColon
                                                regular
                                                uppercase
                                                labelKey={`${ID_FORM}.installmentTerm.header.label`}
                                            />
                                            <Text size="6">{values?.numberOfInstallments[0]?.label}</Text>
                                        </Box>
                                    </Box>
                                </Box>
                            </Col>
                        )}
                    </Row>
                )}
                {mode === "preview" || mode === "view" ? (
                    <Box className={classNames("mt-3", { "mt-6 mx-7": mode === "view" })}>
                        {mode === "view" && (
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                label={`${ID_FORM}.transactionNumber.label`}
                                value={values?.reference}
                            />
                        )}
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            label={`${ID_FORM}.transactionDate.label`}
                            value={moment(values?.transactionDate).format("DD/MM/YYYY")}
                        />
                        {mode === "view" && (
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                label={`${ID_FORM}.typeOperation.label`}
                                value={i18n.get(`${TITLE_FORM}`)}
                            />
                        )}
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            label={`${ID_FORM}.creditCard.label`}
                            value={values?.creditCardTittle}
                        />
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            label={`${ID_FORM}.transactionDescription.label`}
                            value={values?.transactionDescription}
                        />
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            label={`${ID_FORM}.transactionAmount.label`}
                            value={values?.transactionAmount}
                        />
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            label={`${ID_FORM}.interestRate.label`}
                            value={`${values?.interestRate[0]?.id || ""} %`}
                        />
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            label={`${ID_FORM}.numberOfInstallments.label`}
                            value={values?.numberOfInstallments[0]?.label}
                        />
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            label={`${ID_FORM}.monthlyInstallment.label`}
                            value={
                                convertedAmount
                                    ? `${sourceAmountCurrency} ${Math.abs(convertedAmount)}`
                                    : normalizeAmount(values?.convertedAmt)
                            }
                        />
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            label={`${ID_FORM}.chargeCommission.label`}
                            value={
                                chargeCommission
                                    ? `${sourceAmountCurrency} ${chargeCommission}`
                                    : normalizeAmount(values?.chargeCommission)
                            }
                        />
                    </Box>
                ) : null}
            </>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        showFilterChips: false,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        showSchedulerMessage: false,
        validationSchema,
        subtitle: mode !== "view" ? `${creditCardfranchise} ${creditCardShortLabel}` : null,
        onlyBackToDesktopBtn: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    movement: creditCardMovementsSelectors.getSelectedMovement(state),
});

InstallmentPurchaseForm.propTypes = {
    history: shape({}).isRequired,
    mode: string,
    currentLang: string,
    preDataForm: shape({}),
    fromBackoffice: bool,
    location: shape({}),
    dispatch: func.isRequired,
    transaction: shape({}),
    data: shape({}),
    movement: shape({}),
    isDesktop: bool.isRequired,
};

InstallmentPurchaseForm.defaultProps = {
    fromBackoffice: false,
    currentLang: "",
    mode: "edit",
    preDataForm: {},
    location: {},
    transaction: {},
    data: {},
    movement: null,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(InstallmentPurchaseForm));
