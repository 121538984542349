import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import FormattedAmount from "pages/_components/FormattedAmount";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import ModalManualRole from "pages/forms/customForms/ModalManualRole";
import { bool, func, shape, string } from "prop-types";
import TransactionFilterProvider, { XLS_CONTENT_TYPE } from "providers/TransactionFilterProvider";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routerActions } from "react-router-redux/actions";
import { selectors as loginSelectors } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as updateUSerDataSelectors } from "reducers/updateUserData/updateUserData.reducer";
import { ReactComponent as Show } from "styles/images/search.svg";
import * as i18nUtils from "util/i18n";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import Sticker from "pages/_components/Sticker";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import { actions as paymentItemActions } from "reducers/paymentItem";
import TransactionStatus from "pages/transactions/_components/TransactionStatus";

const ID_FORM = "role.payment";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ITEMS_PER_PAGE = 10;

const RolePaymentDetail = ({
    dispatch,
    currency,
    // setValue,
    // onChangeManual,
    isEditable,
    mode,
    isFetchingDownload,
    location,
    match,
    preDataForm,
    manualPaymentList,
    fetching,
    showExtraData,
    setLocationCustomPath,
    formValues,
    isDesktop,
}) => {
    const [amountTotal, setAmountTotal] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [paymentList, setPaymentList] = useState([]);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [isTicket, setIsTicket] = useState(false);
    const [inputSearchTerm, setInputSearchTerm] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [visibleItemsCount, setVisibleItemsCount] = useState(ITEMS_PER_PAGE);

    const filterData = (filterText) => {
        const filterTextLowerCase = filterText?.toLowerCase();
        const filterList =
            filterTextLowerCase && filterTextLowerCase !== ""
                ? paymentList.filter(
                      (item) =>
                          item?.reference?.toLowerCase().includes(filterTextLowerCase) ||
                          item?.beneficiaryName?.toLowerCase().includes(filterTextLowerCase) ||
                          item?.accountNumber?.toLowerCase().includes(filterTextLowerCase) ||
                          (/^-?\d*\.?\d+$/.test(filterTextLowerCase)
                              ? parseFloat(filterTextLowerCase).toFixed(2) ===
                                parseFloat(item?.amount?.quantity?.toString()).toFixed(2)
                              : false),
                  )
                : [...paymentList];
        return filterList;
    };

    const filteredData = filterData(searchTerm);
    const currentItems = filteredData.slice(0, visibleItemsCount);

    const handleSearch = () => {
        setSearchTerm(inputSearchTerm);
        setVisibleItemsCount(ITEMS_PER_PAGE);
    };

    const handleLoadMore = () => {
        setVisibleItemsCount((prevCount) => prevCount + ITEMS_PER_PAGE);
    };

    const genericProps = {
        mode,
        // lang: currentLang,
        // idTransactionTicket: idTransaction,
        // fromBackoffice,
        // isRequired: true,
        idActivity: ID_ACTIVITY,
        // dispatch,
    };

    const downloadFilterTransaction = (contentType) => {
        const idTransaction = match?.params?.idTransaction;
        dispatch(formActions.downloadRolePaymentListRequest(contentType, filteredData, idTransaction, searchTerm));
    };

    const renderDownloadDocs = (downloading, documents) => {
        if (!downloading) {
            return (
                <Dropdown
                    fetching={isFetchingDownload !== undefined ? isFetchingDownload : false}
                    image="images/download.svg"
                    label="global.download"
                    labelClassName="visually-hidden"
                    bsStyle="only-icon"
                    pullRight>
                    {documents.map((item) => (
                        <Button
                            key={item.label}
                            label={item.label}
                            onClick={() => {
                                item.onClick(downloadFilterTransaction);
                            }}
                            className="dropdown__item-btn"
                            bsStyle="link"
                        />
                    ))}
                </Dropdown>
            );
        }
        return <Button label="downloading" bsStyle="only-icon" loading />;
    };

    useEffect(() => {
        if (location.state && location.state.showModal) {
            setShowModal(true);
        }
    }, [location]);

    const closeAddItemModal = () => {
        setShowModal(false);
        setItemToEdit(null);
    };

    const showAddItemModal = () => {
        setShowModal(true);
    };

    const editItem = (payment) => {
        const paymentEditedIndex = paymentList.findIndex((p) => p.id === payment.id);

        const newPaymentList = paymentList
            .slice(0, paymentEditedIndex)
            .concat([payment])
            .concat(paymentList.slice(paymentEditedIndex + 1, paymentList.length));

        setPaymentList(newPaymentList);
        setItemToEdit(null);
        setShowModal(false);
    };

    const addItem = (payment) => {
        setPaymentList(paymentList.concat(payment));
        setShowModal(false);
    };

    const handleBack = () => {
        const amount = {
            currency: "USD",
            quantity: manualPaymentList?.reduce((acc, val) => acc + parseFloat(val.amount.quantity), 0) || 0,
        };
        dispatch(
            formActions.rolePaymentSetDetailData(
                manualPaymentList,
                amount,
                formValues.uploadBy === "inputManually",
                false,
                formValues.debitAccountId,
                formValues.commentary,
                formValues?.scheduler,
            ),
        );
        dispatch(routerActions.goBack());
    };

    const saveAndContinue = () => {
        dispatch(
            formActions.rolePaymentSetDetailData(
                paymentList,
                amountTotal,
                true,
                true,
                formValues.debitAccountId,
                formValues.commentary,
                formValues?.scheduler,
            ),
        );
        dispatch(routerActions.goBack());
    };

    /**
     * Set position revelock
     */

    const getLocationBasePath = () => {
        const basePathSplit = match?.path?.split(":");
        return basePathSplit && basePathSplit.length > 0 ? basePathSplit[0] : "";
    };

    const setRevelockLocation = () => {
        if (setLocationCustomPath) {
            const pageId = match.url.includes("salaryPayment") ? "role.payment.details" : "suppliers.payment.details";
            setLocationCustomPath(getLocationBasePath(), pageId);
        }
    };

    const getColumnsTemplateHeader = () => {
        if (!isDesktop) {
            return "1fr auto 0.5rem";
        }
        if (showExtraData && isEditable) {
            return "1fr 1fr 1fr 1fr 1fr 8rem";
        }
        if (showExtraData) {
            return "1fr 1fr 1fr 1fr 1fr minmax(1.5rem, 2rem)";
        }
        if (isEditable) {
            return "1fr 1fr 1fr 1fr 9rem";
        }
        return "1fr 1fr 1fr 1fr minmax(1.5rem, 2rem)";
    };

    const getColumnsTemplateBody = () => {
        if (!isDesktop) {
            return "1fr auto 1.5rem";
        }
        if (showExtraData && isEditable) {
            return "1fr 1fr 1fr 1fr 1fr minmax(1.5rem, 2rem)";
        }
        if (showExtraData) {
            return "1fr 1fr 1fr 1fr 1fr minmax(1.5rem, 2rem)";
        }
        if (isEditable) {
            return "1fr 1fr 1fr 1fr minmax(1.5rem, 2rem)";
        }
        return "1fr 1fr 1fr 1fr minmax(1.5rem, 2rem)";
    };

    const calculateAmount = (items) => {
        const itemArray = items || paymentList;
        let amountTot = 0;
        if (itemArray) {
            const itemsDefault = itemArray || [];
            amountTot = itemsDefault.reduce((acc, val) => acc + parseFloat(val.amount.quantity), 0);
        }

        setAmountTotal({
            currency: "USD",
            quantity: amountTot,
        });
    };

    const getCurrencyItems = () => {
        let currencyResult = currency;
        if (paymentList && paymentList?.length > 0) {
            currencyResult = paymentList[0]?.amount.currency || currency;
        }

        return currencyResult;
    };

    const selectItemToEdit = (id) => {
        const item = paymentList.find((p) => p.id === id);
        setItemToEdit(item);
        setShowModal(true);
    };
    const deleteItem = (itemRemove) => {
        if (paymentList) {
            const itemsFilter = paymentList?.filter((item) => item.id !== itemRemove.id);
            setPaymentList(itemsFilter);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line eqeqeq
        if (!preDataForm || preDataForm == {}) {
            const formData = { debitAccount: null };
            dispatch(formActions.preForm({ idActivity: "role.payment.pre", formData }));
        }
        // eslint-disable-next-line
    }, [dispatch, mode, location]);

    useEffect(() => {
        calculateAmount();
        setRevelockLocation();
    }, []);

    useEffect(() => {
        if (manualPaymentList && manualPaymentList.length) {
            setPaymentList(manualPaymentList);
        }
    }, [manualPaymentList]);

    useEffect(() => {
        const idTransaction = match?.params?.idTransaction;
        if (idTransaction) {
            setIsTicket(true);
            dispatch(formActions.rolePaymentFileDataRequest(idTransaction));
        }
    }, [match]);

    useEffect(() => {
        calculateAmount();
    }, [paymentList]);

    const parseProductType = (type) => {
        switch (type) {
            case "S":
                return i18nUtils.get("forms.transfers.local.productType.other.CA");
            case "C":
                return i18nUtils.get("forms.transfers.local.productType.other.CC");
            case "T":
                return i18nUtils.get("forms.transfers.local.productType.other.TC");
            case "P":
                return i18nUtils.get("forms.transfers.local.productType.other.PR");
            default:
                return "";
        }
    };

    const parseItemStatus = (status) => {
        switch (status) {
            case "SEND":
            case "PROCESSED":
                return "success";
            case "PENDING":
                return "warning";
            default:
                return "error";
        }
    };

    const getColumnStart = () => {
        if (isDesktop) {
            return showExtraData ? 6 : 5;
        }
        return 3;
    };

    const isTransactionCreated = (item) => {
        const transactionId = item.idTransaction || match?.params?.idTransaction;
        return transactionId !== undefined && transactionId !== null;
    };

    const showPaymentItemTicket = (item) => {
        const idTransaction = match?.params?.idTransaction;
        const transactionId = item.idTransaction || idTransaction;
        const requestData = {
            transactionId,
            item,
            showExtraData,
        };
        dispatch(paymentItemActions.getPaymentItemDetailsRequest(requestData));
    };

    const renderItemTable = () => {
        if (!currentItems) {
            return <div />;
        }

        const listItems = currentItems.map((item, index) => (
            <>
                <GridTable.Container
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    columnsTemplate={getColumnsTemplateBody()}
                    className="gapX-9"
                    padding={isDesktop ? "px-5" : "pl-5 pt-3 pb-4"}>
                    <GridTable.Data columnStart={1} alignX="flex-start">
                        <Box display="flex" column>
                            <Text size="6" color="primary" bold>
                                {item.beneficiaryName}
                            </Text>
                            <Text size="6">{item.bank?.label || item.bank}</Text>
                        </Box>
                    </GridTable.Data>
                    {isDesktop && (
                        <>
                            <GridTable.Data columnStart={2} alignX="center">
                                <Text size="6">{item.accountNumber}</Text>
                            </GridTable.Data>
                            {!showExtraData && (
                                <GridTable.Data columnStart={3} alignX="center">
                                    <Text size="6">
                                        {item.productType?.label || parseProductType(item.productType)}
                                    </Text>
                                </GridTable.Data>
                            )}
                            <GridTable.Data columnStart={showExtraData ? 3 : 4} alignX="flex-end">
                                <FormattedAmount currency={getCurrencyItems()} quantity={item.amount.quantity} />
                            </GridTable.Data>
                            {showExtraData && (
                                <GridTable.Data columnStart={4} alignX="flex-end">
                                    <Text size="6">{item.reference || "-"}</Text>
                                </GridTable.Data>
                            )}
                        </>
                    )}

                    {isDesktop ? (
                        <>
                            {showExtraData && (
                                <GridTable.Data columnStart={5} alignX="flex-end">
                                    <Sticker
                                        status={parseItemStatus(item.status)}
                                        className="ml-3"
                                        uppercase={false}
                                        bold>
                                        {i18nUtils.get(
                                            `role.payment.item.status.${item.status?.toLowerCase() ||
                                                "role.payment.item.status.error"}`,
                                        )}
                                    </Sticker>
                                </GridTable.Data>
                            )}
                        </>
                    ) : (
                        <GridTable.Data
                            columnStart={2}
                            columnWidth={1}
                            rowStart={1}
                            rowWidth={1}
                            alignX="center"
                            alignY="center">
                            <Box display="flex" column alignX="center" alignY="flex-end" fullWidth fullHeight>
                                <FormattedAmount
                                    size="4"
                                    className="mb-2"
                                    currency={currency}
                                    quantity={item.amount.quantity}
                                />
                                <TransactionStatus
                                    showIcon={false}
                                    idTransactionStatus={item.status === "ERROR" ? "FAILED" : item.status}
                                    showLabel
                                    customLabelKey={`role.payment.item.status.${item.status?.toLowerCase()}`}
                                />
                            </Box>
                        </GridTable.Data>
                    )}

                    {isTransactionCreated(item) && item.status !== "PROCESSING" && item.status !== "PENDING" && (
                        <GridTable.Data columnStart={getColumnStart()} alignX="flex-end">
                            <Box display="flex" alignX="flex-end" gap="3" fullWidth>
                                <Button
                                    className="toolbar-btn view-close"
                                    onClick={() => {
                                        showPaymentItemTicket(item);
                                    }}
                                    image="images/chevromRight.svg"
                                    bsStyle="link"
                                />
                            </Box>
                        </GridTable.Data>
                    )}
                    {isEditable && (
                        <GridTable.Data columnStart={isDesktop ? 7 : 4} alignX="flex-end">
                            <Box display="flex" alignX="flex-end" gap="3" fullWidth>
                                <Button
                                    className="toolbar-btn view-close"
                                    onClick={() => {
                                        selectItemToEdit(item.id);
                                    }}
                                    image="images/icons/editPen16.svg"
                                    bsStyle="link"
                                />
                                <Button
                                    className="toolbar-btn view-close"
                                    onClick={() => {
                                        deleteItem(item);
                                    }}
                                    image="images/icons/trashDelete.svg"
                                    bsStyle="link"
                                />
                            </Box>
                        </GridTable.Data>
                    )}
                </GridTable.Container>
            </>
        ));

        return listItems;
    };

    const renderTableItemDetails = () => (
        <>
            <GridTable>
                <GridTable.Header>
                    <GridTable.Container columnsTemplate={getColumnsTemplateHeader()} className="gapX-9">
                        <GridTable.Data columnStart={1} alignX="flex-start" size="7" inHeader>
                            <Text labelKey="forms.role.payment.payments.tableHeader.beneficiary" />
                        </GridTable.Data>
                        {isDesktop && (
                            <>
                                <GridTable.Data columnStart={2} alignX="center" size="7" inHeader>
                                    <Text labelKey="forms.role.payment.payments.tableHeader.productNumber" />
                                </GridTable.Data>
                                {!showExtraData && (
                                    <GridTable.Data columnStart={3} alignX="center" size="7" inHeader>
                                        <Text labelKey="forms.role.payment.payments.tableHeader.productType" />
                                    </GridTable.Data>
                                )}
                                <GridTable.Data columnStart={showExtraData ? 3 : 4} alignX="flex-end" size="7" inHeader>
                                    <Text labelKey="forms.role.payment.payments.tableHeader.amount" />
                                </GridTable.Data>
                                {showExtraData && (
                                    <GridTable.Data columnStart={4} alignX="flex-end" size="7" inHeader>
                                        <Text labelKey="forms.role.payment.payments.tableHeader.reference" />
                                    </GridTable.Data>
                                )}
                                {showExtraData && (
                                    <GridTable.Data columnStart={5} alignX="flex-end" size="7" inHeader>
                                        <Text labelKey="forms.role.payment.payments.tableHeader.status" />
                                    </GridTable.Data>
                                )}
                            </>
                        )}

                        {!isDesktop && (
                            <GridTable.Data columnStart={2} columnWidth={1} size="7" alignX="center" inHeader>
                                <Text labelKey="transactions.list.header.amountStatus" />
                            </GridTable.Data>
                        )}
                    </GridTable.Container>
                </GridTable.Header>

                <GridTable.Body>
                    {currentItems?.length > 0 ? (
                        renderItemTable()
                    ) : (
                        <Box display="flex" fullWidth alignX="center" className="no-more-data min-height-8rem">
                            <Text
                                labelKey={
                                    paymentList?.length > 0
                                        ? "forms.role.payment.no.payments.filtered"
                                        : "forms.role.payment.no.payments.loaded"
                                }
                                component="p"
                                align="center"
                                size="5"
                                color="text"
                            />
                        </Box>
                    )}
                </GridTable.Body>
                {visibleItemsCount < filteredData.length ? (
                    <Box className="text-center no-more-data flex">
                        <Button
                            bsStyle="link"
                            onClick={() => {
                                handleLoadMore();
                            }}
                            image="images/arrow-down.svg"
                            imageRight
                            label="massive.payments.line.more.results"
                            className="btn-small"
                        />
                    </Box>
                ) : (
                    <Box {...(currentItems?.length > 0 && { className: "pb-9" })} />
                )}
            </GridTable>
        </>
    );

    const documents = [
        {
            label: "accounts.xlsFile",
            onClick: (callback) => {
                callback(XLS_CONTENT_TYPE, null);
            },
        },
    ];

    return (
        <TransactionFilterProvider>
            <Notification scopeToShow="rolePaymentDetail" />

            <Head
                title={
                    match?.url?.includes("salaryPayment")
                        ? "role.payment.details.title"
                        : "suppliers.payment.details.title"
                }
                onBack={handleBack}
                rightChildren={
                    isEditable && (
                        <Button
                            bsStyle="primary"
                            className="btn-width-lg"
                            label="forms.role.payment.payments.add.button.label"
                            image="images/icons/plus-circle.svg"
                            onClick={showAddItemModal}
                        />
                    )
                }
            />

            <MainContainer showLoader={fetching}>
                <Box component="section" display="flex" column gap="5">
                    <Box
                        display="flex"
                        column
                        background="component-background"
                        className="pt-9 min-height-10rem"
                        borderRadius="default">
                        {isTicket && showExtraData && (
                            <Box
                                display="flex"
                                alignY="flex-end"
                                alignX="between"
                                gap="7"
                                className="pb-8 px-5"
                                fullWidth>
                                <Row alignY="flex-end" gapX="4">
                                    <Col xs={5}>
                                        <Box className="form-group">
                                            <Box className="input-group">
                                                <Box
                                                    className="pl-5"
                                                    display="flex"
                                                    alignX="center"
                                                    alignY="center"
                                                    position="absolute"
                                                    fullHeight>
                                                    <Show className="svg-image" />
                                                </Box>
                                                <input
                                                    className="form-control pl-9 position-relative"
                                                    type="text"
                                                    name="input"
                                                    onChange={(event) => {
                                                        const { value } = event?.target || {};
                                                        setInputSearchTerm(value);
                                                    }}
                                                    placeholder={i18nUtils.get("global.search")}
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col xs={4}>
                                        <Button
                                            className="mt-auto btn-primary"
                                            bsStyle="only-icon"
                                            loading={false}
                                            onClick={handleSearch}
                                            image="images/search.svg"
                                            disabled={false}
                                        />
                                    </Col>
                                </Row>

                                {documents && renderDownloadDocs(false, documents)}
                            </Box>
                        )}

                        {renderTableItemDetails()}
                    </Box>
                    <Box
                        {...(isDesktop && { display: "flex" })}
                        alignX="between"
                        background="heading-color"
                        className="px-7 py-6"
                        borderRadius="default">
                        <Box display="flex" alignX="start">
                            <Text component="label" size="5" color="text-inverse-color" bold addColon>
                                {i18nUtils.get("forms.role.payment.payments.quantity.label")}
                            </Text>
                            <Text size="5" color="text-inverse-color" bold>
                                {paymentList?.length || 0}
                            </Text>
                        </Box>
                        <Box display="flex" alignX="start" gap="3">
                            <Text component="label" size="5" color="text-inverse-color" bold uppercase>
                                {i18nUtils.get("forms.role.payment.payments.total.amount.label")}
                            </Text>
                            <FormattedAmount
                                currency={amountTotal?.currency || "USD"}
                                quantity={amountTotal?.quantity || 0}
                                size="5"
                                color="text-inverse-color"
                                bold
                            />
                        </Box>
                    </Box>
                    <Box display="flex" alignX="center" fullWidth>
                        <Row>
                            <Col xs={isEditable ? 3 : 4} xsOffset={isEditable ? 3 : 4}>
                                <Button
                                    bsStyle="outline"
                                    label="forms.role.payment.payments.cancel.button.label"
                                    onClick={handleBack}
                                    block
                                />
                            </Col>
                            {isEditable && (
                                <Col xs={3}>
                                    <Button
                                        bsStyle="primary"
                                        label="forms.role.payment.payments.save.button.label"
                                        onClick={saveAndContinue}
                                        block
                                    />
                                </Col>
                            )}
                        </Row>
                    </Box>
                </Box>
            </MainContainer>
            {showModal && (
                <ModalManualRole
                    onClose={closeAddItemModal}
                    addItem={addItem}
                    editItem={editItem}
                    isVisible={showModal}
                    itemToEdit={itemToEdit}
                    optionBankList={preDataForm?.bankList}
                    genericProps={genericProps}
                    currency={currency}
                />
            )}
        </TransactionFilterProvider>
    );
};

RolePaymentDetail.propTypes = {
    // value: shape({}),
    dispatch: func.isRequired,
    // setValue: func,
    isEditable: bool.isRequired,
    // isTicket: bool,
    currency: string,
    // onChangeManual: func,
    mode: string.isRequired,
    isFetchingDownload: bool,
    location: shape({}),
    // formValues: shape({}),
    match: shape({}).isRequired,
    preDataForm: shape({}).isRequired,
    manualPaymentList: shape([]).isRequired,
    fetching: bool,
    showExtraData: bool.isRequired,
    setLocationCustomPath: string,
    formValues: shape({}).isRequired,
    isDesktop: bool,
};

RolePaymentDetail.defaultProps = {
    // value: null,
    // setValue: null,
    currency: null,
    // onChangeManual: null,
    isFetchingDownload: false,
    location: null,
    // isTicket: false,
    // formValues: null,
    fetching: false,
    setLocationCustomPath: "",
    isDesktop: false,
};

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    environments: sessionSelectors.getEnvironments(state),
    user: sessionSelectors.getUser(state),
    activeRegion: loginSelectors.getRegion(state),
    isShowUpdateUserDataStepZero: updateUSerDataSelectors.isShowUpdateUserDataStepZero(state),
    mode: "edit",
    isFetchingDownload: false,
    preDataForm: formSelectors.getPreData(state),
    manualPaymentList: formSelectors.getRolePaymentList(state),
    isEditable: formSelectors.isRolePaymentDetailEditable(state),
    fetching: formSelectors.isFetchingTransactionLines(state),
    showExtraData: formSelectors.isRolePaymentDetailShowExtraData(state),
    formValues: formSelectors.getValues(state),
});

export default connect(mapStateToProps)(withRouter(withRevelockPositionContext(RolePaymentDetail)));
