import { toPng } from "html-to-image";

// eslint-disable-next-line import/prefer-default-export
export const getBase64FromComponent = (ref, quality, exclusions) =>
    new Promise((resolve, reject) => {
        if (!ref?.current) {
            reject();
            return;
        }

        toPng(ref.current, {
            quality: quality ?? 1.0,
            filter: (node) => !exclusions.some((className) => node.classList?.contains(className)),
        })
            .then((dataUrl) => {
                resolve(dataUrl);
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.error("Error capturing screenshot:", err);
                reject();
            });
    });
