import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    GET_PAYMENT_ITEM_DETAILS: "rolePaymentItem/GET_PAYMENT_ITEM_DETAILS",
    GET_PAYMENT_ITEM_SUCCESS: "rolePaymentItem/GET_PAYMENT_ITEM_SUCCESS",
    GET_PAYMENT_ITEM_FAILURE: "rolePaymentItem/GET_PAYMENT_ITEM_FAILURE",
};

export const INITIAL_STATE = {
    fetching: false,
    requestData: {},
    ticketData: {},
    mode: "view",
    transaction: {},
};

export default createReducer(INITIAL_STATE, {
    [types.GET_PAYMENT_ITEM_DETAILS]: (state, action) => ({
        ...state,
        fetching: true,
        requestData: action.requestData,
    }),
    [types.GET_PAYMENT_ITEM_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        ticketData: action.ticketData,
        transaction: action.ticketData.transaction,
    }),
    [types.GET_PAYMENT_ITEM_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
});

export const actions = {
    getPaymentItemDetailsRequest: makeActionCreator(types.GET_PAYMENT_ITEM_DETAILS, "requestData"),
    getPaymentItemDetailsSuccess: makeActionCreator(types.GET_PAYMENT_ITEM_SUCCESS, "ticketData"),
    getPaymentItemDetailsFailure: makeActionCreator(types.GET_PAYMENT_ITEM_FAILURE),
};

export const selectors = {
    isFetching: ({ rolePaymentItem }) => rolePaymentItem.fetching,
    getTicketData: ({ rolePaymentItem }) => rolePaymentItem.ticketData,
    getMode: ({ rolePaymentItem }) => rolePaymentItem.mode,
    getTransaction: ({ rolePaymentItem }) => rolePaymentItem.transaction,
};
