import classNames from "classnames";
import SelectSimpleField from "pages/forms/customForms/_customFields/SelectSimpleField";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import BeneficiarySelector from "pages/frequentDestination/components/BeneficiarySelector";
import {
    FOREIGN_TRANSFER,
    INTERNAL_TRANSFER,
    LOCAL_TRANSFER,
    PAY_2X3,
    PRODUCT_TYPE_TC,
    PRODUCT_TYPE_TP,
} from "pages/frequentDestination/validation/BeneficiaryFormValidation";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import SwitchField from "pages/_components/fields/SwitchField";
import SideBarModal from "pages/_components/modal/SideBarModal";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { selectors as bankSelectors } from "reducers/bankSelector";
import { selectors as formSelectors } from "reducers/form";
import { selectors as freqDestSelectors } from "reducers/frequentDestination/frequentDestination";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";
import { formatCreditCardNumber } from "util/creditCards";
import * as i18n from "util/i18n";
import { getTransactionKind } from "util/transaction";
import BeneficiaryForeign from "./BeneficiaryForeign";
import { BENEFICIARY_THREE_NAME, IBAN_NUMBER } from "./Constants";

const BANESCO_BANK_CODE = "BANSPAPA";

const BeneficiaryField = (props) => {
    const {
        isLocalForm,
        isForeignForm,
        isDesktop,
        bankList,
        productTypeList,
        form,
        field,
        onChange,
        mode,
        codeBankList,
        relationshipTypeList,
        countryList,
        genericProps,
        idActivity,
        showHeaderComponent,
        showSaveBeneficiary,
        disabledBankSelector,
        fromNewBeneficiary,
        directoryStyle,
        location,
        value,
        beneficiarySelected,
        beneficiaryManagePermission,
        trasfersOtherBanksPermission,
        transfersBanescoPermission,
        validateBankPermissions,
        isPay2x3Form,
    } = props;

    let fieldValue = field?.value;
    const [showBeneficiarySelector, setShowBeneficiarySelector] = useState(false);
    const [beneficiaryType, setBeneficiaryType] = useState(undefined);
    const isCreditCardProducDefault =
        getTransactionKind(idActivity) === "cardPayment" || getTransactionKind(idActivity) === "recharge";
    const isRechargePrepaid = getTransactionKind(idActivity) === "recharge";
    const modifyingDraftTransaction = location.pathname.match("/transaction") !== null;
    const loadBeneficiarySelected = location.pathname.match("/formCustom") !== null;

    const isCreditCardRechargeForm = getTransactionKind(idActivity) === "recharge";

    const isSingleResultBank = bankList !== undefined && bankList.length === 1;

    const setFieldValue = (name, newValue) => {
        fieldValue = newValue;
        form.setFieldValue(name, fieldValue);
    };

    const setDefaultData = () => {
        if (isCreditCardProducDefault && mode === "edit") {
            const productType = { id: "TC", label: i18n.get("forms.transfers.local.productType.other.TC") };
            let bank;
            if (isCreditCardRechargeForm && mode === "edit") {
                bank = { id: "BANSPAPA", label: "BANESCO" };
            }
            setFieldValue("beneficiary", { productType, bank, number: "" });
        }
    };

    const clearAllErrors = () => {
        form.setErrors({});
    };

    const foreignClearCountrySelected = () => {
        if (!setFieldValue) {
            return;
        }

        const updatedBeneficiary = { ...(form?.values?.beneficiary || {}) };

        setFieldValue("beneficiary", updatedBeneficiary);
    };

    const clearBeneficiary = () => {
        form.setFieldValue("beneficiary.load", false);
        setFieldValue("beneficiary", {
            bankSelector: codeBankList[0],
            intermediary_bankSelector: codeBankList[0],
            beneficiaryOption: BENEFICIARY_THREE_NAME,
            birthDateFrom: new Date(),
            accountType: IBAN_NUMBER,
            saveBeneficiary: false,
            supportFiles: fieldValue?.supportFiles,
        });
        form.setFieldValue("addIntermediaryBank", false);
        setDefaultData();
        clearAllErrors();
    };

    const onSelectBeneficiary = (beneficiary, extraData = null) => {
        setShowBeneficiarySelector(false);
        clearBeneficiary();
        if ((!beneficiary && !extraData) || JSON.stringify(extraData) === "{}") {
            return;
        }
        let extraDataMap;
        if (extraData) {
            extraDataMap = extraData;
        } else {
            extraDataMap = beneficiary.extraDataMap;
        }

        Object.keys(extraDataMap).forEach((key) => {
            form.setFieldValue(`beneficiary.${key}`, extraDataMap[key]);
        });

        const { addIntermediaryBank } = extraDataMap;
        form.setFieldValue("addIntermediaryBank", !!addIntermediaryBank);
        if (onChange) {
            onChange(beneficiary);
        }
        form.setFieldValue("beneficiary.saveBeneficiary", false);
        form.setFieldValue("beneficiary.load", true);
        clearAllErrors();
    };

    const handleChangeRadioButton = (option, name) => {
        const val = fieldValue || value || {};
        const newValue = { ...val, [name]: option };
        setFieldValue(field.name, newValue);

        if (onChange) {
            onChange(newValue);
        }
    };

    useEffect(() => {
        if (
            (!modifyingDraftTransaction && (fieldValue === null || fieldValue === undefined || isSingleResultBank)) ||
            isRechargePrepaid
        ) {
            setDefaultData();
        } else {
            const initialValue = fieldValue || props?.value || {};
            setFieldValue(field.name, initialValue);
        }
        handleChangeRadioButton(value?.beneficiaryOption || "BENEFICIARY_THREE_NAME", "beneficiaryOption");
        // beneficiario pre seleccionado luego de agregarlo
        if (beneficiarySelected !== null && loadBeneficiarySelected) {
            onSelectBeneficiary(beneficiarySelected, beneficiarySelected);
        }
        return () => {
            clearAllErrors();
        };
    }, []);

    const deleteError = (name) => {
        const errors = { ...props.errors };
        delete errors[`${field.name}.${name}`];
        form.setErrors(errors);
    };

    const handleChangeSelector = (target, name) => {
        const val = fieldValue || {};
        let newValue = { ...val, [name]: target };

        if (name === "productType") {
            newValue = { ...newValue, number: "" };
        }

        if (name === "bank" && !isCreditCardProducDefault) {
            newValue = { ...newValue, productType: null, number: "" };
        }
        setFieldValue(field.name, newValue);

        deleteError(name);

        if (onChange) {
            onChange(newValue);
        }
    };

    const patternValid = (pattern, val) => {
        if (pattern) {
            try {
                const regex = new RegExp(pattern);
                return regex.test(val);
            } catch (e) {
                return true;
            }
        }
        return true;
    };

    const handleChangeText = (e, name) => {
        if (patternValid(e.target.pattern, e.target.value)) {
            const val = fieldValue || {};
            const newValue = { ...val, [name]: e.target?.value || "" };
            setFieldValue(field.name, newValue);

            deleteError(name);

            if (onChange) {
                onChange(newValue);
            }
        }
    };

    const handleChangeTextProduct = (e, name) => {
        if (patternValid(e.target.pattern, e.target.value)) {
            const val = fieldValue || {};
            const productType = val.productType?.id;

            const targetValue = e.target?.value?.replace(/\s/g, "") || "";
            if (productType === "TC" || productType === "TP") {
                e.persist();
                let selectionStart = e.target?.selectionStart;
                const targetValueLength = targetValue.length;
                if (
                    selectionStart === e.target?.value?.length &&
                    ((targetValue.startsWith("3") && (targetValueLength === 5 || targetValueLength === 11)) ||
                        (!targetValue.startsWith("3") &&
                            (targetValueLength === 5 || targetValueLength === 9 || targetValueLength === 13)))
                ) {
                    selectionStart += 1;
                }

                setTimeout(() => {
                    e.target.focus();
                    e.target.setSelectionRange(selectionStart, selectionStart);
                }, 0);
            }

            const newValue = { ...val, [name]: targetValue };
            setFieldValue(field.name, newValue);

            deleteError(name);

            if (onChange) {
                onChange(newValue);
            }
        }
    };

    const handleChangeSwitch = (e) => {
        const val = fieldValue || {};
        const newValue = { ...val, saveBeneficiary: e.target?.value || "" };
        setFieldValue(field.name, newValue);

        if (onChange) {
            onChange(newValue);
        }
    };

    const handleChangeCheckBox = (checkedIn, name) => {
        const val = fieldValue || {};
        const newValue = { ...val, [name]: checkedIn };
        setFieldValue(field.name, newValue);

        if (onChange) {
            onChange(newValue);
        }
    };

    const handleChangeDate = (option, name) => {
        const val = fieldValue || {};
        const newValue = { ...val, [name]: option };
        setFieldValue(field.name, newValue);

        if (onChange) {
            onChange(newValue);
        }
    };
    const getNumberMaxLength = () => {
        const val = fieldValue || {};
        const { number } = val;
        const productType = val.productType?.id;
        const bankId = val.bank?.id;

        if (productType === "TC" || productType === "TP") {
            // si empieza con 3, largo 15, se aumentan el valor de los espacios en blanco (17)
            // si empieza con 4 o 5, largo 16, se aumentan el valor de los espacios en blanco (19)
            if (number) {
                switch (number.charAt(0)) {
                    case "3":
                        return 17;
                    default:
                        return 19;
                }
            }
        }

        if ((productType === "CA" || productType === "PR") && bankId === BANESCO_BANK_CODE) {
            // si es cuenta banesco, largo 12
            return 12;
        }

        // si es cuenta otro banco, largo 20
        return 20;
    };

    const getNumberPattern = () => {
        const val = fieldValue || {};
        const productType = val.productType?.id;

        if (productType !== "TC" && productType !== "TP") {
            return "^[0-9]*$";
        }

        return "(^$|^[345][0-9\\s]*$)";
    };

    const fieldHasError = (subfield) => {
        const { errors } = props;
        return errors[`${field.name}.${subfield}`];
    };

    const renderError = (subfield) => {
        const { errors } = props;

        if (fieldHasError(subfield)) {
            return <FieldError error={errors[`${field.name}.${subfield}`]} />;
        }
        return null;
    };

    const renderNumber = (v) => {
        if ((v?.productType?.id === "TC" || v?.productType?.id === "TP") && v?.number) {
            return formatCreditCardNumber(v?.number);
        }
        return value?.number;
    };

    const isLoadBeneficiary = () => fieldValue?.load || value?.load || false;

    const renderLocalForm = () => {
        const content = () => (
            <>
                {showHeaderComponent && beneficiaryManagePermission && (
                    <Box display="flex" alignX="flex-end" fullWidth className="mb-1">
                        <Button
                            label="frequentDestination.searchFrequentDestination"
                            onClick={() => {
                                setShowBeneficiarySelector(true);
                                const types = [];
                                if (isLocalForm) {
                                    if (trasfersOtherBanksPermission || !validateBankPermissions) {
                                        types.push(LOCAL_TRANSFER);
                                    }
                                    if (transfersBanescoPermission || !validateBankPermissions) {
                                        types.push(INTERNAL_TRANSFER);
                                    }
                                }
                                if (isPay2x3Form) {
                                    types.push(PAY_2X3);
                                }
                                setBeneficiaryType(types);
                            }}
                            image="images/icons/directory.svg"
                            bsStyle="link"
                        />
                    </Box>
                )}

                <Row>
                    <Col xs={12} {...(directoryStyle && { md: 6 })}>
                        <SelectSimpleField
                            form={form}
                            field={field}
                            labelKey="forms.beneficiary.bankSelector.label"
                            name="bank"
                            placeholder={i18n.get("forms.placeholder.select")}
                            valueKey="id"
                            value={fieldValue?.bank?.id || ""}
                            onChange={handleChangeSelector}
                            options={bankList}
                            disabled={disabledBankSelector || isLoadBeneficiary() || isSingleResultBank}
                        />
                    </Col>
                    <Col xs={12} md={directoryStyle ? 3 : 6}>
                        <SelectSimpleField
                            form={form}
                            field={field}
                            labelKey="forms.beneficiary.productType.label"
                            name="productType"
                            placeholder={i18n.get("forms.placeholder.select")}
                            valueKey="id"
                            value={fieldValue?.productType?.id || ""}
                            onChange={handleChangeSelector}
                            options={productTypeList}
                            disabled={isCreditCardProducDefault || isLoadBeneficiary()}
                        />
                    </Col>
                    <Col xs={12} md={directoryStyle ? 3 : 6}>
                        <TextSimpleField
                            form={form}
                            field={field}
                            labelKey="forms.beneficiary.number.label"
                            name="number"
                            placeholder=""
                            value={fieldValue?.number || ""}
                            onChange={handleChangeTextProduct}
                            maxLength={getNumberMaxLength()}
                            pattern={getNumberPattern()}
                            disabled={isLoadBeneficiary()}
                            type={isDesktop ? "text" : "tel"}
                            {...((fieldValue?.productType?.id === "TC" || fieldValue?.productType?.id === "TP") && {
                                useMaskedInput: true,
                            })}
                            showPatternError
                            labelPatternError={i18n.get("forms.fields.pattern.invalid.label")}
                        />
                    </Col>
                    <Col xs={12} {...(directoryStyle && { md: 6 })}>
                        <TextSimpleField
                            form={form}
                            field={field}
                            labelKey="forms.beneficiary.name.label"
                            name="name"
                            placeholder=""
                            value={fieldValue?.name || ""}
                            maxLength="35"
                            onChange={handleChangeText}
                            pattern="^[a-zA-Z0-9 ]*$"
                            disabled={isLoadBeneficiary()}
                        />
                    </Col>
                    <Col xs={12} {...(directoryStyle && { md: 6 })}>
                        <TextSimpleField
                            form={form}
                            field={field}
                            isRequired={false}
                            labelKey="forms.beneficiary.alias.label"
                            name="alias"
                            placeholder=""
                            value={fieldValue?.alias || ""}
                            onChange={handleChangeText}
                            pattern="^[a-zA-Z ]*$"
                            disabled={isLoadBeneficiary()}
                            maxLength="28"
                        />
                    </Col>
                    <Col xs={12} {...(directoryStyle && { md: 6 })}>
                        <TextSimpleField
                            form={form}
                            field={field}
                            isRequired={false}
                            labelKey="forms.beneficiary.email.label"
                            name="email"
                            placeholder=""
                            value={fieldValue?.email || ""}
                            onChange={handleChangeText}
                            pattern="^[a-zA-Z0-9ñ@+._-]*$"
                            disabled={isLoadBeneficiary()}
                        />
                    </Col>
                </Row>

                <Box display="flex" fullWidth>
                    <Box display="flex" alignX="flex-end" className="mt-9 ml-md-auto">
                        {showSaveBeneficiary && !isLoadBeneficiary() && beneficiaryManagePermission && (
                            <SwitchField
                                {...field}
                                {...form}
                                name="saveBeneficiary"
                                idForm="forms.beneficiary"
                                value={fieldValue?.saveBeneficiary}
                                onChange={handleChangeSwitch}
                                error={false}
                            />
                        )}
                        {isLoadBeneficiary() && (
                            <Button
                                label="frequentDestination.clearBeneficiary"
                                onClick={clearBeneficiary}
                                image="images/icons/circleCross.svg"
                                bsStyle="link"
                            />
                        )}
                    </Box>
                </Box>
            </>
        );
        if (mode === "edit") {
            return (
                <Box
                    background="white"
                    className={classNames(` ${isPay2x3Form ? "pb-0" : "pb-9"} mx-n-5`, {
                        "pt-8 pl-5 pl-lg-10 pr-5 pr-lg-10": !directoryStyle,
                        "pt-9 px-5 px-md-0": directoryStyle,
                    })}
                    borderRadius="default"
                    fullHeight>
                    {directoryStyle ? (
                        <Row>
                            <Col md={10} mdOffset={1}>
                                {content()}
                            </Col>
                        </Row>
                    ) : (
                        content()
                    )}
                </Box>
            );
        }

        const isBanescoId = (fieldValue?.bank?.id || value?.bank?.id) === BANESCO_BANK_CODE;
        const productTypeId = fieldValue?.productType?.id || value?.productType?.id;
        const productTypeKey = isBanescoId ? "banesco" : "other";

        return (
            <>
                <Box className="data-wrapper">
                    <FieldLabel
                        idField={`${field.name}_bank`}
                        labelKey="forms.beneficiary.bankSelector.label"
                        mode="view"
                    />
                    <Text>{fieldValue?.bank?.label || value?.bank?.label}</Text>
                </Box>
                <Box className="data-wrapper">
                    <FieldLabel
                        idField={`${field.name}_productType`}
                        labelKey="forms.beneficiary.productType.label"
                        mode="view"
                    />
                    <Text>{i18n.get(`forms.transfers.local.productType.${productTypeKey}.${productTypeId}`)}</Text>
                </Box>
                <Box className="data-wrapper">
                    <FieldLabel
                        idField={`${field.name}_number`}
                        labelKey="forms.beneficiary.number.label"
                        mode="view"
                    />
                    <Text>{renderNumber(fieldValue) || renderNumber(value)}</Text>
                </Box>
                <Box className="data-wrapper">
                    <FieldLabel idField={`${field.name}_name`} labelKey="forms.beneficiary.name.label" mode="view" />
                    <Text>{fieldValue?.name || value?.name}</Text>
                </Box>

                {(fieldValue?.alias || value?.alias) && (
                    <Box className="data-wrapper">
                        <FieldLabel
                            idField={`${field.name}_alias`}
                            labelKey="forms.beneficiary.alias.label"
                            mode="view"
                        />
                        <Text>{fieldValue?.alias || value?.alias}</Text>
                    </Box>
                )}

                {(fieldValue?.email || value?.email) && (
                    <Box className="data-wrapper">
                        <FieldLabel
                            idField={`${field.name}_email`}
                            labelKey="forms.beneficiary.email.label"
                            mode="view"
                        />
                        <Text>{fieldValue?.email || value?.email}</Text>
                    </Box>
                )}
            </>
        );
    };

    return (
        <>
            {/* ****** LocalForm ******** */}
            {isLocalForm || isPay2x3Form ? renderLocalForm() : null}
            {/* ****** LocalForm ******** */}
            {/* ****** ForeignForm ******** */}
            {isForeignForm && (
                <BeneficiaryForeign
                    isDesktop={isDesktop}
                    field={field}
                    form={form}
                    fieldHasError={fieldHasError}
                    renderError={renderError}
                    productTypeList={productTypeList}
                    countryList={countryList}
                    relationshipTypeList={relationshipTypeList}
                    codeBankList={codeBankList}
                    mode={mode}
                    genericProps={genericProps}
                    handleChangeText={handleChangeText}
                    handleChangeCheckBox={handleChangeCheckBox}
                    handleChangeSelector={handleChangeSelector}
                    handleChangeRadioButton={handleChangeRadioButton}
                    handleChangeDate={handleChangeDate}
                    showHeaderComponent={showHeaderComponent}
                    showSaveBeneficiary={showSaveBeneficiary}
                    fromNewBeneficiary={fromNewBeneficiary}
                    directoryStyle={directoryStyle}
                    onClickSelectBeneficiary={() => {
                        setShowBeneficiarySelector(true);
                        setBeneficiaryType([FOREIGN_TRANSFER]);
                    }}
                    value={value}
                    clearBeneficiary={clearBeneficiary}
                    loadBeneficiary={isLoadBeneficiary()}
                    handleChangeSwitch={handleChangeSwitch}
                    clearCountrySelected={foreignClearCountrySelected}
                />
            )}

            {/* ****** ForeignForm ******** */}
            {showHeaderComponent && beneficiaryType && (
                <SideBarModal
                    show={showBeneficiarySelector}
                    onClose={() => {
                        setShowBeneficiarySelector(false);
                    }}
                    title="beneficiary.selector.title">
                    <BeneficiarySelector
                        {...props}
                        isDesktop={isDesktop}
                        onSelect={(beneficiary) => onSelectBeneficiary(beneficiary)}
                        beneficiaryType={beneficiaryType}
                        {...(isCreditCardProducDefault &&
                            !isCreditCardRechargeForm && { productType: PRODUCT_TYPE_TC })}
                        {...(isCreditCardRechargeForm && { productType: PRODUCT_TYPE_TP })}
                    />
                </SideBarModal>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    frequentDestionationOnRow: freqDestSelectors.getFrequentDestionationOnRow(state),
    selectedBank: bankSelectors.getSelectedBank(state, "foreignBank"),
    beneficiarySelected: freqDestSelectors.getBeneficiarySelected(state),
    beneficiaryManagePermission: sessionSelectors.hasPermissions(state, ["beneficiaryManage"]),
    trasfersOtherBanksPermission: sessionSelectors.hasPermissions(state, ["transferLocal"]),
    transfersBanescoPermission: sessionSelectors.hasPermissions(state, ["transferThirdParties"]),
});

BeneficiaryField.propTypes = {
    isLocalForm: bool,
    isForeignForm: bool,
    setValues: shape({}),
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    frequentDestionationOnRow: shape({}),
    isDesktop: bool.isRequired,
    fromTransaction: bool,
    selectedBank: shape({ type: string, code: string }),
    beneficiaryManagePermission: false,
    bankList: arrayOf(shape({})).isRequired,
    productTypeList: arrayOf(shape({})).isRequired,
    form: shape({}).isRequired,
    field: shape({}).isRequired,

    genericProps: shape({}).isRequired,
    onChange: func,
    value: shape({}),
    codeBankList: arrayOf(shape({})),
    relationshipTypeList: arrayOf(shape({})),
    countryList: arrayOf(shape({})),
    idActivity: string,
    showHeaderComponent: bool,
    showSaveBeneficiary: bool,
    disabledBankSelector: bool,
    fromNewBeneficiary: bool,
    directoryStyle: bool,
    errors: shape({}),
    beneficiarySelected: shape({}).isRequired,
    trasfersOtherBanksPermission: bool.isRequired,
    transfersBanescoPermission: bool.isRequired,
    validateBankPermissions: bool,
    isPay2x3Form: bool,
};

BeneficiaryField.defaultProps = {
    isLocalForm: false,
    isForeignForm: false,
    setValues: {},
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    transaction: {},
    location: {},
    fromTransaction: false,
    frequentDestionationOnRow: {},
    selectedBank: { type: "", code: "" },
    beneficiaryManagePermission: false,
    onChange: null,
    value: {},
    codeBankList: [],
    relationshipTypeList: [],
    countryList: [],
    idActivity: "",
    showHeaderComponent: true,
    showSaveBeneficiary: true,
    disabledBankSelector: false,
    fromNewBeneficiary: false,
    directoryStyle: false,
    errors: {},
    validateBankPermissions: false,
    isPay2x3Form: false,
};
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(BeneficiaryField));
