import { CORPORATE_GROUP_ENVIRONMENT_TYPE, CORPORATE_ENVIRONMENT_TYPE } from "constants.js";
import React, { Component } from "react";
import { bool, func, shape, string } from "prop-types";

import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import classNames from "classnames";
import { replace } from "react-router-redux";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import List from "pages/transactions/_components/List";
import HiddenFilters from "pages/transactions/_components/HiddenFilters";
import ProductFilters from "pages/_components/ProductFilters";
import MainContainer from "pages/_components/MainContainer";
import TransactionFilterProvider, {
    EnumTransactionFilterType,
    PDF_CONTENT_TYPE,
    XLS_CONTENT_TYPE,
} from "providers/TransactionFilterProvider";
import withTransactionFilterContext from "hoc/withTransactionFilterContext";
import ButtonChipContext from "./_components/ButtonChipContext";

const LAST_TRANSACTIONS = "last";

export const EnumTransactionStatus = {
    PENDING: "PENDING",
    PROCESSING: "PROCESSING",
};

class PendingTransactionList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        isFirstFetching: bool.isRequired,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
        isMultipleSignaturesCancelled: bool.isRequired,
    };

    state = {
        selectedFilter: LAST_TRANSACTIONS,
        defaultFilters: null,
        onlyPendings: true,
        onlyProcessing: true,
        ascending: false,
        filtersValue: {
            status: [EnumTransactionStatus.PENDING, EnumTransactionStatus.PROCESSING],
        },
        isChangeCompany: false,
    };

    toggleChangeCompany = () => {
        this.setState((prevState) => ({
            isChangeCompany: !prevState.isChangeCompany,
        }));
    };

    componentDidMount = () => {
        const { dispatch, isMultipleSignaturesCancelled } = this.props;
        if (isMultipleSignaturesCancelled) {
            this.setState({ selectedFilter: "pendingSignature" });
            this.changeStatusInFiltersValue([EnumTransactionStatus.PENDING]);
            dispatch(transactionsActions.setMultipleSignaturesCancelled(false));
        } else {
            const dateFrom = new Date();
            dateFrom.setYear(dateFrom.getFullYear() - 10);
            const dateTo = new Date();
            const defaultFilters = {
                dateFrom,
                dateTo,
                pageNumber: 1,
                filter: LAST_TRANSACTIONS,
                isCreatedByMe: false,
            };
            this.setState({ defaultFilters });
            dispatch(transactionsActions.loadListRequest(defaultFilters, true, true, true, true));
        }
    };

    handleFilterButtonsClick = (idFilter) => {
        const { onlyPendings, onlyProcessing } = this.state;
        const { dispatch } = this.props;
        let filters = { filter: idFilter, isCreatedByMe: false };
        let pendings = onlyPendings;
        let processing = onlyProcessing;

        switch (idFilter) {
            case LAST_TRANSACTIONS: {
                const dateFrom = new Date();
                dateFrom.setYear(dateFrom.getFullYear() - 1);
                const dateTo = new Date();
                pendings = true;
                processing = true;
                filters = {
                    dateFrom,
                    dateTo,
                    pageNumber: 1,
                    ...filters,
                };
                break;
            }
            case "bankProcess": {
                const dateFrom = new Date();
                dateFrom.setYear(dateFrom.getFullYear() - 1);
                const dateTo = new Date();
                pendings = false;
                processing = true;
                filters = {
                    dateFrom,
                    dateTo,
                    pageNumber: 1,
                    minAmount: 0,
                    ...filters,
                };
                break;
            }
            case "pendingSignature": {
                const dateFrom = new Date();
                dateFrom.setYear(dateFrom.getFullYear() - 1);
                const dateTo = new Date();
                pendings = true;
                processing = false;
                filters = {
                    dateFrom,
                    dateTo,
                    pageNumber: 1,
                    minAmount: 0,
                    ...filters,
                };
                break;
            }
            default:
                break;
        }
        this.setState({ selectedFilter: idFilter, onlyPendings: pendings, onlyProcessing: processing });
        dispatch(transactionsActions.loadListRequest(filters, pendings, processing, false));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        const pathname = "/desktop";
        dispatch(transactionsActions.setInitialState());
        dispatch(replace({ pathname, state: { transition: "transition-flow-close" } }));
    };

    handleOrder = () => {
        const { ascending } = this.state;
        this.setState({ ascending: !ascending });
    };

    resetHandleOrder = () => {
        this.setState({ ascending: false });
    };

    changeStatusInFiltersValue(statusArray) {
        const { filtersValue } = this.state;
        const newFilters = {
            ...filtersValue,
            status: statusArray,
        };
        this.setState({ filtersValue: newFilters });
    }

    render() {
        const { fetching, isDesktop, dispatch, isFirstFetching, activeEnvironment } = this.props;
        const {
            defaultFilters,
            selectedFilter,
            onlyPendings,
            onlyProcessing,
            ascending,
            filtersValue,
            isChangeCompany,
        } = this.state;
        const isCorporateGroup = activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE;
        const isPending = filtersValue.status.includes("PENDING") && !filtersValue.status.includes("PROCESSING");

        const filters = [
            <ButtonChipContext
                className={classNames("px-4", {
                    "is-active": selectedFilter === LAST_TRANSACTIONS,
                })}
                label="transactions.list.filter.all"
                onClick={(setStatus) => {
                    this.changeStatusInFiltersValue([EnumTransactionStatus.PENDING, EnumTransactionStatus.PROCESSING]);
                    if (setStatus) {
                        setStatus([EnumTransactionStatus.PENDING, EnumTransactionStatus.PROCESSING]);
                    }
                    this.handleFilterButtonsClick(LAST_TRANSACTIONS);
                }}
                image={selectedFilter === LAST_TRANSACTIONS ? "images/check.svg" : ""}
                key={LAST_TRANSACTIONS}
            />,
            <ButtonChipContext
                className={classNames("px-4", {
                    "is-active": selectedFilter === "bankProcess",
                })}
                label="transactions.list.filter.bankProcess"
                onClick={(setStatus) => {
                    this.changeStatusInFiltersValue([EnumTransactionStatus.PROCESSING]);
                    if (setStatus) {
                        setStatus([EnumTransactionStatus.PROCESSING]);
                    }
                    this.handleFilterButtonsClick("bankProcess");
                }}
                image={selectedFilter === "bankProcess" ? "images/check.svg" : ""}
                key="bankProcess"
            />,
            <ButtonChipContext
                className={classNames("px-4", {
                    "is-active": selectedFilter === "pendingSignature",
                })}
                label="transactions.list.filter.pendingSignature"
                onClick={(setStatus) => {
                    this.changeStatusInFiltersValue([EnumTransactionStatus.PENDING]);
                    if (setStatus) {
                        setStatus([EnumTransactionStatus.PENDING]);
                    }
                    this.handleFilterButtonsClick("pendingSignature");
                }}
                image={selectedFilter === "pendingSignature" ? "images/check.svg" : ""}
                key="pendingSignature"
            />,
        ];

        const documents = [
            {
                label: "accounts.pdfFile",
                onClick: (callback) => {
                    if (callback) {
                        callback(PDF_CONTENT_TYPE, filtersValue);
                    }
                },
            },
            {
                label: "accounts.xlsFile",
                onClick: (callback) => {
                    if (callback) {
                        callback(XLS_CONTENT_TYPE, filtersValue);
                    }
                },
            },
        ];

        const setFilterValues = (dateFrom, dateTo, channelType) => {
            let newFilters = {};
            if (channelType) {
                newFilters = {
                    ...filtersValue,
                    dateFrom: defaultFilters?.dateFrom,
                    dateTo: defaultFilters?.dateTo,
                    channelType,
                };
            } else if (dateFrom || dateTo) {
                newFilters = {
                    ...filtersValue,
                    dateFrom: dateFrom || (filtersValue?.dateFrom ? filtersValue?.dateFrom : defaultFilters?.dateFrom),
                    dateTo: dateTo || (filtersValue?.dateTo ? filtersValue?.dateTo : defaultFilters?.dateTo),
                    channelType: null,
                };
            }
            this.setState({ filtersValue: newFilters });
        };

        return (
            <TransactionFilterProvider
                transactionFilterType={EnumTransactionFilterType.PENDING_TRANSACTION}
                defaultStatusList={[EnumTransactionStatus.PENDING, EnumTransactionStatus.PROCESSING]}>
                <Notification scopeToShow="transactions" />
                <Head
                    title="pendingDispatch.list.title"
                    onBack={this.handleBack}
                    exportList={documents}
                    exportListBsStyle="only-icon"
                />

                <MainContainer showLoader={isFirstFetching || isChangeCompany} showChildrenWithLoader className="px-0">
                    <div className="above-the-fold">
                        <ProductFilters
                            isPendingTransactionList
                            activeEnvironment={activeEnvironment}
                            alwaysShowChips
                            closeMoreFiltersWhenSiblingClick
                            borderRadiusTop
                            notCollapsedFilters
                            documents={documents}
                            moreFilters={
                                <HiddenFilters
                                    dispatch={dispatch}
                                    isDesktop={isDesktop}
                                    fetching={fetching}
                                    onlyPendings={onlyPendings}
                                    onlyProcessing={onlyProcessing}
                                    isTransactionList={false}
                                    resetHandleOrder={this.resetHandleOrder}
                                    setFilterValues={setFilterValues}
                                />
                            }
                            moreFiltersClosedKeyLabel="global.productFilters.btnFilter.closed.label"
                            moreFiltersOpenedKeyLabel="global.productFilters.btnFilter.opened.label"
                            filterDownloadBtn
                            isDesktop={isDesktop}
                            showEnvironmentSelector={activeEnvironment.type === CORPORATE_ENVIRONMENT_TYPE}
                            onToggleChangeCompany={this.toggleChangeCompany}>
                            {filters}
                        </ProductFilters>
                        <List
                            isDesktop={isDesktop}
                            defaultFilters={defaultFilters}
                            showEnvironment={isCorporateGroup}
                            itemsAreClickeable={!isCorporateGroup}
                            onlyPendings={onlyPendings}
                            onlyProcessing={onlyProcessing}
                            handleOrder={this.handleOrder}
                            ascending={ascending}
                            isPending={isPending}
                        />
                    </div>
                </MainContainer>
            </TransactionFilterProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    isFirstFetching: transactionsSelectors.getFirstFetch(state),
    fetching: transactionsSelectors.getFetching(state),
    filters: transactionsSelectors.getFilters(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    isMultipleSignaturesCancelled: transactionsSelectors.isMultipleSignaturesCancelled(state),
});

export default connect(mapStateToProps)(withTransactionFilterContext(PendingTransactionList));
