import classNames from "classnames";
import Box from "pages/_components/Box";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import MaskedInput from "react-text-mask";
import { ReactComponent as Show } from "styles/images/search.svg";
import * as i18nUtils from "util/i18n";
import * as maskUtils from "util/mask";

const TextSimpleField = (props) => {
    const {
        className,
        disabled,
        error,
        field,
        form,
        isRequired,
        labelKey,
        maxLength,
        name,
        onChange,
        pattern,
        placeholder,
        serarchStyle,
        tooltipText,
        type,
        useMaskedInput,
        value,
        showPatternError,
        labelPatternError,
    } = props;

    const fieldHasError = (subfield) => {
        if (error) {
            return true;
        }
        if (Object.keys(form).length > 0) {
            const { errors } = form;
            return field.name === subfield ? errors[`${field.name}`] : errors[`${field.name}.${subfield}`];
        }
        return null;
    };

    const renderError = (subfield) => {
        if (error) {
            return <FieldError error={error} />;
        }
        if (Object.keys(form).length > 0) {
            const { errors } = form;

            if (fieldHasError(subfield)) {
                return (
                    <FieldError
                        error={field.name === subfield ? errors[`${field.name}`] : errors[`${field.name}.${subfield}`]}
                    />
                );
            }
        }
        return null;
    };

    const handleCopy = (event) => {
        const text = event.target.value;
        const textWithoutSpaces = text.replace(/\s/g, "");
        event.clipboardData.setData("text/plain", textWithoutSpaces);
        event.preventDefault();
    };

    const onChangeRef = (e) => {
        if (showPatternError !== undefined && showPatternError === true) {
            delete form?.errors[`${field.name}.${name}`];
            const regex = new RegExp(pattern);
            const isValid = regex.test(e.target.value);
            if (!isValid) {
                form.setErrors({ ...(form?.errors ?? {}), [`${field.name}.${name}`]: labelPatternError });
                return;
            }
        }

        onChange(e, name);
    };

    return (
        <>
            <Box
                className={classNames("form-group", className, {
                    "has-error": fieldHasError(name),
                })}>
                {labelKey ? (
                    <FieldLabel
                        optional={isRequired ? "" : i18nUtils.get("forms.optional")}
                        idField={name}
                        labelKey={labelKey}
                        tooltipText={tooltipText}
                        labelNoMarginTop
                    />
                ) : null}
                <Box className="input-group">
                    {serarchStyle && (
                        <Box
                            className="pl-5"
                            display="flex"
                            alignX="center"
                            alignY="center"
                            position="absolute"
                            fullHeight>
                            <Show className="svg-image" />
                        </Box>
                    )}
                    {useMaskedInput ? (
                        <MaskedInput
                            id={name}
                            className="form-control"
                            type={type}
                            name={Object.keys(field).length > 0 ? `${field.name}_${name}` : name}
                            placeholder={placeholder}
                            {...(onChange && { onChange: (e) => onChangeRef(e, name) })}
                            {...(field.length !== 0 && { value })}
                            disabled={disabled}
                            mask={maskUtils.creditCardFormat(value)}
                            guide={false}
                            onCopy={handleCopy}
                        />
                    ) : (
                        <input
                            id={name}
                            className={classNames("form-control", {
                                "pl-9 position-relative": serarchStyle,
                            })}
                            type={type}
                            name={Object.keys(field).length > 0 ? `${field.name}_${name}` : name}
                            placeholder={placeholder}
                            {...(onChange && { onChange: (e) => onChangeRef(e, name) })}
                            {...(field.length !== 0 && { value })}
                            {...(maxLength && { maxLength })}
                            pattern={pattern}
                            disabled={disabled}
                        />
                    )}
                </Box>
                {renderError(name)}
            </Box>
        </>
    );
};

TextSimpleField.propTypes = {
    className: bool,
    disabled: bool,
    error: string,
    field: shape({}),
    form: shape({}),
    isRequired: bool,
    labelKey: string,
    maxLength: string,
    name: string.isRequired,
    onChange: func.isRequired,
    pattern: string,
    placeholder: string,
    serarchStyle: bool,
    tooltipText: string,
    type: string,
    useMaskedInput: bool,
    value: string.isRequired,
    showPatternError: bool,
    labelPatternError: string,
};

TextSimpleField.defaultProps = {
    className: false,
    disabled: false,
    error: null,
    field: {},
    form: {},
    isRequired: true,
    labelKey: null,
    maxLength: null,
    pattern: null,
    placeholder: "",
    serarchStyle: false,
    tooltipText: null,
    type: "text",
    useMaskedInput: false,
    showPatternError: false,
    labelPatternError: "",
};

export default TextSimpleField;
