import { call, put, takeLatest } from "redux-saga/effects";

import { types, actions as rolePaymentItemActions } from "reducers/paymentItem";
import * as rolePaymentItemApi from "middleware/paymentItem";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { routerActions } from "react-router-redux";

const sagas = [takeLatest(types.GET_PAYMENT_ITEM_DETAILS, getPaymentItemDetails)];

function* getPaymentItemDetails({ requestData }) {
    const response = yield call(rolePaymentItemApi.getPaymentItemDetails, requestData);
    if (!response?.type || !response?.data?.data || response.type === "W") {
        yield put(rolePaymentItemActions.getPaymentItemDetailsFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["rolePaymentDetail"]),
        );
    } else {
        const { ticketData } = response.data.data;
        const { idTransaction, operationType } = ticketData;
        yield put(rolePaymentItemActions.getPaymentItemDetailsSuccess(ticketData));
        if (operationType) {
            if (operationType === "suppliers.payment.send") {
                yield put(routerActions.push(`/suppliersPaymentItem/${idTransaction}/processDetail`));
            } else {
                yield put(routerActions.push(`/salaryPaymentItem/${idTransaction}/processDetail`));
            }
        }
    }
}

export default sagas;
