import moment from "moment";
import { PDFTextField, PDFTextFieldFirst } from "pages/forms/customForms/PDFTicket";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import TicketRenderCustom from "../TicketRenderCustom";

const TicketPreApprovedCreditPos = (props) => {
    const { transaction, extraData, wally, highlightedLabel, highlightedValue } = props;

    const { activityName: title, idActivity, creationDateTime, data, idTransactionStatus } = transaction;
    const { reference, backendReference, preApprovedCreditUser } = data;

    const PDFTextFieldComponent = highlightedValue ? PDFTextField : PDFTextFieldFirst;

    const renderFields = () => (
        <>
            {highlightedValue && (
                <Box
                    display="flex"
                    alignY="center"
                    background={
                        // eslint-disable-next-line no-nested-ternary
                        idTransactionStatus === "FINISHED" ? (wally ? "wally" : "success-ticket") : "transparent"
                    }
                    column
                    fullWidth
                    className="pt-3 pb-5 mt-n-min">
                    <Text
                        color={idTransactionStatus === "FINISHED" ? "inverse" : "heading"}
                        className="mb-2"
                        labelKey={highlightedLabel}
                    />

                    <Text color={idTransactionStatus === "FINISHED" ? "inverse" : "heading"} size="big" bold>
                        {highlightedValue}
                    </Text>
                </Box>
            )}
            <Box className="pt-9 mx-7">
                {(reference || backendReference) && (
                    <FormFieldsComponents.ReadTextCustom
                        value={reference || backendReference}
                        label="ticketNoTransactional.voucher.label"
                    />
                )}
                <FormFieldsComponents.ReadTextCustom
                    value={moment(creationDateTime).format("DD/MM/YYYY")}
                    label="ticketNoTransactional.date.label"
                />
                <FormFieldsComponents.ReadTextCustom
                    value={i18n.get(`pre.approved.credit.pos.status.${preApprovedCreditUser.status}`)}
                    label="ticketPreApprovedCreditPos.status.label"
                />
                <FormFieldsComponents.ReadTextCustom
                    value={`USD ${preApprovedCreditUser?.amount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                    })}`}
                    label="ticketPreApprovedCreditPos.amount.label"
                />
                <FormFieldsComponents.ReadTextCustom
                    value={`USD ${preApprovedCreditUser?.monthlyInstallment.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                    })}`}
                    label="ticketPreApprovedCreditPos.monthlyInstallment.label"
                />

                <FormFieldsComponents.ReadTextCustom
                    value={`${preApprovedCreditUser?.durationYears} ${i18n.get("scheduler.months")}`}
                    label="ticketPreApprovedCreditPos.durationYears.label"
                />

                <FormFieldsComponents.ReadTextCustom
                    value={`${(100 * (preApprovedCreditUser?.annualRate ?? 0)).toFixed(2)}%`}
                    label="ticketPreApprovedCreditPos.anualRate.label"
                />
                <FormFieldsComponents.ReadTextCustom value={title} label="ticketNoTransactional.operationDate.label" />

                {extraData?.map((info) => (
                    <FormFieldsComponents.ReadTextCustom
                        labelCustom={info.labelCustom}
                        label={info.label}
                        value={info.value}
                    />
                ))}
            </Box>
        </>
    );
    const renderTicket = () => (
        <>
            {(reference || backendReference) && (
                <>
                    <PDFTextFieldComponent
                        value={reference || backendReference}
                        label={i18n.get("ticketNoTransactional.voucher.label")}
                    />
                    <PDFTextField
                        value={moment(creationDateTime).format("DD/MM/YYYY")}
                        label={i18n.get("ticketNoTransactional.date.label")}
                    />
                </>
            )}
            {(!reference || !backendReference) && (
                <PDFTextFieldComponent
                    value={moment(creationDateTime).format("DD/MM/YYYY")}
                    label={i18n.get("ticketNoTransactional.date.label")}
                />
            )}
            <PDFTextField
                value={i18n.get(`pre.approved.credit.pos.status.${preApprovedCreditUser.status}`)}
                label={i18n.get("ticketPreApprovedCreditPos.status.label")}
            />
            <PDFTextField
                value={`USD ${preApprovedCreditUser.amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}`}
                label={i18n.get("ticketPreApprovedCreditPos.amount.label")}
            />
            <PDFTextField
                value={`USD ${preApprovedCreditUser.monthlyInstallment.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                })}`}
                label={i18n.get("ticketPreApprovedCreditPos.monthlyInstallment.label")}
            />
            <PDFTextField
                value={`${preApprovedCreditUser?.durationYears} meses`}
                label={i18n.get("ticketPreApprovedCreditPos.durationYears.label")}
            />
            <PDFTextField
                value={`${(100 * (preApprovedCreditUser?.annualRate ?? 0)).toFixed(2)}%`}
                label={i18n.get("ticketPreApprovedCreditPos.anualRate.label")}
            />

            <PDFTextField value={title} label={i18n.get("ticketNoTransactional.operationDate.label")} />
        </>
    );

    const formProps = {
        title,
        renderFields,
        renderTicket,
        idActivity,
    };
    return <TicketRenderCustom {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
});

TicketPreApprovedCreditPos.propTypes = {
    dispatch: func.isRequired,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    fromTransaction: bool,
    extraData: shape({}),
    wally: bool,
    highlightedLabel: string,
    highlightedValue: string,
};
TicketPreApprovedCreditPos.defaultProps = {
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    transaction: {},
    location: {},
    fromTransaction: false,
    extraData: null,
    wally: false,
    highlightedLabel: null,
    highlightedValue: null,
};
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(TicketPreApprovedCreditPos));
