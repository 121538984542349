/* eslint-disable*/
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import ProductDetail from "pages/_components/ProductDetail";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TextField from "pages/_components/fields/TextField";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { routerActions } from "react-router-redux";
import { push } from "react-router-redux/actions";
import { actions as notificationActions } from "reducers/notification";
import { actions as kuaraActions, selectors as kuaraSelectors } from "reducers/kuara";
import { compose } from "redux";
import { filterData } from "util/array";
import { findContact, getContactDisplayName, requestPermission } from "util/contact.util";
import * as i18n from "util/i18n";
import KuaraListContacts from "./KuaraContactComponents/KuaraListContacts";
import { extractCountryAndNumber, extractCountryCodePanama, formatAccount } from "./hooks/KuaraHooks";
import WallyModal from "pages/_components/modal/WallyModal";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import kuaraVersionNumber from "util/kuaraVersionNumber";

const SendKuara = (props) => {
    const { isDesktop, dispatch, isActiveKuara, accountKuara, fetchingPeople } = props;
    const [listPeopleAll, setListPeopleAll] = useState([]);
    const [listPeople, setListPeople] = useState([]);
    const [showWallyDialog, setShowWallyDialog] = useState(false);

    useEffect(() => {
        if (isActiveKuara) {
            dispatch(kuaraActions.setFetchingPeople(true));
            requestPhoneNumbers();
        } else {
            dispatch(push("/kuara"));
        }
    }, []);

    const requestPhoneNumbers = async () => {
        const responseRequest = await requestPermission();
        if (responseRequest?.read === undefined || (responseRequest.read !== true && responseRequest !== "true")) {
            dispatch(
                notificationActions.showNotification(
                    i18n.get("kuara.forms.transfers.contact.permission.denied"),
                    "error",
                    ["desktop"],
                    true,
                    null,
                    () => (
                        <Box alignX="center" display="flex">
                            <Button
                                label="kuara.forms.transfers.contact.permission.open"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (!window?.cordova?.plugins?.settings) {
                                        return;
                                    }
                                    window.cordova.plugins.settings.open(
                                        "application_details",
                                        function() {},
                                        function() {},
                                    );
                                }}
                                bsStyle="link"
                            />
                        </Box>
                    ),
                ),
            );

            dispatch(routerActions.replace("/desktop"));
            return;
        }

        const responseContactList = await findContact({
            fields: {
                displayName: true,
                firstName: true,
                middleName: true,
                familyName: true,
                organizationName: true,
                phoneNumbers: true,
                emails: true,
            },
            baseCountryCode: null,
        });

        const contactRefList = responseContactList
            .map((item) => {
                if (
                    !item?.phoneNumbers ||
                    !Array.isArray(item.phoneNumbers) ||
                    item.phoneNumbers.length === 0 ||
                    !item.phoneNumbers[0]?.value
                ) {
                    return undefined;
                }

                const phoneFormatData = kuaraVersionNumber(1)
                    ? extractCountryCodePanama(item.phoneNumbers[0].value)
                    : extractCountryAndNumber(item.phoneNumbers[0].value);

                if (
                    kuaraVersionNumber(1)
                        ? !phoneFormatData?.countryCode || !phoneFormatData?.phoneNumber
                        : phoneFormatData === null || phoneFormatData === ""
                ) {
                    return undefined;
                }

                const { countryCode, phoneNumber } = phoneFormatData;
                const countryCodeAux = countryCode || "";
                const phoneNumberAux = phoneNumber || "";
                const finalPhoneNumber = !countryCodeAux ? phoneNumberAux : `${countryCodeAux}-${phoneNumberAux}`;
                const displayName = getContactDisplayName(item);

                return {
                    originalId: item?.id,
                    displayName,
                    firstName: item?.firstName,
                    middleName: item?.middleName,
                    familyName: item?.familyName,
                    organizationName: item?.organizationName,
                    phoneNumbers: item?.phoneNumbers,
                    phoneNumber: finalPhoneNumber,
                    phoneNumberOriginal: item.phoneNumbers[0].value,
                    originalRawId: item?.rawId,
                    emails: item?.emails,
                };
            })
            .filter((itemFilter) => itemFilter);
        setListPeopleAll(contactRefList);
        setListPeople(contactRefList);
        dispatch(kuaraActions.setBeneficiaryPeople(contactRefList));
        dispatch(kuaraActions.setFetchingPeople(false));
    };

    const filterBeneficiaries = (value, beneficiaries, keys, setBeneficiaries) => {
        if (value !== "") {
            const data = filterData(beneficiaries, keys, value);
            setBeneficiaries(data);
        } else {
            setBeneficiaries(beneficiaries);
        }
    };

    const handleBack = () => {
        dispatch(push("/kuara"));
    };

    const onFinish = () => {
        dispatch(push("/sendKuaraTransaction"));
    };

    const onSelectBeneficiary = (beneficiary) => {
        dispatch(
            kuaraActions.setSelectedBeneficiary({
                originalId: beneficiary?.originalId,
                contactName: beneficiary?.displayName,
                firstName: beneficiary?.firstName,
                middleName: beneficiary?.middleName,
                familyName: beneficiary?.familyName,
                organizationName: beneficiary?.organizationName,
                contactPhone: beneficiary?.phoneNumber,
                phoneNumberOriginal: beneficiary?.phoneNumberOriginal,
                originalRawId: beneficiary?.originalRawId,
                emails: beneficiary?.emails,
                phoneNumbers: beneficiary?.phoneNumbers,
            }),
        );
        dispatch(kuaraActions.validateNumbersRequest([beneficiary?.phoneNumber], onFinish));
    };

    const handleFavorite = (beneficiary) => {
        dispatch(kuaraActions.favoriteRequest(beneficiary));
    };

    const handlerShowWallyDialog = () => {
        setShowWallyDialog(true);
    };

    const handlerCloseWallyDialog = () => {
        setShowWallyDialog(false);
    };

    return (
        <PageLoading loading={fetchingPeople}>
            <Head
                subtitlePrefix={accountKuara?.productAlias}
                subtitle={formatAccount(accountKuara)}
                onBack={handleBack}
                title="kuara.forms.transfers.title"
                additionalClassName="kuara-header"
            />
            <Notification scopeToShow="sendKuara" />
            <ProductDetail>
                <ProductDetail.Body
                    id="productDetail.body"
                    isDesktop={isDesktop}
                    background={null}
                    wally
                    scroll
                    beforeTabs={
                        <Box display="flex" column alignX="center" alignY="center" className="pt-7 pb-6">
                            <Text bold size="2" align="center" color="heading" labelKey="kuara.forms.transfers.help" />
                        </Box>
                    }>
                    <Box display="flex" column alignY="center" fullWidth>
                        <Formik>
                            <Form className="full-width">
                                <Box
                                    className="py-9 mb-3"
                                    background="component-background"
                                    borderRadius="default"
                                    alignY="center">
                                    <Box display="flex" className="pl-5 pr-7" fullWidth>
                                        <Field
                                            component={TextField}
                                            name="filter"
                                            type="text"
                                            placeholder={i18n.get("global.search")}
                                            showLabel={false}
                                            noLabel
                                            serarchStyle
                                            labelNoMarginTop
                                            handleOnChange={(event) =>
                                                filterBeneficiaries(
                                                    event.target.value,
                                                    listPeopleAll,
                                                    ["phoneNumber", "fullName", "displayName"],
                                                    setListPeople,
                                                )
                                            }
                                        />
                                        <Button
                                            className="ml-3"
                                            imageMd
                                            image="images/add-contact.svg"
                                            href="/sendKuaraAddContact"
                                            imageColor="kuara-secondary"
                                        />
                                    </Box>
                                    {kuaraVersionNumber(1) && (
                                        <Box fitWidth className="pl-5">
                                            <Button
                                                label="kuara.to.wally"
                                                bsStyle="primary"
                                                className="btn-small kuara-color mt-4"
                                                block
                                                onClick={() => handlerShowWallyDialog()}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Form>
                        </Formik>

                        <KuaraListContacts
                            contacts={listPeople}
                            onSelectBeneficiary={onSelectBeneficiary}
                            updateFavorite={handleFavorite}
                        />
                    </Box>
                </ProductDetail.Body>
            </ProductDetail>
            <WallyModal show={showWallyDialog} onClose={handlerCloseWallyDialog} dispatch={dispatch} />
        </PageLoading>
    );
};

const mapStateToProps = (state) => ({
    isActiveKuara: kuaraSelectors.getIsActiveKuara(state),
    beneficiariesPeople: kuaraSelectors.getBeneficiariesPeople(state),
    accountKuara: kuaraSelectors.getAccountKuara(state),
    fetchingPeople: kuaraSelectors.isFetchingPeople(state),
});

SendKuara.propTypes = {
    dispatch: func.isRequired,
    isActiveKuara: bool,
    isDesktop: bool.isRequired,
    beneficiariesPeople: arrayOf(shape({})),
    accountKuara: shape({}),
    fetchingPeople: bool,
};

SendKuara.defaultProps = {
    isActiveKuara: false,
    beneficiariesPeople: [],
    accountKuara: {},
    fetchingPeople: false,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(SendKuara));
