import { isAndroidPlatform, isIOSPlatform } from "util/device";
import TagManager from "react-gtm-module";

export const DATA_LAYER_PREAPPROVED_REQUEST = "form_solicitud_producto";
export const DATA_LAYER_FILTER_REQUEST_CLAIMS = "filter_solicitud_reclamos";
export const DATA_LAYER_UPDATE_EMAIL_CLAIMS = "change_email";
export const DATA_LAYER_UPDATE_PHONE_CLAIMS = "change_phone";
export const DATA_LAYER_TRANSFER_TRACKING = "clic_seguir_transferencia";

export const EVENT_BY_ACTIVITY_MAP = new Map([
    ["reference.letter.send", "form_carta_referencia"],
    ["report.replace.card.send", "form_reporte_tarjeta"],
]);

export const getMobileTagManagetId = (configRef) => {
    if (!configRef) {
        return undefined;
    }

    if (isIOSPlatform()) {
        return configRef.get("google.tag.manager.id.ios", "");
    }

    if (isAndroidPlatform()) {
        return configRef.get("google.tag.manager.id.android", "");
    }

    return undefined;
};

export const setDataLayer = async (props) => {
    try {
        const tagManagerArgs = {
            dataLayer: {
                ...(props || {}),
            },
        };

        TagManager.dataLayer(tagManagerArgs);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

export const getGtmPayload = (dataValues, gtmKeys) => {
    if (!gtmKeys || !Array.isArray(gtmKeys) || typeof dataValues !== "object" || dataValues === null) {
        return {};
    }

    const getNestedValue = (obj, path) =>
        path.split(".").reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);

    return gtmKeys.reduce((acc, key) => {
        const value = getNestedValue(dataValues, key);
        if (value !== undefined) {
            acc[key] = value;
        }
        return acc;
    }, {});
};
