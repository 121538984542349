import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    TRANSFER_RECEIVED_LIST_REQUEST: "supportTransfer/TRANSFER_RECEIVED_LIST_REQUEST",
    TRANSFER_RECEIVED_LIST_SUCCESS: "supportTransfer/TRANSFER_RECEIVED_LIST_SUCCESS",
    TRANSFER_RECEIVED_LIST_FAILURE: "supportTransfer/TRANSFER_RECEIVED_LIST_FAILURE",

    SEND_UPLOAD_SUPPORT_TRANSFER_REQUEST: "supportTransfer/SEND_UPLOAD_SUPPORT_TRANSFER_REQUEST",
    SEND_UPLOAD_SUPPORT_TRANSFER_REQUEST_SUCCESS: "supportTransfer/SEND_UPLOAD_SUPPORT_TRANSFER_REQUEST_SUCCESS",
    SEND_UPLOAD_SUPPORT_TRANSFER_REQUEST_FAILURE: "supportTransfer/SEND_UPLOAD_SUPPORT_TRANSFER_REQUEST_FAILURE",

    RESET_FORM: "supportTransfer/RESET_FORM",
};

export const INITIAL_STATE = {
    transferList: [],
    fetching: false,
    fetchingSend: false,
};

export const actions = {
    getTransferListRequest: makeActionCreator(types.TRANSFER_RECEIVED_LIST_REQUEST, "idAccount"),
    sendUploadSupportRequest: makeActionCreator(
        types.SEND_UPLOAD_SUPPORT_TRANSFER_REQUEST,
        "idAccount",
        "transferFT",
        "supportFiles",
        "reason",
        "relation",
        "onFinish",
    ),
    resetForm: makeActionCreator(types.RESET_FORM),
};

export const selectors = {
    getTransferList: ({ supportTransferReceived }) => supportTransferReceived?.transferList || [],
    isFetching: ({ supportTransferReceived }) => supportTransferReceived?.fetching || false,
    isFetchingSend: ({ supportTransferReceived }) => supportTransferReceived?.fetchingSend || false,
};

export default createReducer(INITIAL_STATE, {
    [types.TRANSFER_RECEIVED_LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        fetchingSend: false,
        transferList: [],
    }),
    [types.TRANSFER_RECEIVED_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: true,
        fetchingSend: false,
        transferList: [],
    }),
    [types.TRANSFER_RECEIVED_LIST_SUCCESS]: (state, action) => ({
        ...state,
        transferList: action?.transferList || [],
        fetching: false,
        fetchingSend: false,
    }),

    [types.SEND_UPLOAD_SUPPORT_TRANSFER_REQUEST]: (state) => ({
        ...state,
        fetchingSend: true,
        fetching: false,
    }),
    [types.SEND_UPLOAD_SUPPORT_TRANSFER_REQUEST_FAILURE]: (state) => ({
        ...state,
        fetchingSend: false,
        fetching: false,
    }),
    [types.SEND_UPLOAD_SUPPORT_TRANSFER_REQUEST_SUCCESS]: (state, action) => ({
        ...state,
        transferList: state.transferList.filter((el) => el.idStatement !== action?.transferFT),
        fetchingSend: false,
        fetching: false,
    }),
    [types.RESET_FORM]: (state) => ({
        ...state,
        ...INITIAL_STATE,
    }),
});
