import Accordion from "pages/_components/Accordion";
import AttachedFile from "pages/_components/AttachedFile";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import FieldError from "pages/_components/fields/FieldError";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useRef } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as notificationActions } from "reducers/notification";
import { compose } from "redux";
import * as config from "util/config";
import useFileProcessor from "../hooks/supportFilesHooks";

const SupportFiles = (props) => {
    const { form, dispatch, mode, isDesktop, idField, ID_FORM, supportDocuments, error } = props;
    const fileInputRef = useRef(null);
    const { setFieldValue, setFieldError } = form;
    const { processFileAsync } = useFileProcessor();
    // configuraciones
    const maxDocuments = config.get("support.transfer.file.max.uploads", 2);

    const handleAddDocument = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const fileAux = event.target.files[0];
        processFileAsync(
            fileAux,
            supportDocuments,
            setFieldValue,
            idField,
            dispatch,
            notificationActions,
            setFieldError,
        );
    };

    const removeSelectedFile = (file) => {
        const newSupportFiles = supportDocuments.filter((item) => item.key !== file?.key);
        setFieldValue(idField, newSupportFiles);
        setFieldError(idField, "");
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const renderDetailView = () => (
        <Box display="flex" fullWidth column>
            {supportDocuments.map((doc, index) => (
                <Box
                    id={`doc${index}`}
                    display="flex"
                    alignX="flex-end"
                    className="attachment-data-width-100-6-5rem"
                    flex1>
                    <AttachedFile file={doc} />
                </Box>
            ))}
        </Box>
    );

    const content = () => (
        <>
            <Box
                display="flex"
                borderRadius="default"
                border="background-divider-1"
                style={{
                    flexDirection: "column",
                    padding: "1rem",
                }}>
                {supportDocuments.map((doc, index) => (
                    <>
                        <Box
                            id={`doc${index}`}
                            display="flex"
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                            <Box display="flex">
                                <Image
                                    src="images/icons/check_circle.svg"
                                    wrapperWidth="5"
                                    wrapperHeight="5"
                                    width="5"
                                    height="5"
                                    className="pt-2"
                                />
                                <Box
                                    display="flex"
                                    className="ml-4 mr-4"
                                    alignX="center"
                                    style={{
                                        flexDirection: "column",
                                    }}>
                                    <Text bold size="6">
                                        {doc.name}
                                    </Text>
                                    <Text
                                        color="text-color"
                                        size="7"
                                        labelKey={`forms.${ID_FORM}.supportFiles.attach`}
                                    />
                                </Box>
                            </Box>
                            <Button
                                image="images/icons/deleteTrash.svg"
                                onClick={() => {
                                    removeSelectedFile(doc);
                                }}
                                aria-label={`forms.${ID_FORM}.supportFiles.delete.attach`}
                            />
                        </Box>

                        {index === 0 && supportDocuments.length >= 1 && (
                            <div className="separator mb-9">
                                <hr />
                            </div>
                        )}
                    </>
                ))}
                {supportDocuments.length < maxDocuments && (
                    <>
                        <Box display="flex" alignY="center">
                            <Image
                                src="images/icons/upload.svg"
                                className="svg-image"
                                color="primary-color"
                                wrapperWidth="5"
                                wrapperHeight="5"
                                width="5"
                                height="5"
                            />

                            <Col className="ml-4 mr-4">
                                <Row heightAuto>
                                    <Box display="flex" alignX="space-between">
                                        <Button
                                            bsStyle="link"
                                            className="p-0 remove-min-height mb-2"
                                            onClick={() => fileInputRef.current.click()}>
                                            <Text
                                                labelKey={`forms.${ID_FORM}.supportFiles.upload`}
                                                color="primary"
                                                bold
                                                size="6"
                                                underlined
                                            />
                                        </Button>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: "none" }}
                                            onChange={handleAddDocument}
                                        />
                                    </Box>
                                </Row>
                                <Row heightAuto>
                                    <Text
                                        color="text-color"
                                        size="7"
                                        labelKey={`forms.${ID_FORM}.supportFiles.upload.info`}
                                    />
                                </Row>
                            </Col>
                        </Box>
                    </>
                )}
            </Box>
            {error && <FieldError error={error} relativeStyleError />}
        </>
    );

    return (
        <>
            {mode === "edit" && <Box>{content()}</Box>}

            {mode === "preview" && isDesktop && (
                <Box className="ticket-info-wrapper">
                    <Accordion grayBox>
                        <Accordion.Item title={`forms.${ID_FORM}.supportFiles.title`}>
                            {renderDetailView()}
                        </Accordion.Item>
                    </Accordion>
                </Box>
            )}
            {mode === "preview" && !isDesktop && (
                <Box className="ticket-info-wrapper">
                    <Text
                        component="h4"
                        color="primary"
                        size="5"
                        bold
                        labelKey={`forms.${ID_FORM}.supportFiles.title`}
                        className="mt-0 mb-4"
                    />
                    {renderDetailView()}
                </Box>
            )}
        </>
    );
};

SupportFiles.propTypes = {
    ID_FORM: string.isRequired,
    mode: string.isRequired,
    idField: string.isRequired,
    isDesktop: bool.isRequired,
    form: shape({}).isRequired,
    dispatch: func.isRequired,
    supportDocuments: arrayOf(shape({})),
    error: string,
};

SupportFiles.defaultProps = {
    supportDocuments: [],
    error: "",
};

export default compose(connect(), withRouter)(SupportFiles);
