import React, { createRef } from "react";
import { getDisplay, getMobileOS } from "util/device";
import { downloadMobileFile } from "util/download";
import { getBase64FromComponent } from "util/htmlToImage/htmlToImage.util";
import * as i18n from "util/i18n";
import b64toBlob from "b64-to-blob";

const withScreenshotExport = (WrappedComponent, exclusions = [], quality = 1.0) =>
    class extends React.Component {
        constructor(props) {
            super(props);
            this.ref = createRef();
        }

        base64ToBlob = (base64String, contentType = "application/octet-stream") => {
            const byteCharacters = atob(base64String);
            const byteArrays = [];

            for (let i = 0; i < byteCharacters.length; i++) {
                byteArrays.push(byteCharacters.charCodeAt(i));
            }

            return new Blob([new Uint8Array(byteArrays)], { type: contentType });
        };

        generateUrlFromBlob = async (base64, title) =>
            new Promise((resolve, reject) => {
                const blob = this.base64ToBlob(base64, "image/png");
                const reader = new FileReader();

                reader.onloadend = () => {
                    let dataUrl = reader.result;
                    const chunks = dataUrl.split(",");

                    if (getMobileOS(getDisplay()) === "iOS") {
                        dataUrl = `data:image/png;${i18n.get(title)?.replace("/", "-")}.png;base64,${chunks[1]}`;
                    }

                    resolve(dataUrl);
                };

                reader.onerror = (e) => reject(e);
                reader.readAsDataURL(blob);
            });

        downloadImage = async (fileName) => {
            setTimeout(async () => {
                if (!this.ref.current) {
                    return;
                }

                try {
                    await getBase64FromComponent(this.ref, 0.0000000001, []);

                    const dataUrl = await getBase64FromComponent(this.ref, quality, exclusions);
                    const chunks = dataUrl.split(",");

                    const fileBlob = b64toBlob(chunks[1], "image/png");
                    downloadMobileFile(fileBlob, fileName, "image/png");
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error("Error download image:", error);
                }
            }, 1000);
        };

        shareImage = async (fileName) => {
            if (!this.ref.current) {
                return;
            }

            try {
                await getBase64FromComponent(this.ref, 0.0000000001, []);
                const dataUrl = await getBase64FromComponent(this.ref, quality, exclusions);
                const cleanBase64 = dataUrl.replace(/^data:image\/\w+;base64,/, "");
                const url = await this.generateUrlFromBlob(cleanBase64, fileName);

                const options = {
                    files: [url],
                };

                window.plugins.socialsharing.shareWithOptions(options, null, null);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error("Error shareImage image:", error);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    screenshotRef={this.ref}
                    downloadImage={this.downloadImage}
                    shareImage={this.shareImage}
                />
            );
        }
    };

export default withScreenshotExport;
