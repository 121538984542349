/* eslint-disable */
import { PANAMA_COUNTRY_NAME, REGION_REST_OF_LATAM, REGION_USA } from "constants.js";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import PhoneInput from "pages/_components/fields/PhoneInput";
import TextField from "pages/_components/fields/TextField";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux/actions";
import { selectors as loginSelectors } from "reducers/login";
import { actions as kuaraActions, selectors as kuaraSelectors } from "reducers/kuara";
import { compose } from "redux";
import { requestWritePermission, updateContact } from "util/contact.util";
import * as i18n from "util/i18n";
import * as config from "util/config";
import * as Yup from "yup";
import { formatAccount } from "./hooks/KuaraHooks";
import kuaraVersionNumber from "util/kuaraVersionNumber";
import { actions as notificationActions } from "reducers/notification";

const SendKuaraUpdateContact = (props) => {
    const { dispatch, history, fetching, accountKuara, activeRegion, selectedBeneficiary } = props;

    const isRequestAddContact =
        location.hash === "#/sendKuaraRequestAddContact" || location.pathname === "/sendKuaraRequestAddContact";
    const [contactPhone, setContactPhone] = useState("507" + selectedBeneficiary?.contactPhone);
    const [contactName, setContactName] = useState(selectedBeneficiary?.contactName);
    const contactPhoneObj = { prefix: "507-", value: contactPhone };
    const [responseWriteRequest, setResponseWriteRequest] = useState(false);

    let country = REGION_USA;
    if (activeRegion !== REGION_REST_OF_LATAM) {
        country = activeRegion.toLowerCase();
    }

    useEffect(() => {
        requestUpdatePhone();
    }, []);

    const requestUpdatePhone = async () => {
        const responseWriteRequest = await requestWritePermission();
        setResponseWriteRequest(responseWriteRequest);
    };

    const handleUpdatePhone = async (contact) => {
        updateContact(contact);
    };

    const handleBack = () => {
        history.goBack();
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleSubmit = ({ cellPhone, name }) => {
        const phone =
            (cellPhone.prefix.includes("-") ? cellPhone.prefix : cellPhone.prefix + "-") +
            cellPhone.areaCode +
            cellPhone.value;
        setContactName(name);
        setContactPhone(phone);

        const updBeneficiary = {
            id: selectedBeneficiary?.originalId,
            displayName: name,
            firstName: name,
            phoneNumbers: [
                {
                    id: selectedBeneficiary?.phoneNumbers[0].id,
                    type: "mobile",
                    value: phone,
                },
            ],
            rawId: selectedBeneficiary?.originalRawId,
        };

        const updPhones = [
            {
                id: selectedBeneficiary.phoneNumbers[0].id,
                type: selectedBeneficiary.phoneNumbers[0].type,
                value: phone,
            },
        ];

        const updBeneficiaryForState = {
            ...selectedBeneficiary,
            contactName: name,
            contactPhone: phone,
            firstName: name,
            phoneNumberOriginal: phone,
            phoneNumbers: updPhones,
        };
        dispatch(kuaraActions.setSelectedBeneficiary(updBeneficiaryForState));

        if (responseWriteRequest) {
            handleUpdatePhone(updBeneficiary);
        }

        dispatch(push(isRequestAddContact ? "/sendKuaraRequestTransaction" : "/sendKuaraTransaction"));
    };

    const validationSchema = () =>
        Yup.lazy(() =>
            Yup.object().shape({
                name: Yup.string().required(i18n.get("kuara.name.error.empty")),
                cellPhone: Yup.object()
                    .shape({
                        value: Yup.string().required(i18n.get("kuara.cellPhone.error.empty")),
                    })
                    .test("is-pa", i18n.get("kuara.transfers.invalid.number"), (dataNumber) => {
                        const prefixComplete = dataNumber?.prefix;
                        const prefix = prefixComplete ? prefixComplete.split("-")[0] : "";
                        const value = dataNumber?.value;
                        const countriesValidations = config.getArray("kuara.countries.numbers.validation");
                        if (prefix && prefix !== "") {
                            const countriesValidate = countriesValidations.filter((country) =>
                                country.includes(prefix + ":"),
                            );
                            if (countriesValidate.length === 0) {
                                return true;
                            }
                            const country = countriesValidate[0];
                            const countryNumberLength = country.split(":")[1];
                            return parseInt(countryNumberLength) === value.length;
                        }
                        return true;
                    }),
            }),
        );

    return (
        <PageLoading loading={fetching}>
            <Head
                title={isRequestAddContact ? "kuara.forms.requestTransfers.title" : "forms.transfers.kuara.title"}
                subtitlePrefix={accountKuara?.productAlias}
                subtitle={formatAccount(accountKuara)}
                onBack={handleBack}
                additionalClassName="kuara-header"
            />
            <Notification scopeToShow="sendKuaraUpdateContact" />
            <MainContainer>
                <Box display="flex" column fullWidth fullHeight className="pt-7">
                    <Box display="flex" column alignX="center" alignY="center" className="pb-6">
                        <Text bold size="2" align="center" color="heading">
                            {i18n.get("kuara.update.contact")}
                        </Text>
                    </Box>
                    <Box display="flex" column alignX="center" alignY="center" className="pb-6">
                        <Text bold size="6" align="center" color="kuara-secondary">
                            {i18n.get("kuara.update.contact.additional.info")}
                        </Text>
                    </Box>
                    <Formik
                        initialValues={{
                            cellPhone: contactPhoneObj,
                            name: contactName,
                        }}
                        validationSchema={validationSchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                        onSubmit={handleSubmit}>
                        <Form className="full-height">
                            <Box display="flex" column fullWidth fullHeight>
                                <Box background="white" className="pt-1 pb-9 px-5 mx-n-5 mb-3" borderRadius="default">
                                    <Field
                                        idForm="wally"
                                        name="cellPhone"
                                        component={PhoneInput}
                                        country={country}
                                        placeholder=""
                                        value={contactPhone}
                                        {...(kuaraVersionNumber(1) && { onlyCountries: [PANAMA_COUNTRY_NAME] })}
                                    />
                                </Box>
                                <Box
                                    background="white"
                                    display="flex"
                                    column
                                    className="px-5 mb-9 mx-n-5"
                                    borderRadius="default">
                                    <Box className="pb-7">
                                        <Field
                                            name="name"
                                            component={TextField}
                                            placeholder=""
                                            labelText={i18n.get("kuara.name.label")}
                                            initValue={contactName}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" column fullWidth className="mt-auto">
                                    <Button bsStyle="primary" className="kuara-color" block type="submit">
                                        {i18n.get("kuara.continue.button")}
                                    </Button>
                                    <Button
                                        label="kuara.select.button.cancel"
                                        onClick={handleCancel}
                                        bsStyle="outline"
                                        block
                                    />
                                </Box>
                            </Box>
                        </Form>
                    </Formik>
                </Box>
            </MainContainer>
        </PageLoading>
    );
};

const mapStateToProps = (state) => ({
    accountKuara: kuaraSelectors.getAccountKuara(state),
    activeRegion: loginSelectors.getRegion(state),
    fetching: kuaraSelectors.isFetching(state),
    selectedBeneficiary: kuaraSelectors.getSelectedBeneficiary(state),
});

SendKuaraUpdateContact.propTypes = {
    dispatch: func.isRequired,
    accountKuara: shape({}),
    history: shape({}).isRequired,
    fetching: bool,
    activeRegion: string,
    selectedBeneficiary: shape({}),
};

SendKuaraUpdateContact.defaultProps = {
    fetching: false,
    accountKuara: {},
    activeRegion: "",
    selectedBeneficiary: {},
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(SendKuaraUpdateContact));
