import classNames from "classnames";
import { Field } from "formik";
import moment from "moment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import Text from "pages/_components/Text";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FormTransition from "pages/forms/_components/FormTransition";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import {
    PDFAmountField,
    PDFAmountFieldError,
    PDFExpirationText,
    PDFTextField,
    PDFTextFieldKualaMessage,
    PDFaddresseeField,
} from "pages/forms/customForms/PDFTicketKuara";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux/actions";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { actions as kuaraActions, selectors as kuaraSelectors } from "reducers/kuara";
import { compose } from "redux";
import * as config from "util/config";
import * as i18n from "util/i18n";
import { numberFormat } from "util/number";
import * as schedulerUtils from "util/scheduler";
import useAsyncTicketProcessing from "hooks/useAsyncTicketProcessing";
import { statusMap } from "util/general";
import { formatAccount, getDefaultValueDate, useMetaData } from "./hooks/KuaraHooks";

const maxLengthReason = config.getInteger("core.password.maxLength");
const expirationDays = config.getInteger("core.requestMoney.expirationDays.wally");
const { decimalSeparator, thousandSeparator } = numberFormat();

const SendKuaraTransaction = (props) => {
    const {
        mode,
        location,
        dispatch,
        preDataForm,
        kuara,
        beneficiaryKuara,
        accountKuara,
        userKuara,
        transaction,
        isFetchingPeople,
        isFetchingKuara,
        fetching,
    } = props;

    const [tooltipProcessingComponent] = useAsyncTicketProcessing(transaction);
    const isRequestTransferForm = location.pathname === "/sendKuaraRequestTransaction";
    const isSendTransferForm = location.pathname === "/sendKuaraTransaction";
    const isSendPendingTransferForm = location.pathname === "/transactionPendingKara";
    const isRenderTicket = location.pathname === "/transactionKuara" || location.pathname.includes("/transaction/");
    let ID_FORM = isRenderTicket ? transaction?.idActivity?.replace(".send", "") : "";
    ID_FORM = !isRenderTicket && isRequestTransferForm ? "requestTransfers.kuara" : ID_FORM;
    ID_FORM = !isRenderTicket && isSendTransferForm ? "transfers.kuara" : ID_FORM;
    ID_FORM = !isRenderTicket && isSendPendingTransferForm ? "transfers.approve.kuara" : ID_FORM;

    const ID_ACTIVITY = `${ID_FORM}.send`;
    const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
    const TITLE_FORM = isRenderTicket ? "activities.transfers.kuara.send" : `forms.${ID_FORM}.title`;
    ID_FORM = ID_FORM === undefined ? "transfers.kuara" : ID_FORM;
    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);
    const [favorite, setFavorite] = useState(beneficiaryKuara?.favorite);

    useEffect(() => {
        if (mode === "edit" && !isSendPendingTransferForm && ID_ACTIVITY_PRE !== "undefined.pre") {
            const requestData = isRequestTransferForm
                ? null
                : {
                      beneficiaryPhone: beneficiaryKuara?.contactPhone,
                      beneficiaryName: beneficiaryKuara?.contactName,
                      isLocal: true,
                  };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData }));
        } else {
            dispatch(kuaraActions.setFetching(false));
        }
    }, [mode]);

    useEffect(() => {
        if (mode === "edit" && isSendPendingTransferForm) {
            // dispatch(formActions.confirmMovementPendingWally(ID_ACTIVITY));
        }
    }, []);

    useEffect(() => {
        setFavorite(beneficiaryKuara?.favorite);
    }, [isFetchingPeople]);

    const validateForm = (values) => {
        const errors = {};
        const { amount } = values;
        if (!amount?.quantity) {
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.required`);
        }

        if (amount?.quantity > accountKuara?.balance && !isRequestTransferForm) {
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.insufficient`);
        }

        if (values?.creditReference && values?.creditReference.length > (maxLengthReason || 40)) {
            errors.creditReference = `forms.${ID_FORM}.message.maxCharacters`;
        }

        return errors;
    };

    const handlerCancelAction = () => {
        dispatch(push("/sendKuara"));
    };

    const handlerLink = () => {
        dispatch(kuaraActions.setPathRedirectToKuara(location.pathname));
        dispatch(push(`/formCustom/transferInternal?creditAccount=${accountKuara.idProduct}&&from=kuara`));
    };
    const addDayExpirations = (date) => {
        const tmpDate = date.clone();
        tmpDate.add(expirationDays || 15, "days");
        return tmpDate;
    };

    const renderTicket = (values) => {
        const idStatus = statusMap.get(transaction?.idTransactionStatus) ?? transaction?.idTransactionStatus;
        const showReference =
            (idStatus === "FINISHED" || idStatus === "REJECTED") &&
            transaction.idActivity !== "requestTransfers.kuara.send";
        const ticketFinished = idStatus === "FINISHED";

        return (
            <>
                {ticketFinished ? (
                    <PDFAmountField
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.sending`)}
                    />
                ) : (
                    <PDFAmountFieldError idForm={ID_FORM} name="amount" values={values} />
                )}
                <PDFaddresseeField
                    idForm={ID_FORM}
                    value={values?.beneficiaryFullName}
                    label={
                        ID_ACTIVITY === "receivedTransfers.kuara.send"
                            ? i18n.get("forms.receivedTransfers.kuara.from")
                            : i18n.get("forms.transfers.kuara.to")
                    }
                />
                {values?.creditReference ? (
                    <PDFTextFieldKualaMessage
                        idForm={ID_FORM}
                        value={values?.creditReference}
                        label={i18n.get("forms.transfers.kuara.textarea.label")}
                    />
                ) : null}
                {showReference && values?.reference ? (
                    <PDFTextField
                        idForm={ID_FORM}
                        name="reference"
                        values={values}
                        label={i18n.get(`forms.transfers.kuara.voucher`)}
                    />
                ) : null}
                <PDFTextField
                    idForm={ID_FORM}
                    value={
                        values?.scheduler?.valueDate?.format("DD/MM/YYYY hh:mm A") ||
                        moment(values?.transactionDate, "YYYY-MM-DDTHH:mm:ss.000Z").format("DD/MM/YYYY hh:mm A")
                    }
                    label={i18n.get("forms.transfers.internal.date.label_preview")}
                />

                {isRequestTransferForm && mode === "view" && (
                    <PDFExpirationText
                        idForm={ID_FORM}
                        value={`${i18n.get(`forms.${ID_FORM}.expirationDate`)} ${addDayExpirations(
                            values?.scheduler?.valueDate,
                        )?.format("DD/MM/YYYY hh:mm A")}`}
                    />
                )}
            </>
        );
    };

    const renderFields = (setFieldValue, values) => {
        const { currentLang, fromBackoffice } = props;
        const genericProps = {
            mode,
            lang: currentLang,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        const dataAmount = {
            decimalSeparator,
            options: [{ id: "USD", label: "USD" }],
            precision: 2,
            thousandSeparator,
        };

        const onChangeAmmount = () => {
            if (isRequestTransferForm) {
                setFieldValue("creditAcctId", accountKuara?.idProduct);
                setFieldValue("debitAccountId", beneficiaryKuara?.accountId);
            } else {
                setFieldValue("debitAccountId", accountKuara?.idProduct);
                setFieldValue("creditAcctId", beneficiaryKuara?.accountId);
            }
            setFieldValue("beneficiaryFullName", beneficiaryKuara?.contactName);
            setFieldValue("beneficiaryPhoneNumber", beneficiaryKuara?.contactPhone);
            setFieldValue("beneficiaryPartyId", beneficiaryKuara?.partyId);
            setFieldValue("kuaraPhoneNumber", userKuara?.phoneNumber);
            setFieldValue("isCommerce", beneficiaryKuara?.type === "C");

            setFieldValue("scheduler", {
                selectedOption: schedulerUtils.TODAY,
                valueDate: getDefaultValueDate(metadata),
            });
        };

        const handleFavorite = () => {
            if (!isFetchingPeople) {
                dispatch(kuaraActions.favoriteRequest(beneficiaryKuara));
            }
        };

        return (
            <>
                {mode === "edit" && (
                    <>
                        {isRequestTransferForm ? (
                            <Box display="flex" column alignY="center" className="mt-4 mb-3" fullWidth>
                                <Box display="flex" alignX="center" alignY="center" className="mb-2" fullWidth>
                                    <Text color="heading" size="3" className="mr-2">
                                        {i18n.get(`forms.${ID_FORM}.titleForm`)}
                                    </Text>
                                </Box>
                            </Box>
                        ) : (
                            <Box display="flex" column alignY="center" className="mt-4 mb-3" fullWidth>
                                <Box display="flex" alignX="center" alignY="center" className="mb-2" fullWidth>
                                    <Text color="heading" size="3" className="mr-2">
                                        {i18n.get("forms.transfers.kuara.available")}
                                        {":"}
                                    </Text>
                                    <FormattedAmount
                                        color="heading"
                                        size="2"
                                        bold
                                        quantity={accountKuara?.balance}
                                        currency={accountKuara?.currency}
                                    />
                                </Box>
                                <Button
                                    label={
                                        beneficiaryKuara?.type === "P"
                                            ? "forms.transfers.kuara.link.people"
                                            : "forms.transfers.kuara.link"
                                    }
                                    bsStyle="link"
                                    className="kuara-secondary-color"
                                    small
                                    onClick={handlerLink}
                                />
                            </Box>
                        )}
                        <Box display="flex" column fullWidth>
                            <Box className="px-5 pt-5 pb-9 mb-3 mx-n-5" background="white" borderRadius="default">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Amount}
                                    data={dataAmount}
                                    key="amount"
                                    name="amount"
                                    value={{ currency: "USD", quantity: values?.amount }}
                                    idField="amount"
                                    customPlaceholderCurrency=""
                                    customPlaceholderQuantity="0.00"
                                    quantityTextAlign="text-left"
                                    disabledCurrency
                                    boldAmount
                                    onChange={onChangeAmmount}
                                />
                            </Box>
                            <Box
                                display="flex"
                                alignY="center"
                                className="py-7 pl-7 pr-6 mb-3 mx-n-5"
                                background="white"
                                borderRadius="default"
                                position="relative">
                                <Text size="3" color="heading" className="position-absolute">
                                    {i18n.get("forms.transfers.kuara.to")}:
                                </Text>
                                <Text fullWidth size="2" color="heading" bold className="px-7 mx-auto" align="center">
                                    {beneficiaryKuara?.contactName}
                                </Text>
                                {beneficiaryKuara?.type === "P" && (
                                    <Button
                                        loading={isFetchingPeople}
                                        bsStyle="only-icon"
                                        imageSm
                                        image={favorite ? "images/star.svg" : "images/star-off.svg"}
                                        onClick={() => handleFavorite()}
                                    />
                                )}
                            </Box>
                            <Box className="px-5 pb-8 mx-n-5" background="white" borderRadius="default">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Textarea}
                                    key="creditReference"
                                    name="creditReference"
                                    idField="creditReference"
                                    value={values?.creditReference}
                                    renderAs="creditReference"
                                    isRequired={false}
                                    optional={i18n.get("form.field.optional")}
                                    maxLength={maxLengthReason || 40}
                                />
                            </Box>
                        </Box>
                    </>
                )}

                {mode === "preview" || mode === "view" ? (
                    <Box display="flex" alignX="center" fullWidth className="amount-wrapper kuara">
                        <Box display="flex" column>
                            {mode === "preview" ? (
                                <Box display="flex" alignX="center">
                                    <Text
                                        component="label"
                                        labelKey={`forms.${ID_ACTIVITY}`}
                                        className="mb-0"
                                        size="5"
                                        color="heading"
                                        regular
                                    />
                                </Box>
                            ) : (
                                <Box display="flex">
                                    <FieldLabel
                                        labelNoMarginTop
                                        labelText={i18n.get(`forms.${ID_FORM}.sending`)}
                                        mode="view"
                                    />
                                </Box>
                            )}
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Amount}
                                data={dataAmount}
                                key="amount"
                                name="amount"
                                value={values.amount || transaction?.amount}
                                idField="amount"
                                bigDataAmount
                                renderLabel={false}
                            />
                        </Box>
                    </Box>
                ) : null}

                {mode === "preview" || mode === "view" ? (
                    <Box className={classNames("kuara", { "mt-6": mode === "view" })}>
                        {mode === "view" && tooltipProcessingComponent}

                        <Box
                            display="flex"
                            alignX="center"
                            {...(mode === "preview" && { className: "mb-6 px-5" })}
                            {...(mode === "view" && { className: "mt-5" })}
                            fullWidth>
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.beneficiaryFullName || transaction?.beneficiaryFullName}
                                label={
                                    ID_ACTIVITY === "receivedTransfers.kuara.send"
                                        ? "forms.receivedTransfers.kuara.from"
                                        : "forms.transfers.kuara.to"
                                }
                                bigMessageStyle
                                kuara
                                wally
                            />
                        </Box>
                        {(values?.creditReference ||
                            transaction?.creditReference ||
                            transaction?.data?.creditReference) && (
                            <Box
                                display="flex"
                                alignX="center"
                                {...(mode === "view" && { className: "mb-9" })}
                                {...(mode === "preview" && { className: "px-5" })}
                                fullWidth>
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    kuara
                                    wally
                                    value={
                                        values?.creditReference ||
                                        transaction?.creditReference ||
                                        transaction?.data.creditReference
                                    }
                                    label="forms.transfers.kuara.textarea.label"
                                    regularMessageStyle
                                    centerBlock
                                />
                            </Box>
                        )}

                        {mode === "view" &&
                            (values?.backendReference ||
                                transaction?.backendReference ||
                                transaction?.data?.reference) && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={
                                        values?.backendReference ||
                                        transaction?.backendReference ||
                                        transaction?.data?.reference
                                    }
                                    kuara
                                    label="forms.transfers.kuara.voucher"
                                    shouldRender={mode === "view"}
                                />
                            )}
                        {mode === "view" && (
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                kuara
                                wally
                                marginStyle
                                value={
                                    values?.scheduler?.valueDate?.format("DD/MM/YYYY hh:mm A") ||
                                    (transaction?.transactionDate
                                        ? moment(transaction?.transactionDate, "YYYY-MM-DDTHH:mm:ss.000Z").format(
                                              "DD/MM/YYYY hh:mm A",
                                          )
                                        : moment(values?.transactionDate, "YYYY-MM-DDTHH:mm:ss.000Z").format(
                                              "DD/MM/YYYY hh:mm A",
                                          ))
                                }
                                label="forms.transfers.kuara.date"
                            />
                        )}
                        {isRequestTransferForm && mode === "view" && (
                            <Box display="flex" alignX="center">
                                <Text size="6" color="border-base">
                                    {i18n.get(`forms.${ID_FORM}.expirationDate`)}{" "}
                                    {addDayExpirations(values?.scheduler?.valueDate)?.format("DD/MM/YYYY hh:mm A")}
                                </Text>
                            </Box>
                        )}
                        {isSendPendingTransferForm && mode === "preview" && (
                            <Box display="flex" alignX="center" className="mt-4">
                                <Text size="6" color="border-base">
                                    {i18n.get(`forms.${ID_FORM}.expirationDate`)}{" "}
                                    {addDayExpirations(
                                        moment(values?.transactionDate, "YYYY-MM-DDTHH:mm:ss.000Z"),
                                    )?.format("DD/MM/YYYY hh:mm A")}
                                </Text>
                            </Box>
                        )}
                    </Box>
                ) : null}
            </>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        showFilterChips: false,
        cancelAction: handlerCancelAction,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        showSchedulerMessage: false,
        kuara,
        isJoint: false,
        subtitle: formatAccount(accountKuara),
        subtitlePrefix: accountKuara?.productAlias,
        validate: validateForm,
        fetching: fetching || isFetchingKuara,
        handleCancelPreview: handlerCancelAction,
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    beneficiaryKuara: kuaraSelectors.getSelectedBeneficiary(state),
    accountKuara: kuaraSelectors.getAccountKuara(state),
    userKuara: kuaraSelectors.getUserKuara(state),
    isFetchingPeople: kuaraSelectors.isFetchingPeople(state),
    isFetchingKuara: kuaraSelectors.isFetching(state),
});

SendKuaraTransaction.propTypes = {
    dispatch: func.isRequired,
    mode: string,
    currentLang: string,
    preDataForm: shape({}),
    kuara: bool,
    fromBackoffice: bool,
    subtitle: string,
    subtitlePrefix: string,
    previewData: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
    beneficiaryKuara: shape({}),
    accountKuara: shape({}),
    userKuara: shape({}),
    isFetchingPeople: bool,
    isFetchingKuara: bool,
    fetching: bool,
};

SendKuaraTransaction.defaultProps = {
    fromBackoffice: false,
    mode: "edit",
    currentLang: "",
    preDataForm: {},
    kuara: true,
    subtitle: true,
    subtitlePrefix: true,
    fromTransaction: false,
    previewData: {},
    transaction: {},
    location: {},
    beneficiaryKuara: {},
    accountKuara: {},
    userKuara: {},
    isFetchingPeople: false,
    isFetchingKuara: false,
    fetching: false,
};
export default compose(connect(mapStateToProps), withRouter)(SendKuaraTransaction);
