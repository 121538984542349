import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import {
    actions as updateUserDataActions,
    selectors as updateUserDataSelectors,
} from "reducers/updateUserData/updateUserData.reducer";
import { compose } from "redux";
import { push } from "react-router-redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { useLoadingGlobalProvider } from "providers/LoadingGlobalProvider";
import classNames from "classnames";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import DateField from "pages/_components/fields/DateField";
import * as dateUtils from "util/date";
import moment from "moment";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";

const FORM_ID = "settings.personalDataUpdateStep5";

export const PEP_RELATED_TYPE_PUBLIC = "FPUBLICA";
export const PEP_RELATED_TYPE_FAMILY = "FAMILIAR";

const PersonalDataUpdateStep5 = ({
    isSubmitting,
    isFetching,
    isDesktop,
    dispatch,
    exchangeToken,
    currentUserData,
    values,
    setFieldValue,
    preDataStep5,
}) => {
    // Section 1
    const [isUSCitizenQ1, setIsUSCitizenQ1] = useState();
    const [countryNameQ2, setCountryNameQ2] = useState();
    const [isDiffResidenceQ2, setIsDiffResidenceQ2] = useState();
    const [isPEPQ3, setIsPEPQ3] = useState();
    const [isPEPFamilyQ4, setIsPEPFamilyQ4] = useState();
    const { setLoading } = useLoadingGlobalProvider();
    const [chargePepValue, setChargePepValue] = useState();
    const [showInputChargePep, setShowInputChargePep] = useState();
    const [countryList, setCountryList] = useState([]);
    const [pepFamilyInfo, setPepFamilyInfo] = useState({});

    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);

    useEffect(() => {
        dispatch(updateUserDataActions.preFormStep5(exchangeToken));
    }, [dispatch]);

    const initPepRelatedData = () => {
        if (!currentUserData?.PEPData?.PEPRelated?.length || currentUserData.PEPData.PEPRelated.length === 0) {
            return;
        }

        const publicPositionRelated = currentUserData.PEPData.PEPRelated.find(
            (pepRelated) => pepRelated?.RelType && pepRelated.RelType === PEP_RELATED_TYPE_PUBLIC,
        );
        if (publicPositionRelated) {
            const chargePep = publicPositionRelated.PublicPos;
            setChargePepValue(chargePep);
            setFieldValue("employmentPep", chargePep);
        }
        const familyRelated = currentUserData.PEPData.PEPRelated.find(
            (pepRelated) => pepRelated?.RelType && pepRelated.RelType === PEP_RELATED_TYPE_FAMILY,
        );
        if (familyRelated) {
            setFieldValue("pepFamilyInfo.countryCodeResidence", familyRelated?.Country?.CountryCode);
            setFieldValue("pepFamilyInfo.countryCodeNationality", familyRelated?.PersonData?.Nationality);
            // setFieldValue("pepFamilyInfo.countryCodeIdentification", familyRelated?.PersonData?.Nationality);
            setFieldValue("pepFamilyInfo.publicPos", familyRelated?.PublicPos);
            setFieldValue("pepFamilyInfo.fullName", familyRelated?.PersonData?.PersonName?.FullName);
        }
    };

    useEffect(() => {
        if (!isFetching && currentUserData) {
            if (currentUserData.FATCA !== null) {
                const { US183Permanence, OtherNationality } = currentUserData.FATCA;
                const usResident = currentUserData.residenceCountry === "US";
                const isSelectedQ1 = US183Permanence || usResident;
                const otherNationality = OtherNationality;
                setIsUSCitizenQ1(isSelectedQ1);
                setFieldValue("isUSCitizenQ1", isSelectedQ1);
                setIsDiffResidenceQ2(otherNationality);
                setFieldValue("isDiffResidenceQ2", otherNationality);
            }

            if (currentUserData.PEPData !== null) {
                const { IsPEP, Family } = currentUserData.PEPData;
                const isSelectedQ3 = IsPEP;
                setIsPEPQ3(isSelectedQ3);
                setShowInputChargePep(isSelectedQ3);
                setIsPEPFamilyQ4(Family);

                setFieldValue("isPEPQ3", isSelectedQ3);
                setFieldValue("isPEPFamilyQ4", Family);

                initPepRelatedData();
            }
        }
    }, []);

    useEffect(() => {
        if (!isFetching && preDataStep5) {
            const { countryName, countryList: countryListRef } = preDataStep5;
            setCountryNameQ2(countryName);
            setFieldValue("acceptConditions", false);
            setCountryList(countryListRef);
        }
    }, [preDataStep5]);

    const handleQuestion = (questionId, valueSelected) => {
        if (questionId === 1) {
            setFieldValue("isUSCitizenQ1", valueSelected);
            setIsUSCitizenQ1(valueSelected);
        } else if (questionId === 2) {
            setFieldValue("isDiffResidenceQ2", valueSelected);
            setIsDiffResidenceQ2(valueSelected);
        } else if (questionId === 3) {
            setFieldValue("isPEPQ3", valueSelected);
            setIsPEPQ3(valueSelected);
            setShowInputChargePep(valueSelected);
            if (!valueSelected) {
                setFieldValue("employmentPep", "");
            }
        } else if (questionId === 4) {
            setFieldValue("isPEPFamilyQ4", valueSelected);
            setIsPEPFamilyQ4(valueSelected);
        }
    };

    const handleCancel = () => {
        dispatch(push("/desktop"));
    };

    const renderExtraPEPFamilyQ4 = () => (
        <Box
            display="flex"
            column
            fullWidth
            background="component-background"
            className="pt-5 pb-10 pb-md-11 mb-3 mb-md-8"
            borderRadius="default">
            <Row>
                <Col xs={12} md={6}>
                    <Field
                        idForm={FORM_ID}
                        name="pepFamilyInfo.countryCodeResidence"
                        component={Selector}
                        options={[...(countryList ?? [])]}
                        value={pepFamilyInfo?.countryCodeResidence}
                        nestedErrorsObject
                        valueKey="value"
                        labelKey="label"
                        labelNoMarginTop
                        handleChange={(countryCode) => {
                            setPepFamilyInfo({ ...pepFamilyInfo, countryCodeResidence: countryCode });
                            setFieldValue("pepFamilyInfo.countryCodeResidence", countryCode);
                        }}
                        searchable
                    />
                </Col>
                <Col xs={12} md={6}>
                    <Field
                        idForm={FORM_ID}
                        name="pepFamilyInfo.countryCodeNationality"
                        component={Selector}
                        options={[...(countryList ?? [])]}
                        value={pepFamilyInfo?.countryCodeNationality}
                        valueKey="value"
                        nestedErrorsObject
                        labelKey="label"
                        labelNoMarginTop
                        handleChange={(countryCode) => {
                            setPepFamilyInfo({ ...pepFamilyInfo, countryCodeNationality: countryCode });
                            setFieldValue("pepFamilyInfo.countryCodeNationality", countryCode);
                        }}
                        searchable
                    />
                </Col>
            </Row>
            <Row className="mt-5">
                <Col xs={12} md={6}>
                    <Field
                        idForm={FORM_ID}
                        name="pepFamilyInfo.countryCodeIdentification"
                        component={Selector}
                        options={[...(countryList ?? [])]}
                        value={pepFamilyInfo?.countryCodeIdentification}
                        valueKey="value"
                        labelKey="label"
                        nestedErrorsObject
                        labelNoMarginTop
                        handleChange={(countryCode) => {
                            setPepFamilyInfo({ ...pepFamilyInfo, countryCodeIdentification: countryCode });
                            setFieldValue("pepFamilyInfo.countryCodeIdentification", countryCode);
                        }}
                        searchable
                    />
                </Col>
                <Col xs={12} md={6}>
                    <Field
                        idForm={FORM_ID}
                        name="pepFamilyInfo.issuedIdentValue"
                        component={TextField}
                        type="text"
                        nestedErrorsObject
                        value={pepFamilyInfo?.issuedIdentValue}
                        labelNoMarginTop
                        maxLength="15"
                    />
                </Col>
            </Row>
            <Row className="mt-5">
                <Col xs={12} md={6}>
                    <Field
                        component={DateField}
                        idForm={FORM_ID}
                        name="pepFamilyInfo.startPosition"
                        minDate={moment().add(-100, "years")}
                        nestedErrorsObject
                        maxDate={moment(new Date())}
                        dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18n.getLang())}
                        idField="pepFamilyInfo.startPosition"
                        value={pepFamilyInfo?.startPosition}
                        {...(isDesktop && { labelNoMarginTop: true })}
                        onChange={(selectedIssuedDate) => {
                            const date = moment(selectedIssuedDate.toDate()).format("YYYY-MM-DD");
                            setPepFamilyInfo({ ...pepFamilyInfo, startPosition: date });
                            setFieldValue("pepFamilyInfo.startPosition", date);
                        }}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <Field
                        idForm={FORM_ID}
                        name="pepFamilyInfo.fullName"
                        component={TextField}
                        type="text"
                        value={pepFamilyInfo?.fullName}
                        labelNoMarginTop
                        nestedErrorsObject
                        maxLength="35"
                    />
                </Col>
            </Row>
            <Row className="mt-5">
                <Col xs={12} md={6}>
                    <Field
                        idForm={FORM_ID}
                        name="pepFamilyInfo.publicPos"
                        component={TextField}
                        nestedErrorsObject
                        type="text"
                        value={pepFamilyInfo?.publicPos}
                        labelNoMarginTop
                        maxLength="35"
                    />
                </Col>
            </Row>
        </Box>
    );

    const aditionalDataList = [
        { id: 1, name: "isUSCitizenQ1", label: "question1.label", validation: isUSCitizenQ1 },
        {
            id: 2,
            name: "isDiffResidenceQ2",
            label: "question2.label",
            textParams: { COUNTRY_PLACEHOLDER: countryNameQ2 ? `${countryNameQ2}` : "" },
            validation: isDiffResidenceQ2,
        },
        { id: 3, name: "isPEPQ3", label: "question3.label", validation: isPEPQ3 },
        {
            id: 4,
            name: "isPEPFamilyQ4",
            label: "question4.label",
            validation: isPEPFamilyQ4,
            extraFields: renderExtraPEPFamilyQ4,
        },
    ];

    return (
        <>
            <Notification scopeToShow="personalDataUpdate" />
            <Form className="mx-n-5">
                <Box
                    display="flex"
                    column
                    fullWidth
                    className="px-md-12 pt-3 pt-md-8 pb-md-8 mb-md-5"
                    {...(isDesktop && { background: "component-background", borderRadius: "default" })}>
                    <Box display="flex" column className="px-0">
                        {aditionalDataList.map((data) => (
                            /* eslint-disable no-nested-ternary */
                            <Box display="flex" column fullWidth>
                                <Box
                                    key={data.id}
                                    display="flex"
                                    {...(isDesktop
                                        ? data.id === 3 && showInputChargePep
                                            ? { alignX: "between", alignY: "flex-start" }
                                            : { alignX: "between", alignY: "center" }
                                        : {
                                              column: true,
                                              gap: "5",
                                              background: "component-background",
                                              borderRadius: "xxl",
                                          })}
                                    className={classNames("mb-3 mb-md-9", { "p-5": !isDesktop })}
                                    fullWidth>
                                    <Box display="flex" column>
                                        <Text
                                            labelKey={`${FORM_ID}.${data.label}`}
                                            {...(data.textParams && { textParams: data.textParams })}
                                            className="mr-md-7"
                                        />
                                        {data.id === 3 && showInputChargePep && (
                                            <Box display="flex" fullWidth className="pt-5">
                                                <Field
                                                    idForm={FORM_ID}
                                                    name="employmentPep"
                                                    component={TextField}
                                                    type="text"
                                                    labelNoMarginTop
                                                    maxLength="35"
                                                    pattern="^[a-zA-Z0-9 ]*$"
                                                    initValue={chargePepValue}
                                                />
                                            </Box>
                                        )}
                                    </Box>

                                    <Box
                                        display="flex"
                                        {...(isDesktop
                                            ? { alignX: "between" }
                                            : { alignX: "flex-end", className: "ml-auto max-widht-half" })}
                                        gap={isDesktop ? "7" : "5"}>
                                        <Button
                                            bsStyle="chip"
                                            className={classNames("full-width", {
                                                "is-active show-active-check-mobile": !data.validation,
                                                "btn-height-8": !isDesktop,
                                            })}
                                            label="global.no"
                                            image={!data.validation ? "images/check.svg" : ""}
                                            onClick={() => handleQuestion(data.id, false)}
                                        />
                                        <Button
                                            bsStyle="chip"
                                            className={classNames("full-width", {
                                                "is-active show-active-check-mobile": data.validation,
                                                "btn-height-8": !isDesktop,
                                            })}
                                            label="global.yes"
                                            image={data.validation ? "images/check.svg" : ""}
                                            onClick={() => handleQuestion(data.id, true)}
                                        />
                                    </Box>
                                    <input type="hidden" name={data.name} value={data.validation} />
                                </Box>
                                {data?.validation && data?.extraFields && data.extraFields()}
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box
                    display="flex"
                    column
                    fullWidth
                    background="component-background"
                    className="px-5 px-md-12 py-8 mb-5 mb-md-8"
                    borderRadius="default">
                    <Field name="acceptConditions" idForm={FORM_ID} component={Checkbox} formGroup />
                </Box>

                <Row className="px-5 px-md-0" {...(!isDesktop && { gapY: "3" })}>
                    <Col xs={12} md={4} mdOffset={2}>
                        <Button label="global.cancel" bsStyle="outline" onClick={handleCancel} block />
                    </Col>
                    <Col xs={12} md={4} {...(!isDesktop && { className: "grid-reversed" })}>
                        <Button
                            disabled={!values.acceptConditions}
                            label="global.continue"
                            loading={isSubmitting || isFetching}
                            type="submit"
                            bsStyle="primary"
                            block
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

PersonalDataUpdateStep5.propTypes = {
    dispatch: func.isRequired,
    isFetching: bool,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    exchangeToken: string.isRequired,
    currentUserData: shape({}),
    values: shape({}).isRequired,
    setFieldValue: func.isRequired,
    preDataStep5: shape({}),
};

PersonalDataUpdateStep5.defaultProps = {
    isFetching: false,
    currentUserData: null,
    preDataStep5: null,
};

const mapStateToProps = (state) => ({
    isFetching: updateUserDataSelectors.isFetching(state),
    exchangeToken: updateUserDataSelectors.getExchangeToken(state),
    currentUserData: updateUserDataSelectors.getCurrentUserData(state),
    preDataStep5: updateUserDataSelectors.getPreDataStep5(state),
});

const pepFamilyInfoSchema = (defError) =>
    Yup.object().shape({
        countryCodeResidence: Yup.string().required(defError),
        countryCodeNationality: Yup.string().required(defError),
        countryCodeIdentification: Yup.string().required(defError),
        issuedIdentValue: Yup.string().required(defError),
        startPosition: Yup.string().required(defError),
        fullName: Yup.string().required(defError),
        publicPos: Yup.string().required(defError),
    });

export default compose(
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            isUSCitizenQ1: false,
            isDiffResidenceQ2: false,
            isPEPQ3: false,
            isPEPFamilyQ4: false,
            acceptConditions: false,
            employmentPep: "",
        }),
        validationSchema: () => {
            const defError = i18n.get(DEFAULT_REQUIRED);
            return Yup.object().shape({
                acceptConditions: Yup.boolean().oneOf([true], i18n.get(`${FORM_ID}.conditions.required`)),
                isPEPQ3: Yup.boolean(),
                employmentPep: Yup.string().when("isPEPQ3", {
                    is: true,
                    then: Yup.string().required(i18n.get(`${FORM_ID}.employmentPep.required`)),
                    otherwise: Yup.string().notRequired(),
                }),
                pepFamilyInfo: Yup.mixed().when("isPEPFamilyQ4", {
                    is: true,
                    then: pepFamilyInfoSchema(defError),
                    otherwise: Yup.mixed().notRequired(),
                }),
            });
        },
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(updateUserDataActions.saveForm(values, 5, formikBag));
        },
    }),
)(PersonalDataUpdateStep5);
