import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";
import { push } from "react-router-redux";
import { delay } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import * as i18n from "middleware/i18n";
import { types as loginTypes } from "reducers/login";
import { actions as notificationActions } from "reducers/notification";
import * as configUtil from "util/config";
import { MAX_FAILED_TIMES, TIME_IN_MILLIS_TO_REFRESH } from "constants.js";
import { selectors as i18nSelector, types } from "reducers/i18n";
import globalTypes from "reducers/types/global";
import { crashLogData, crashLogString } from "util/crashReport/crashReport.util";
import * as i18nUtils from "util/i18n";
import { store } from "../store";

const sagas = [
    takeLatest(globalTypes.INIT, checkInitialLang),
    takeLatest(types.SET_LANG, changeLanguage),
    takeLatest(loginTypes.LOGIN_STEP_1_SUCCESS, changeLanguage),
    takeLatest(loginTypes.LOGIN_OAUTH_SUCCESS, changeLanguage),
    takeLatest([types.SET_INIT_LANG, types.RESET_SAGAS_UPDATE], update),
];

export default sagas;

function* changeLanguage({ lang, etag, showNotification, notification }) {
    try {
        yield put({ type: types.UPDATE_REQUEST });

        moment.locale(lang);

        const response = yield call(i18n.listMessages, lang, etag);
        if (response.status !== 304) {
            const { data } = response.data;
            const etagResponse = response.headers.etag;

            yield put({ type: types.UPDATE_SUCCESS, items: data, etag: etagResponse, lang });
            if (showNotification && notification) {
                const { message, type, scope } = notification;
                const confirmationMessage = i18nUtils.get(message);
                yield put(notificationActions.showNotification(confirmationMessage, type, [scope]));
            }
        } else {
            yield put({ type: types.UPDATE_FETCH_TO_FALSE });
        }
    } catch (err) {
        yield call(updateFailure, err?.data?.code, err?.data?.message);
        crashLogString(`i18n response error: ${err}`);
        crashLogData(err);
    }
}

function* checkInitialLang() {
    const initialLang = i18nSelector.getLang(store.getState());

    if (initialLang) {
        moment.locale(initialLang);
        yield call(update, { lang: initialLang });
    } else {
        const deviceLang = i18nUtils.deviceLanguage();

        if (deviceLang) {
            moment.locale(deviceLang);
            yield put({ type: types.SET_INIT_LANG, lang: deviceLang });
        }
    }
}

function* update({ lang, etag }) {
    while (true) {
        try {
            const timesFailed = yield select(i18nSelector.getTimesFailed);
            crashLogString(`timesFailedResponse: ${timesFailed}`, { timesFailed });

            if (timesFailed >= MAX_FAILED_TIMES) {
                const lastResponseCode = yield select(i18nSelector.getLastResponseCode);
                const lastResponseMessage = yield select(i18nSelector.getLastResponseMessage);

                yield put(push({ pathname: "/serverError", lastResponseCode, lastResponseMessage }));
            }

            yield put({ type: types.UPDATE_REQUEST });

            const response = yield call(i18n.listMessages, lang, etag);
            if (response.status !== 304) {
                const { code } = response.data;

                if (code === "COR000I") {
                    const { data } = response.data;
                    const { etag: etagResponse } = response.headers;

                    yield put({ type: types.UPDATE_SUCCESS, items: data, etag: etagResponse, lang });
                    yield call(
                        delay,
                        configUtil.getTimeInMillis("frontend.i18n.refresh.interval", TIME_IN_MILLIS_TO_REFRESH),
                    );
                } else {
                    yield call(updateFailure, TIME_IN_MILLIS_TO_REFRESH, code, response?.data?.message);
                }
            } else {
                yield put({ type: types.UPDATE_FETCH_TO_FALSE });
                yield call(
                    delay,
                    configUtil.getTimeInMillis("frontend.i18n.refresh.interval", TIME_IN_MILLIS_TO_REFRESH),
                );
            }
        } catch (err) {
            crashLogString(`i18n response error: ${err}`);
            crashLogData(err);
            yield call(updateFailure, TIME_IN_MILLIS_TO_REFRESH, err?.data?.code, err?.data?.message);
        }
    }
}

function* updateFailure(timeInMillisToDelay = 0, lastResponseCode, lastResponseMessage) {
    yield put({ type: types.UPDATE_FAILURE, data: { lastResponseCode, lastResponseMessage } });
    yield call(delay, timeInMillisToDelay);
}
