/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, createRef } from "react";
import { arrayOf, element, bool, string, node } from "prop-types";
import classNames from "classnames";

import Button from "pages/_components/Button";

class Dropdown extends Component {
    node = createRef();

    static propTypes = {
        ariaLabel: string,
        block: bool,
        bsStyle: string,
        buttonClass: string,
        children: arrayOf(element).isRequired,
        className: string,
        disabled: bool,
        dropdownButtonContent: node,
        fetching: bool,
        id: string,
        image: string,
        inverse: bool,
        label: string,
        labelClassName: string,
        maxContentWidth: bool,
        positionRelative: bool,
        pullCenter: bool,
        pullLeft: bool,
        pullRight: bool,
        pullUp: bool,
        tabIndex: string,
        imageMd: bool,
    };

    static defaultProps = {
        ariaLabel: null,
        block: false,
        bsStyle: null,
        buttonClass: null,
        className: null,
        disabled: false,
        dropdownButtonContent: null,
        fetching: false,
        id: null,
        image: null,
        inverse: false,
        label: null,
        labelClassName: null,
        maxContentWidth: false,
        positionRelative: false,
        pullCenter: false,
        pullLeft: false,
        pullRight: false,
        pullUp: false,
        tabIndex: null,
        imageMd: false,
    };

    state = {
        isOpen: false,
        navIndex: 0,
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick, false);
        document.addEventListener("touchstart", this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick, false);
        document.removeEventListener("touchstart", this.handleClick, false);
    }

    handleClick = (e) => {
        const { target } = e;
        const { isOpen } = this.state;
        if (!this.node.current.contains(target) && isOpen) {
            this.toggleOpen(e);
        }
    };

    toggleOpen = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState((prevState) => ({ isOpen: !prevState.isOpen, navIndex: 0 }));
    };

    onChildFocus = (param) => {
        this.setState({ navIndex: param });
    };

    onFocusHandler = () => {
        this.setState({ isOpen: true });
    };

    onBlurHandler = (ix) => {
        this.setState({ isOpen: !ix });
    };

    render() {
        const {
            ariaLabel,
            block,
            bsStyle,
            buttonClass,
            children,
            className,
            disabled,
            dropdownButtonContent,
            fetching,
            id,
            image,
            inverse,
            label,
            labelClassName,
            maxContentWidth,
            positionRelative,
            pullCenter,
            pullLeft,
            pullRight,
            pullUp,
            tabIndex,
        } = this.props;
        const { isOpen, navIndex } = this.state;
        const childrenLength = React.Children.toArray(children).length;

        const keyPressHandler = (ev) => {
            let aux = navIndex;
            if (ev.shiftKey && ev.key === "Tab") {
                aux -= 1;
            } else if (ev.key === "Tab") {
                aux += 1;
            }

            if (aux >= childrenLength || aux < 0) {
                this.setState({ isOpen: false });
            }
        };

        return (
            <div id={id} className={classNames("dropdown", className)} ref={this.node}>
                <Button
                    {...(tabIndex && { tabIndex })}
                    aria-expanded={isOpen}
                    aria-haspopup
                    ariaLabel={ariaLabel}
                    block={block}
                    bsStyle={bsStyle}
                    className={buttonClass}
                    disabled={disabled}
                    expandable
                    image={image}
                    label={label}
                    labelClassName={labelClassName}
                    loading={fetching}
                    onClick={this.toggleOpen}
                    inverse={inverse}
                    {...(this.props.imageMd && { imageMd: this.props.imageMd })}
                    onKeyDown={keyPressHandler}>
                    {dropdownButtonContent}
                </Button>
                {isOpen && !fetching && (
                    <ul
                        className={classNames("dropdown__menu", {
                            "dropdown__menu--left": pullLeft,
                            "dropdown__menu--right": pullRight,
                            "dropdown__menu--up": pullUp,
                            "dropdown__menu--center": pullCenter,
                            "pos-relative": positionRelative,
                            "dropdown-width-max-content": maxContentWidth,
                        })}>
                        {React.Children.map(children, (child, ix) => {
                            if (child) {
                                return (
                                    <li
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={ix}
                                        onBlur={() => this.onBlurHandler(ix === children?.length - 1)}
                                        className="dropdown__item"
                                        onClick={() => {
                                            // eslint-disable-next-line no-unused-expressions
                                            this.onHandleIndex;
                                            this.onBlurHandler();
                                        }}
                                        onKeyDown={keyPressHandler}>
                                        {child &&
                                            React.cloneElement(child, {
                                                ...child.props,
                                                ix,
                                                keyPressHandler,
                                                onFocus: () => {
                                                    this.onChildFocus(ix);
                                                },
                                            })}
                                    </li>
                                );
                            }
                            return null;
                        })}
                    </ul>
                )}
            </div>
        );
    }
}

export default Dropdown;
